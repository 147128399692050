import React, { useState, useEffect, useContext } from "react";
import TimezoneSelect from "react-timezone-select";
import { Card, Form, Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getGeneralPreferences,
  updateGeneralPreferences,
} from "../../../redux/slices/preferences";
import NotyfContext from "../../../contexts/NotyfContext";
import * as Yup from "yup";
import { Formik } from "formik";
import { format } from "date-fns";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";

const GeneralSettings = ({ generalPreferences, activeTab }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const current = new Date();

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "20px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      zIndex: 9999,
      borderRadius: "20px",
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: "20px",
      backgroundColor: state.isSelected
        ? "#007bff"
        : state.isFocused
        ? "#e9ecef"
        : "#fff",
      color: state.isSelected ? "#fff" : "#333",
      padding: "10px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#333",
    }),
  };

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [impersonationUser, user]);

  useEffect(() => {
    const fetchGeneralPreferences = async () => {
      setIsLoading(true);
      await dispatch(getGeneralPreferences())
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
    };

    if (activeUser.id && activeTab === "#General") {
      fetchGeneralPreferences();
    }

    // fetchGeneralPreferences(activeUser.id);
  }, [activeUser, activeTab, dispatch, notyf]);

  const initialValues = {
    timeZone: generalPreferences?.timeZone,
    dateFormat: generalPreferences?.dateFormat,
    timeFormat: generalPreferences?.timeFormat,
  };

  const validation = Yup.object().shape({
    timeZone: Yup.string().required("Time Zone is required"),
    dateFormat: Yup.string().required("Date Format is required"),
    timeFormat: Yup.string().required("Time Format is required"),
  });

  return (
    <Card>
      <Card.Header>
        <Card.Title>{t("settings.generalSettings.title")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && <Loader />}
        {!isLoading && (
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, { setValues }) => {
              const data = {
                time_zone: values.timeZone,
                date_format: values.dateFormat,
                time_format: values.timeFormat,
              };

              await dispatch(updateGeneralPreferences({ data: data }))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "General Preferences updated successfully",
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("settings.generalSettings.timeZone")}
                    </Form.Label>
                    <TimezoneSelect
                      value={values.timeZone}
                      onChange={(timezone) =>
                        setFieldValue("timeZone", timezone.value)
                      }
                      onBlur={handleBlur}
                      labelStyle="original" // This will show the timezone with the UTC offset
                      styles={customStyles}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("settings.generalSettings.dateFormat")}
                    </Form.Label>
                    <Form.Select
                      name="dateFormat"
                      value={values.dateFormat}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="MMM d, yyyy">
                        {format(current, "MMMM d, yyyy")}
                      </option>
                      <option value="yyyy/MM/dd">
                        {format(current, "yyyy/MM/dd")}
                      </option>
                      <option value="MM/dd/yyyy">
                        {format(current, "MM/dd/yyyy")}
                      </option>
                      <option value="dd/MM/yyyy">
                        {format(current, "dd/MM/yyyy")}
                      </option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("settings.generalSettings.timeFormat")}
                    </Form.Label>
                    <Form.Select
                      name="timeFormat"
                      value={values.timeFormat}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="HH:mm">{format(current, "HH:mm")}</option>
                      <option value="hh:mm a">
                        {format(current, "hh:mm a")}
                      </option>
                    </Form.Select>
                  </Form.Group>
                  <div className="large-screen-form-footer">
                    <Row>
                      <Col md="auto" className="ms-auto">
                        <Button
                          type="submit"
                          variant="primary"
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#fff",
                            border: "none",
                          }}
                        >
                          {t("settings.generalSettings.save")}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </Card.Body>
    </Card>
  );
};

export default GeneralSettings;
