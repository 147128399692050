import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form, Spinner, Table } from "react-bootstrap";
import { getAvailableOffers } from "../../../../redux/slices/offers";
import { activateSims, getSims } from "../../../../redux/slices/sims";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import NotyfContext from "../../../../contexts/NotyfContext";
import { Formik } from "formik";
import * as Yup from "yup";
import Loader from "../../../../components/spinners/Loader";

const ActivateSimModal = ({
  openActivateModal,
  setOpenActivateModal,
  selectedSims,
  clearSelectedRows,
}) => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const availableOffers = useSelector((state) => state.offers.availableOffers);
  const notyf = useContext(NotyfContext);
  const [currentStep, setCurrentStep] = useState(1);

  const initialValues = {
    sims: selectedSims,
    offer: "",
  };

  const validation = Yup.object().shape({
    sims: Yup.array().required("Required"),
    offer: Yup.string().required("Required"),
  });

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchAvailableOffers = async () => {
      await dispatch(getAvailableOffers())
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            dismissible: true,
            ripple: true,
            duration: 5000,
            position: {
              x: "center",
              y: "top",
            },
          });
          setOpenActivateModal(false);
        });
    };

    if (activeUser.id && openActivateModal) {
      fetchAvailableOffers();
    }
  }, [dispatch, activeUser, selectedSims, openActivateModal]);

  return (
    <Modal
      show={openActivateModal}
      onHide={() => setOpenActivateModal(false)}
      centered
      size="lg"
      backdrop="static"
      scrollable
    >
      <Modal.Header>
        <Modal.Title>Activate SIMs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={async (values, { setStatus, setValues }) => {
            const data = {
              imsiList: values.sims.map((sim) => sim.imsi),
              offer: values.offer,
            };
            await dispatch(
              activateSims({ sims: data.imsiList, offer: data.offer })
            )
              .then(() => {
                notyf.open({
                  type: "success",
                  message: "SIMs activated successfully",
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
                setStatus("Success");
              })
              .catch((error) => {
                notyf.open({
                  type: "danger",
                  message: error,
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
                setStatus("Error");
              })
              .finally(() => {
                setCurrentStep(3);
                setValues(initialValues);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            validateForm,
            setFieldTouched,
            status,
            setValues,
          }) => {
            const nextStep = () => {
              validateForm().then((errors) => {
                if (Object.keys(errors).length === 0) {
                  if (currentStep === 1) {
                    setCurrentStep(2);
                    handleSubmit();
                  }
                } else {
                  Object.keys(values).forEach((field) => {
                    setFieldTouched(field);
                  });
                }
              });
            };
            return (
              <Form onSubmit={handleSubmit}>
                {currentStep === 1 && (
                  <React.Fragment>
                    <Form.Group className="mb-3">
                      <Table responsive hover>
                        <thead>
                          <th>ICCID</th>
                          <th>IMSI</th>
                        </thead>
                        <tbody>
                          {values.sims.map((sim) => (
                            <tr key={sim.id}>
                              <td>{sim.iccid}</td>
                              <td>{sim.imsi}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Offer</Form.Label>
                      <Form.Select
                        name="offer"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.offer}
                        isInvalid={Boolean(errors.offer && touched.offer)}
                      >
                        <option value="">Select offer</option>
                        {availableOffers.map((offer) => (
                          <option key={offer.id} value={offer.name}>
                            {offer.name}
                          </option>
                        ))}
                      </Form.Select>
                      {!!touched.offer && (
                        <Form.Control.Feedback type="invalid">
                          {errors.offer}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </React.Fragment>
                )}
                {currentStep === 2 && <Loader />}
                {currentStep === 3 && status === "Success" && (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <FaCheckCircle size={120} color="#4BBF73" />
                    <h3>Success</h3>
                  </div>
                )}
                {currentStep === 3 && status === "Error" && (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <FaExclamationCircle size={120} color="#FF0000" />
                    <h3 className="mt-3">Order Error</h3>
                    <p>Please contact the administrator.</p>
                  </div>
                )}
                <Modal.Footer>
                  {currentStep === 1 && (
                    <React.Fragment>
                      <Button
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                        }}
                        onClick={() => {
                          setValues(initialValues);
                          setOpenActivateModal(false);
                          clearSelectedRows();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={nextStep}
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                        }}
                      >
                        Submit
                      </Button>
                    </React.Fragment>
                  )}
                  {currentStep === 3 && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        setCurrentStep(1);
                        setValues(initialValues);
                        setOpenActivateModal(false);
                        clearSelectedRows();
                      }}
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                        border: "none",
                      }}
                    >
                      Close
                    </Button>
                  )}
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ActivateSimModal;
