import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";
import { setAlerts } from "./alerts";

const initialState = {
  data_volume: 0.0,
  data_volume_difference_percentage: 0.0,
  data_sessions: 0,
  data_sessions_difference_percentage: 0.0,
  alerts: 0,
  alerts_difference_percentage: 0.0,
  zero_sessions: 0,
  zero_sessions_difference_percentage: 0.0,
  average_data_volume: 0.0,
  active_sims: 0,
  by_country: [],
  by_month: [],
  offers_distribution: [],
  sim_inventory: [],
  network_distribution: [],
  volume_by_network: [],
  alerts: [],
  data_volume_per_hour: [],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDataVolume(state, action) {
      state.data_volume = action.payload;
    },
    setDataVolumeDifferencePercentage(state, action) {
      state.data_volume_difference_percentage = action.payload;
    },
    setDataSessions(state, action) {
      state.data_sessions = action.payload;
    },
    setDataSessionsDifferencePercentage(state, action) {
      state.data_sessions_difference_percentage = action.payload;
    },
    setAlerts(state, action) {
      state.alerts = action.payload;
    },
    setAlertsDifferencePercentage(state, action) {
      state.alerts_difference_percentage = action.payload;
    },
    setZeroSessions(state, action) {
      state.zero_sessions = action.payload;
    },
    setZeroSessionsDifferencePercentage(state, action) {
      state.zero_sessions_difference_percentage = action.payload;
    },
    setAverageDataVolume(state, action) {
      state.average_data_volume = action.payload;
    },
    setByCountry(state, action) {
      state.by_country = action.payload;
    },
    setByMonth(state, action) {
      state.by_month = action.payload;
    },
    setOffersDistribution(state, action) {
      state.offers_distribution = action.payload;
    },
    setSimInventory(state, action) {
      state.sim_inventory = action.payload;
    },
    setNetworkDistribution(state, action) {
      state.network_distribution = action.payload;
    },
    setVolumeByNetwork(state, action) {
      state.volume_by_network = action.payload;
    },
    setAlerts(state, action) {
      state.alerts = action.payload;
    },
    setDataVolumePerHour(state, action) {
      state.data_volume_per_hour = action.payload;
    },
    setActiveSims(state, action) {
      state.active_sims = action.payload;
    },
  },
});

export function getDataSessionCounts({ type }) {
  return async (dispatch) => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      const impersonationToken =
        window.localStorage.getItem("impersonationToken");
      if (!accessToken) {
        return Promise.reject("No access token");
      }
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statistics/counts/data_sessions?period=${type}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        dashboardSlice.actions.setDataSessions(
          response.data.data.currentPeriodDataSessions
        )
      );
      dispatch(
        dashboardSlice.actions.setDataSessionsDifferencePercentage(
          response.data.data.dataSessionsDifferencePercentage
        )
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getDataVolumeCounts({ type }) {
  return async (dispatch) => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      const impersonationToken =
        window.localStorage.getItem("impersonationToken");
      if (!accessToken) {
        return Promise.reject("No access token");
      }
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statistics/counts/data_volume?period=${type}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        dashboardSlice.actions.setDataVolume(
          response.data.data.currentPeriodDataVolume
        )
      );
      dispatch(
        dashboardSlice.actions.setDataVolumeDifferencePercentage(
          response.data.data.dataVolumeDifferencePercentage
        )
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getZeroSessionCounts({ type }) {
  return async (dispatch) => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      const impersonationToken =
        window.localStorage.getItem("impersonationToken");
      if (!accessToken) {
        return Promise.reject("No access token");
      }
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statistics/counts/zero_sessions?period=${type}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        dashboardSlice.actions.setZeroSessions(
          response.data.data.currentPeriodZeroSessions
        )
      );
      dispatch(
        dashboardSlice.actions.setZeroSessionsDifferencePercentage(
          response.data.data.zeroSessionsDifferencePercentage
        )
      );

      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getAlertCounts({ type }) {
  return async (dispatch) => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      const impersonationToken =
        window.localStorage.getItem("impersonationToken");
      if (!accessToken) {
        return Promise.reject("No access token");
      }
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statistics/counts/alerts?period=${type}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        dashboardSlice.actions.setAlerts(response.data.data.currentPeriodAlerts)
      );
      dispatch(
        dashboardSlice.actions.setAlertsDifferencePercentage(
          response.data.data.alertsDifferencePercentage
        )
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getAverageDataVolume() {
  return async (dispatch) => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      const impersonationToken =
        window.localStorage.getItem("impersonationToken");
      if (!accessToken) {
        return Promise.reject("No access token");
      }
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statistics/counts/average_data_volume`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        dashboardSlice.actions.setAverageDataVolume(
          response.data.data.averageDataVolume
        )
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getActiveSims() {
  return async (dispatch) => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      const impersonationToken =
        window.localStorage.getItem("impersonationToken");
      if (!accessToken) {
        return Promise.reject("No access token");
      }
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statistics/counts/active_sims`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        dashboardSlice.actions.setActiveSims(response.data.data.activeSims)
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

// export function getCounts({ type }) {
//   return async (dispatch) => {
//     try {
//       const accessToken = window.localStorage.getItem("accessToken");
//       const impersonationToken =
//         window.localStorage.getItem("impersonationToken");
//       if (!accessToken) {
//         return Promise.reject("No access token");
//       }
//       const response = await axios.get(
//         `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statistics/counts?period=${type}`,
//         {
//           headers: {
//             Authorization: `Bearer ${
//               impersonationToken ? impersonationToken : accessToken
//             }`,
//           },
//         }
//       );
//       dispatch(
//         dashboardSlice.actions.setDataVolume(
//           response.data.data.currentPeriodDataVolume
//         )
//       );
//       dispatch(
//         dashboardSlice.actions.setDataVolumeDifferencePercentage(
//           response.data.data.dataVolumeDifferencePercentage
//         )
//       );
//       dispatch(
//         dashboardSlice.actions.setDataSessions(
//           response.data.data.currentPeriodDataSessions
//         )
//       );
//       dispatch(
//         dashboardSlice.actions.setDataSessionsDifferencePercentage(
//           response.data.data.dataSessionsDifferencePercentage
//         )
//       );
//       dispatch(
//         dashboardSlice.actions.setAlerts(response.data.data.currentPeriodAlerts)
//       );
//       dispatch(
//         dashboardSlice.actions.setAlertsDifferencePercentage(
//           response.data.data.alertsDifferencePercentage
//         )
//       );
//       dispatch(
//         dashboardSlice.actions.setZeroSessions(
//           response.data.data.currentPeriodZeroSessions
//         )
//       );
//       dispatch(
//         dashboardSlice.actions.setZeroSessionsDifferencePercentage(
//           response.data.data.zeroSessionsDifferencePercentage
//         )
//       );
//       dispatch(
//         dashboardSlice.actions.setAverageDataVolume(
//           response.data.data.averageDataVolume
//         )
//       );
//       dispatch(
//         dashboardSlice.actions.setActiveSims(response.data.data.activeSims)
//       );
//       return Promise.resolve(response.data.message);
//     } catch (error) {
//       return Promise.reject(error.message);
//     }
//   };
// }

export function getCountryDistribution({ period, type }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) {
      return Promise.reject("No access token");
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statistics/bycountry?period=${period}&type=${type}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(dashboardSlice.actions.setByCountry(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getDistributionByMonth({ type }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) {
      return Promise.reject("No access token");
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statistics/bymonth?type=${type}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(dashboardSlice.actions.setByMonth(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getOffersDistribution() {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) {
      return Promise.reject("No access token");
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statistics/offers`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        dashboardSlice.actions.setOffersDistribution(response.data.data)
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getSimInventory() {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) {
      return Promise.reject("No access token");
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statistics/inventory`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(dashboardSlice.actions.setSimInventory(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getNetworkDistribution({ country }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statistics/network?country=${country}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        dashboardSlice.actions.setNetworkDistribution(response.data.data)
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getNetworkVolumeDistribution({ country }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statistics/network/volume?country=${country}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(dashboardSlice.actions.setVolumeByNetwork(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getDataVolumePerHour() {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statistics/byhour`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(dashboardSlice.actions.setDataVolumePerHour(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getAlerts() {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statistics/alerts`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(dashboardSlice.actions.setAlerts(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export default dashboardSlice.reducer;
