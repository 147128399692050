import React from "react";
import { useEffect, useState, useContext } from "react";
import { Card, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAlerts } from "../../redux/slices/dashboard";
import NotyfContext from "../../contexts/NotyfContext";
import Loader from "../spinners/Loader";
import { useTranslation } from "react-i18next";

const WatchdogTable = ({ alerts }) => {
  const { t } = useTranslation();
  if (alerts.length > 0) {
    return (
      <Table hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Alert</th>
            <th>Date</th>
            <th>IMSI</th>
          </tr>
        </thead>
        <tbody>
          {alerts.slice(0, 10).map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                {item.context} {item.action}
              </td>
              <td>{item.alertDate.replace("T", " ")}</td>
              <td>{item.imsi}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  } else {
    return <div className="text-center">{t("dashboard.noData")}</div>;
  }
};

const WatchdogAlerts = ({ reload }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const alerts = useSelector((state) => state.dashboard.alerts);
  const [alertsList, setAlertsList] = useState([]);
  const notyf = useContext(NotyfContext);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user.id, impersonationUser.id]);

  useEffect(() => {
    const fetchAlerts = async () => {
      setIsLoading(true);
      await dispatch(getAlerts())
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (activeUser.id) {
      fetchAlerts();
    }
  }, [activeUser, reload]);

  return (
    <Card className="w-100">
      <Card.Header>
        <Card.Title>{t("dashboard.watchdogAlerts")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && <Loader />}
        {!isLoading && <WatchdogTable alerts={alerts} />}
      </Card.Body>
    </Card>
  );
};

export default WatchdogAlerts;
