import React, { useMemo } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import CountrySelectContext from "../../../../utils/CountrySelectContext";
import countryList from "react-select-country-list";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Basic = ({
  handleBlur,
  handleChange,
  values,
  errors,
  touched,
  entityType,
  image,
  inputRef,
  handleDisplayFileDetails,
  handleUpload,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const countryOptions = useMemo(() => {
    const options = countryList().getData();
    return [{ label: "Select a country", value: "" }, ...options];
  }, []);

  return (
    <React.Fragment>
      <Form.Group className="mb-3">
        <Form.Label>{t("crm.newModal.fullName")}</Form.Label>
        <Form.Control
          type="text"
          name="fullName"
          value={values.fullName}
          isInvalid={Boolean(touched.fullName && errors.fullName)}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {!!touched.fullName && (
          <Form.Control.Feedback type="invalid">
            {errors.fullName}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      {entityType !== "Subaccount" && (
        <Form.Group className="mb-3">
          <Form.Label>Portfolio ID</Form.Label>
          <Form.Control
            type="text"
            name="portfolioId"
            value={values.portfolioId}
            isInvalid={Boolean(touched.portfolioId && errors.portfolioId)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {!!touched.portfolioId && (
            <Form.Control.Feedback type="invalid">
              {errors.portfolioId}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      )}
      {entityType !== "Subaccount" && (
        <React.Fragment>
          <Form.Group className="mb-3">
            <Form.Label>{t("crm.newModal.address")}</Form.Label>
            <Form.Control
              type="text"
              name="address"
              value={values.address}
              isInvalid={Boolean(touched.address && errors.address)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.address && (
              <Form.Control.Feedback type="invalid">
                {errors.address}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Row className="mb-3">
            <Col md={4}>
              <Form.Group>
                <Form.Label>{t("crm.newModal.city")}</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={values.city}
                  isInvalid={Boolean(touched.city && errors.city)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.city && (
                  <Form.Control.Feedback type="invalid">
                    {errors.city}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>{t("crm.newModal.country")}</Form.Label>
                <CountrySelectContext.Provider value={countryOptions}>
                  <CountrySelectContext.Consumer>
                    {(countryOptions) => (
                      <Form.Select
                        value={values.country}
                        onChange={handleChange}
                        name="country"
                        isInvalid={Boolean(touched.country && errors.country)}
                      >
                        {countryOptions.map((option) => (
                          <option key={option.value} value={option.label}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                  </CountrySelectContext.Consumer>
                </CountrySelectContext.Provider>
                {!!touched.country && (
                  <Form.Control.Feedback type="invalid">
                    {errors.country}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>{t("crm.newModal.postcode")}</Form.Label>
                <Form.Control
                  type="text"
                  name="postcode"
                  value={values.postcode}
                  isInvalid={Boolean(touched.postcode && errors.postcode)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.postcode && (
                  <Form.Control.Feedback type="invalid">
                    {errors.postcode}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>{t("crm.newModal.telephone")}</Form.Label>
                <Form.Control
                  type="text"
                  value={values.telephoneNumber}
                  name="telephoneNumber"
                  isInvalid={Boolean(
                    touched.telephoneNumber && errors.telephoneNumber
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.telephoneNumber && (
                  <Form.Control.Feedback type="invalid">
                    {errors.telephoneNumber}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>{t("crm.newModal.mobile")}</Form.Label>
                <Form.Control
                  type="text"
                  value={values.mobileNumber}
                  name="mobileNumber"
                  isInvalid={Boolean(
                    touched.mobileNumber && errors.mobileNumber
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.mobileNumber && (
                  <Form.Control.Feedback type="invalid">
                    {errors.mobileNumber}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
        </React.Fragment>
      )}
      <Form.Group className="mb-3">
        <Form.Label>{t("crm.newModal.email")}</Form.Label>
        <Form.Control
          type="email"
          name="email"
          value={values.email}
          isInvalid={Boolean(touched.email && errors.email)}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {!!touched.email && (
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      {entityType !== "Subaccount" && (
        <React.Fragment>
          <Form.Group className="mb-3">
            <Form.Label>{t("crm.newModal.occupation")}</Form.Label>
            <Form.Control
              type="text"
              name="occupation"
              value={values.occupation}
              isInvalid={Boolean(touched.occupation && errors.occupation)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.occupation && (
              <Form.Control.Feedback type="invalid">
                {errors.occupation}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>{t("crm.newModal.company")}</Form.Label>
                <Form.Control
                  type="text"
                  name="company"
                  value={values.company}
                  isInvalid={Boolean(touched.company && errors.company)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!touched.company && (
                  <Form.Control.Feedback type="invalid">
                    {errors.company}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>{t("crm.newModal.tin")}</Form.Label>
                <Form.Control
                  type="text"
                  name="tin"
                  value={values.tin}
                  isInvalid={Boolean(touched.tin && errors.tin)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.tin && (
                  <Form.Control.Feedback type="invalid">
                    {errors.tin}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
        </React.Fragment>
      )}
      <Form.Group className="mb-4">
        <Form.Label>{t("crm.newModal.username")}</Form.Label>
        <Form.Control
          type="text"
          name="username"
          value={values.username}
          isInvalid={Boolean(touched.username && errors.username)}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {!!touched.username && (
          <Form.Control.Feedback type="invalid">
            {errors.username}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>{t("crm.newModal.sendWelcomeEmail")}</Form.Label>
        <Form.Check
          type="switch"
          id="sendWelcomeEmail"
          name="sendWelcomeEmail"
          checked={values.sendWelcomeEmail}
          onChange={(e) => setFieldValue("sendWelcomeEmail", e.target.checked)}
          style={{
            "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
            "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
          }}
        />
      </Form.Group>
    </React.Fragment>
  );
};

export default Basic;
