import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarNav from "./SidebarNav";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import pos2cloud from "../../assets/img/pos2cloud.png";
import { useContext } from "react";
import NotyfContext from "../../contexts/NotyfContext";

const Sidebar = ({ items, showFooter = true }) => {
  const { isOpen, position, setPosition } = useSidebar();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [activeUser, setActiveUser] = useState({});
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const [whitelabelUrl, setWhitelabelUrl] = useState(pos2cloud);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [impersonationUser, user]);

  useEffect(() => {
    if (
      whitelabel &&
      whitelabel?.whitelabelImage &&
      whitelabel?.whitelabelImage?.imagePath
    ) {
      setWhitelabelUrl(
        `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${whitelabel.whitelabelImage.imagePath}`
      );
    } else {
      setWhitelabelUrl(pos2cloud);
    }
  }, [whitelabel]);

  return (
    <nav className={`sidebar ${position} ${!isOpen ? "collapsed" : ""}`}>
      <div
        className="sidebar-content"
        style={{
          "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
          "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
        }}
      >
        <PerfectScrollbar>
          <a className="sidebar-brand text-center" href="/">
            <Image
              src={whitelabelUrl}
              alt=""
              style={{ maxWidth: "250px", maxHeight: "100px" }}
            />
          </a>

          <SidebarNav items={items} />
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
