import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Card } from "react-bootstrap";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import {
  countDataSessionsForImsiList,
  countZeroSessionsForImsiList,
  getCountsForImsiList,
  sumDataVolumeForImsiList,
} from "../../../redux/slices/dataSessions";
import NotyfContext from "../../../contexts/NotyfContext";

const StatisticsPanel = ({ selectedRows }) => {
  const { t } = useTranslation();
  const notyf = useContext(NotyfContext);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  // const [dataVolumeSelectedRows, setDataVolumeSelectedRows] = useState(0);
  // const [dataSessionsSelectedRows, setDataSessionsSelectedRows] = useState(0);
  // const [zeroSessionsSelectedRows, setZeroSessionsSelectedRows] = useState(0);
  const dataSessions = useSelector(
    (state) => state.dataSessions.dataSessionsCount
  );
  const zeroSessions = useSelector(
    (state) => state.dataSessions.zeroSessionsCount
  );
  const dataVolume = useSelector(
    (state) => state.dataSessions.dataVolumesCount
  );
  const dispatch = useDispatch();

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return 0;

    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  }

  const formatDataVolume = (volume) => {
    if (volume < 1000) {
      return `${volume.toFixed(2)} Bytes`;
    } else if (volume < 1000 * 1000) {
      return `${(volume / 1000).toFixed(2)} KB`;
    } else if (volume < 1000 * 1000 * 1000) {
      return `${(volume / (1000 * 1000)).toFixed(2)} MB`;
    } else {
      return `${(volume / (1000 * 1000 * 1000)).toFixed(2)} GB`;
    }
  };

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [impersonationUser, user]);

  useEffect(() => {
    const fetchSelectedRowsStatistics = async () => {
      await dispatch(
        getCountsForImsiList(selectedRows.map((row) => row.imsi))
      ).catch((error) => {
        notyf.open({
          type: "danger",
          message: error,
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "center",
            y: "top",
          },
        });
      });
    };

    if (activeUser.id) {
      fetchSelectedRowsStatistics();
    }
  }, [selectedRows, activeUser]);

  return (
    <div className="w-100">
      <Row>
        <Col sm="4" lg="12" className="d-flex col-xxl-4">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("sims.dataVolume")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                <CountUp
                  key="dataVolume"
                  start={0}
                  end={dataVolume}
                  duration={2.75}
                  decimals={3}
                  formattingFn={formatDataVolume}
                ></CountUp>
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="4" lg="12" className="d-flex col-xxl-4">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("sims.dataSessions")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                <CountUp start={0} end={dataSessions} duration={2.75}></CountUp>
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="4" lg="12" className="d-flex col-xxl-4">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("sims.zeroSessions")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                <CountUp start={0} end={zeroSessions} duration={2.75} />
              </span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default StatisticsPanel;
