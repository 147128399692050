import { Formik } from "formik";
import React, { useMemo, useContext } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import EditEntityForm from "./EditEntityForm";
import countryList from "react-select-country-list";
import { useDispatch } from "react-redux";
import { updateTenantDetails } from "../../../redux/slices/tenants";
import NotyfContext from "../../../contexts/NotyfContext";
import { updateManagerDetails } from "../../../redux/slices/managers";
import { updateCustomerDetails } from "../../../redux/slices/customers";
import { updateSubaccountDetails } from "../../../redux/slices/subaccounts";
import { useTranslation } from "react-i18next";

const EditEntityModal = ({
  showEditEntity,
  setShowEditEntity,
  entity,
  type,
}) => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const countryOptions = useMemo(() => {
    const options = countryList().getData();
    return [{ label: "Select a country", value: "" }, ...options];
  }, []);
  const initialValues = {
    fullName: entity?.fullName,
    address: type !== "Subaccount" ? entity?.address : "",
    city: type !== "Subaccount" ? entity?.city : "",
    country: type !== "Subaccount" ? entity?.country : "",
    postcode: type !== "Subaccount" ? entity?.postcode : "",
    telephoneNumber: type !== "Subaccount" ? entity?.telephone : "",
    mobileNumber: type !== "Subaccount" ? entity?.mobileNumber : "",
    email: entity?.email,
    occupation:
      type !== "Subaccount" && type !== "Admin" ? entity?.occupation : "",
    company: type !== "Subaccount" && type !== "Admin" ? entity?.company : "",
    tin: type !== "Subaccount" && type !== "Admin" ? entity?.tinNumber : "",
    username: entity?.username,
  };

  const validation = Yup.object().shape({
    fullName: Yup.string().required("Required"),
    address:
      type !== "Subaccount"
        ? Yup.string().required("Required")
        : Yup.string().nullable(),
    city:
      type !== "Subaccount"
        ? Yup.string().required("Required")
        : Yup.string().nullable(),
    country:
      type !== "Subaccount"
        ? Yup.string().required("Required")
        : Yup.string().nullable(),
    postcode:
      type !== "Subaccount"
        ? Yup.string().required("Required")
        : Yup.string().nullable(),
    telephoneNumber:
      type !== "Subaccount"
        ? Yup.string().required("Required")
        : Yup.string().nullable(),
    mobileNumber:
      type !== "Subaccount"
        ? Yup.string().required("Required")
        : Yup.string().nullable(),
    email: Yup.string().email("Invalid email").required("Required"),
    occupation:
      type !== "Subaccount" && type !== "Admin"
        ? Yup.string().required("Required")
        : Yup.string().nullable(),
    company:
      type !== "Subaccount" && type !== "Admin"
        ? Yup.string().required("Required")
        : Yup.string().nullable(),
    tin:
      type !== "Subaccount" && type !== "Admin"
        ? Yup.string().required("Required")
        : Yup.string().nullable(),
    username: Yup.string().required("Required"),
  });

  return (
    <Modal
      show={showEditEntity}
      onHide={() => setShowEditEntity(false)}
      centered
      scrollable
      backdrop="static"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {type === "Admin"
            ? t("crm.editModal.editAdmin")
            : type === "Tenant"
            ? t("crm.editModal.editTenant")
            : type === "Manager"
            ? t("crm.editModal.editManager")
            : type === "Customer"
            ? t("crm.editModal.editCustomer")
            : type === "Subaccount"
            ? t("crm.editModal.editSubaccount")
            : null}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={async (values) => {
            if (type === "Tenant") {
              await dispatch(
                updateTenantDetails({ id: entity.id, values: values })
              )
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Tenant updated successfully",
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  setShowEditEntity(false);
                });
            } else if (type === "Manager") {
              await dispatch(
                updateManagerDetails({ id: entity.id, values: values })
              )
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Manager updated successfully",
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  setShowEditEntity(false);
                });
            } else if (type === "Customer") {
              await dispatch(updateCustomerDetails({ id: entity.id, values }))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Customer updated successfully",
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  setShowEditEntity(false);
                });
            } else if (type === "Subaccount") {
              const data = {
                fullName: values.fullName,
                email: values.email,
                username: values.username,
              };
              await dispatch(
                updateSubaccountDetails({ id: entity.id, values: data })
              )
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Subaccount updated successfully",
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  setShowEditEntity(false);
                });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            isSubmitting,
            touched,
            values,
            setValues,
            setFieldValue,
          }) => {
            return (
              <EditEntityForm
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                type={type}
                setValues={setValues}
                setShowEditEntity={setShowEditEntity}
                initialValues={initialValues}
                handleSubmit={handleSubmit}
                countryOptions={countryOptions}
              />
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default EditEntityModal;
