import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../../contexts/NotyfContext";
import * as Yup from "yup";
import { Button, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import { updateEmail } from "../../../redux/slices/distribution";
import { useTranslation } from "react-i18next";

const UpdateEmailModal = ({
  showUpdateEmailModal,
  setShowUpdateEmailModal,
  email,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const initialValues = {
    category: email?.category ?? "",
    name: email?.name ?? "",
    address: email?.address ?? "",
  };

  const validation = Yup.object().shape({
    category: Yup.string().required("Category is required"),
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
  });
  return (
    <Modal
      show={showUpdateEmailModal}
      onHide={() => setShowUpdateEmailModal(false)}
      centered
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("emails.updateEmail")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={async (values, { setValues }) => {
            await dispatch(updateEmail({ id: email.id, data: values }))
              .then(() => {
                notyf.open({
                  type: "success",
                  message: "Email updated successfully",
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .catch((error) => {
                notyf.open({
                  type: "danger",
                  message: error,
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .finally(() => {
                setValues(initialValues);
                setShowUpdateEmailModal(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            setValues,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("emails.category")}</Form.Label>
                  <Form.Select
                    name="category"
                    value={values.category}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={Boolean(touched.category && errors.category)}
                  >
                    <option value="">{t("emails.selectCategory")}</option>
                    <option value="Invoice">{t("emails.invoice")}</option>
                    <option value="Watchdog">{t("emails.watchdog")}</option>
                  </Form.Select>
                  {!!touched.category && (
                    <Form.Control.Feedback type="invalid">
                      {errors.category}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("emails.name")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={Boolean(touched.name && errors.name)}
                  />
                  {!!touched.name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("emails.address")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={Boolean(touched.address && errors.address)}
                  />
                  {!!touched.address && (
                    <Form.Control.Feedback type="invalid">
                      {errors.address}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    style={{
                      "--dynamic-bg-color":
                        whitelabel?.backgroundColor ?? "#00acdc",
                      "--dynamic-font-color":
                        whitelabel?.fontColor ?? "#ffffff",
                      border: "none",
                    }}
                    onClick={() => {
                      setValues(initialValues);
                      setShowUpdateEmailModal(false);
                    }}
                  >
                    {t("emails.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    style={{
                      "--dynamic-bg-color":
                        whitelabel?.backgroundColor ?? "#00acdc",
                      "--dynamic-font-color":
                        whitelabel?.fontColor ?? "#ffffff",
                      border: "none",
                    }}
                  >
                    {t("emails.submit")}
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateEmailModal;
