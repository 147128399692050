import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Billing = ({ handleBlur, handleChange, values, errors, touched }) => {
  const cc = require("currency-codes");
  const currencies = cc.codes();
  const { t } = useTranslation();

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{t("crm.newModal.minimumAmountToInvoice")}</Form.Label>
        <Form.Control
          type="text"
          name="minimumAmountToInvoice"
          value={values.minimumAmountToInvoice}
          onBlur={handleBlur}
          onChange={handleChange}
          isInvalid={Boolean(
            touched.minimumAmountToInvoice && errors.minimumAmountToInvoice
          )}
        />
        {!!touched.minimumAmountToInvoice && (
          <Form.Control.Feedback type="invalid">
            {errors.minimumAmountToInvoice}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>{t("crm.newModal.invoiceType")}</Form.Label>
        <Form.Select
          name="invoiceType"
          value={values.invoiceType}
          onBlur={handleBlur}
          onChange={handleChange}
          isInvalid={Boolean(touched.invoiceType && errors.invoiceType)}
        >
          <option key="" value="">
            Select invoice type
          </option>
          <option key="none" value="none">
            None
          </option>
          <option key="standard" value="standard">
            Standard
          </option>
          <option key="portfolio" value="portfolio">
            Portfolio
          </option>
          <option key="domain" value="domain">
            Domain
          </option>
        </Form.Select>
        {!!touched.invoiceType && (
          <Form.Control.Feedback type="invalid">
            {errors.invoiceType}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Invoice Handler Type</Form.Label>
        <Form.Select
          name="invoiceHandlerType"
          value={values.invoiceHandlerType}
          onBlur={handleBlur}
          onChange={handleChange}
          isInvalid={Boolean(
            touched.invoiceHandlerType && errors.invoiceHandlerType
          )}
        >
          <option key="auto" value="auto">
            auto
          </option>
          <option key="manual" value="manual">
            manual
          </option>
        </Form.Select>
        {!!touched.invoiceHandlerType && (
          <Form.Control.Feedback type="invalid">
            {errors.invoiceHandlerType}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>{t("crm.newModal.currency")}</Form.Label>
        <Form.Select
          name="currency"
          value={values.currency}
          onBlur={handleBlur}
          onChange={handleChange}
          isInvalid={Boolean(touched.currency && errors.currency)}
        >
          {currencies.map((currency) => (
            <option key={currency} value={currency}>
              {currency}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Invoice Configuration</Form.Label>
        <Form.Select
          name="invoiceConfiguration"
          value={values.invoiceConfiguration}
          onBlur={handleBlur}
          onChange={handleChange}
          isInvalid={Boolean(
            touched.invoiceConfiguration && errors.invoiceConfiguration
          )}
        >
          <option key="skytrackInvoice" value="skytrackInvoice">
            skytrackInvoice
          </option>
        </Form.Select>
        {!!touched.invoiceConfiguration && (
          <Form.Control.Feedback type="invalid">
            {errors.invoiceConfiguration}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>{t("crm.newModal.preferredLanguage")}</Form.Label>
        <Form.Select
          name="preferredLanguage"
          value={values.preferredLanguage}
          onBlur={handleBlur}
          onChange={handleChange}
          isInvalid={Boolean(
            touched.preferredLanguage && errors.preferredLanguage
          )}
        >
          <option key="greek" value="greek">
            Greek
          </option>
          <option key="english" value="english">
            English
          </option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Billing Email</Form.Label>
        <Form.Control
          type="email"
          name="billingEmail"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.billingEmail}
          isInvalid={Boolean(touched.billingEmail && errors.billingEmail)}
        />
        <Form.Control.Feedback type="invalid">
          {Boolean(touched.billingEmail && errors.billingEmail)}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default Billing;
