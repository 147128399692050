import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";

import Clients from "./Clients";

import { useDispatch, useSelector } from "react-redux";
import { getTenants } from "../../redux/slices/tenants";
import Loader from "../../components/spinners/Loader";
import NotyfContext from "../../contexts/NotyfContext";

const Tenants = () => {
  const dispatch = useDispatch();
  const tenants = useSelector((state) => state.tenants.tenants);
  const { user } = useSelector((state) => state.user);
  const [activeUser, setActiveUser] = useState({});
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [isLoading, setIsLoading] = useState(true);
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchTenants = async () => {
      await dispatch(getTenants(activeUser.id))
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (activeUser.id) {
      fetchTenants();
    }
  }, [dispatch, activeUser]);

  return (
    <React.Fragment>
      <Helmet title="Tenants" />
      <Container fluid className="p-0 w-100 h-100">
        <Clients title="Tenants" clientList={tenants} isLoading={isLoading} />
      </Container>
    </React.Fragment>
  );
};

export default Tenants;
