import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
  unreadNotifications: 0,
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
    setUnreadNotifications(state, action) {
      state.unreadNotifications = action.payload;
    },
    updateNotification(state, action) {
      const { id, notificationDetails } = action.payload;
      const updatedNotifications = state.notifications.map((notification) => {
        if (notification.id === id) {
          return notificationDetails;
        }
        return notification;
      });
      return {
        ...state,
        notifications: updatedNotifications,
      };
    },
    addNotification(state, action) {
      const newNotification = action.payload;
      return {
        ...state,
        notifications: [...state.notifications, newNotification],
      };
    },
    incrementUnreadNotifications(state) {
      return {
        ...state,
        unreadNotifications: state.unreadNotifications + 1,
      };
    },
  },
});

export function getNotifications() {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/notifications`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(notificationSlice.actions.setNotifications(response.data.data));
      dispatch(
        notificationSlice.actions.setUnreadNotifications(
          response.data.data.filter((notification) => !notification.read).length
        )
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function markAllAsRead() {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/notifications/read/all`,
        {},
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(notificationSlice.actions.setNotifications(response.data.data));
      dispatch(notificationSlice.actions.setUnreadNotifications(0));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export const {
  setNotifications,
  updateNotification,
  addNotification,
  setUnreadNotifications,
  incrementUnreadNotifications,
} = notificationSlice.actions;

export default notificationSlice.reducer;
