import React, { useEffect, useState, useContext } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { Card, Dropdown, Col } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";

import { getDistributionForSim } from "../../../../redux/slices/sims";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../../../contexts/NotyfContext";

const SIMBarChart = ({ sim, period }) => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [type, setType] = useState("data_volume");
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(true);
  const [barData, setBarData] = useState([]);
  const dispatch = useDispatch();
  const by_month = useSelector((state) => state.sims.by_month);
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchSimDistribution = async () => {
      await dispatch(getDistributionForSim({ id: sim.id, type: type }))
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => setIsLoading(false));
    };

    if (sim.id && activeUser.id) {
      fetchSimDistribution();
    }
  }, [sim.id, activeUser, type]);

  function calculateData(item, type) {
    switch (type) {
      case "data_volume":
        return parseFloat(item.totalBytes / 1024.0 / 1024.0).toFixed(2);
      case "data_sessions":
        return item.dataSessions;
      case "zero_sessions":
        return item.dataSessions;
      case "alerts":
        return item.alerts;
      default:
        return 0;
    }
  }

  const data = {
    labels: by_month.map((item) => item.period),
    datasets: [
      {
        label:
          type === "data_volume"
            ? t("Data Volume")
            : type === "data_sessions"
            ? t("Data Sessions")
            : type === "alerts"
            ? t("Alerts")
            : type === "zero_sessions"
            ? t("Zero Sessions")
            : type === "extra_charge"
            ? t("Extra Charge")
            : null,
        backgroundColor: whitelabel?.backgroundColor ?? "#00acdc",
        borderColor: whitelabel?.backgroundColor ?? "#00acdc",
        hoverBackgroundColor: whitelabel?.backgroundColor ?? "#00acdc",
        hoverBorderColor: whitelabel?.backgroundColor ?? "#00acdc",
        data: by_month.map((item) => calculateData(item, type)),
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cornerRadius: 15,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            stepSize: 20,
          },
          stacked: true,
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "transparent",
          },
          stacked: true,
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let value = tooltipItem.yLabel;
          if (type === "data_volume") {
            return value + " MB";
          }
          return value;
        },
      },
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title className="mb-0">
          {type === "data_volume"
            ? t("simProfile.dataVolume")
            : type === "data_sessions"
            ? t("simProfile.dataSessions")
            : // : type === "alerts"
            // ? t("simProfile.alerts")
            type === "zero_sessions"
            ? t("simProfile.zeroSessions")
            : // : type === "extra_charge"
              // ? t("Extra Charge")
              null}
        </Card.Title>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setType("data_volume")}>
                {t("simProfile.dataVolume")}
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={() => setType("alerts")}>
                {t("simProfile.alerts")}
              </Dropdown.Item> */}
              <Dropdown.Item onClick={() => setType("data_sessions")}>
                {t("simProfile.dataSessions")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("zero_sessions")}>
                {t("simProfile.zeroSessions")}
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={() => setType("extraCharge")}>
                {t("Extra Charge")}
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Header>
      <Card.Body className="d-flex">
        <Col>
          <div className="align-self-center w-100">
            <div className="chart">
              <Bar data={data} options={options} />
            </div>
          </div>
        </Col>
      </Card.Body>
    </Card>
  );
};

export default SIMBarChart;
