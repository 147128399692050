import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { addTenant, removeTenant } from "./tenants";
import { addManager, removeManager } from "./managers";
import { addCustomer, removeCustomer } from "./customers";
import { impersonateUser } from "./impersonationUser";

const initialState = {
  user: {
    id: "",
    fullName: "",
    address: "",
    city: "",
    country: "",
    postcode: "",
    telephoneNumber: "",
    mobileNumber: "",
    email: "",
    occupation: "",
    company: "",
    tin: "",
    username: "",
    privilege: "",
    photo: "",
    activities: null,
    is2FAEnabled: "",
    domainCompany: "",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const data = {
        id: String(action.payload.id),
        fullName: String(action.payload.fullName),
        address: String(action.payload.address),
        city: String(action.payload.city),
        country: String(action.payload.country),
        postcode: String(action.payload.postcode),
        telephoneNumber: String(action.payload.telephoneNumber),
        mobileNumber: action.payload.mobileNumber
          ? String(action.payload.mobileNumber)
          : "",
        email: String(action.payload.email),
        occupation: String(action.payload.occupation),
        company: String(action.payload.company),
        tin: String(action.payload.tinNumber),
        username: String(action.payload.username),
        role: String(action.payload.role),
        photo:
          action.payload.image && action.payload.image.imagePath
            ? String(action.payload.image.imagePath)
            : "",
        activities: action.payload.activities,
        is2FAEnabled: action.payload.mfaEnabled,
        whitelabelingEnabled: action.payload.whitelabelingEnabled,
        privileges:
          action.payload.role === "subaccount"
            ? action.payload.privileges
            : null,
        admin:
          action.payload.role !== "admin" && action.payload.admin
            ? action.payload.admin
            : null,
        tenant:
          action.payload.role !== "admin" &&
          action.payload.role !== "tenant" &&
          action.payload.tenant
            ? action.payload.tenant
            : null,
        manager:
          action.payload.role !== "admin" &&
          action.payload.role !== "tenant" &&
          action.payload.role !== "manager" &&
          action.payload.manager
            ? action.payload.manager
            : null,
        customer:
          action.payload.role !== "admin" &&
          action.payload.role !== "tenant" &&
          action.payload.role !== "manager" &&
          action.payload.role !== "customer" &&
          action.payload.customer
            ? action.payload.customer
            : null,
        domainCompany: action.payload.domainCompany,
      };
      state.user = data;
    },
    clearUser: (state) => {
      state.user = initialState;
    },
    updateFullName: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          fullName: String(action.payload),
        },
      };
    },
    updateAddress: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          address: String(action.payload),
        },
      };
    },
    updateCity: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          city: String(action.payload),
        },
      };
    },
    updateCountry: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          country: String(action.payload),
        },
      };
    },
    updatePostcode: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          postcode: String(action.payload),
        },
      };
    },
    updateTelephone: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          telephoneNumber: String(action.payload),
        },
      };
    },
    updateMobile: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          mobileNumber: String(action.payload),
        },
      };
    },
    updateEmail: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          email: String(action.payload),
        },
      };
    },
    updateOccupation: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          occupation: String(action.payload),
        },
      };
    },
    updateCompany: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          company: String(action.payload),
        },
      };
    },
    updateTIN: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          tin: String(action.payload),
        },
      };
    },
    updatePrivilege: (state, action) => {
      return {
        ...state,
        privilege: String(action.payload),
      };
    },
    updateIs2FAEnabled: (state, action) => {
      const newState = {
        ...state,
        user: {
          ...state.user,
          is2FAEnabled: String(action.payload),
        },
      };
      return newState;
    },
  },
});

export const {
  setUser,
  clearUser,
  updateFullName,
  updateAddress,
  updateCity,
  updateCountry,
  updatePostcode,
  updateTelephone,
  updateEmail,
  updateOccupation,
  updateCompany,
  updateTIN,
  updatePrivilege,
  updateIs2FAEnabled,
} = userSlice.actions;
export default userSlice.reducer;

export function updateUserRole({ entityUser, data }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/user/role/${entityUser.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      if (entityUser.privilege === "TENANT") {
        dispatch(removeTenant(entityUser.id));
        if (data.newRole === "manager") {
          dispatch(addManager(response.data.data));
        } else if (data.newRole === "customer") {
          dispatch(addCustomer(response.data.data));
        }
      } else if (entityUser.privilege === "MANAGER") {
        dispatch(removeManager(entityUser.id));
        if (data.newRole === "tenant") {
          dispatch(addTenant(response.data.data));
        } else if (data.newRole === "customer") {
          dispatch(addCustomer(response.data.data));
        }
      } else if (entityUser.privilege === "CUSTOMER") {
        dispatch(removeCustomer(entityUser.id));
        if (data.newRole === "tenant") {
          dispatch(addTenant(response.data.data));
        } else if (data.newRole === "manager") {
          dispatch(addManager(response.data.data));
        }
      }
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function updateUserAccount({ data }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/user`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      if (impersonationToken) {
        dispatch(impersonateUser(response.data.data));
      } else {
        dispatch(setUser(response.data.data));
      }
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export const loginUser = (userData) => (dispatch) => {
  dispatch(setUser(userData));
};

export const logoutUser = () => (dispatch) => {
  dispatch(clearUser());
};

export const updateCurrentUserFullName = (fullName) => (dispatch) => {
  dispatch(userSlice.actions.updateFullName(fullName));
};

export const updateCurrentUserAddress = (address) => (dispatch) => {
  dispatch(userSlice.actions.updateAddress(address));
};

export const updateCurrentUserCity = (city) => (dispatch) => {
  dispatch(userSlice.actions.updateCity(city));
};

export const updateCurrentUserCountry = (country) => (dispatch) => {
  dispatch(userSlice.actions.updateCountry(country));
};

export const updateCurrentUserPostcode = (postcode) => (dispatch) => {
  dispatch(userSlice.actions.updatePostcode(postcode));
};

export const updateCurrentUserTelephone = (telephone) => (dispatch) => {
  dispatch(userSlice.actions.updateTelephone(telephone));
};

export const updateCurrentUserMobile = (mobile) => (dispatch) => {
  dispatch(userSlice.actions.updateMobile(mobile));
};

export const updateCurrentUserEmail = (email) => (dispatch) => {
  dispatch(userSlice.actions.updateEmail(email));
};

export const updateCurrentUserOccupation = (occupation) => (dispatch) => {
  dispatch(userSlice.actions.updateOccupation(occupation));
};

export const updateCurrentUserCompany = (company) => (dispatch) => {
  dispatch(userSlice.actions.updateCompany(company));
};

export const updateCurrentUserTIN = (tin) => (dispatch) => {
  dispatch(userSlice.actions.updateTIN(tin));
};

export const updateCurrentUserPrivilege = (privilege) => (dispatch) => {
  dispatch(userSlice.actions.updatePrivilege(privilege));
};

export const updateDistributionList = (distributionList) => (dispatch) => {
  dispatch(userSlice.actions.setDistributionList(distributionList));
};

export const updateCurrentUserIs2FAEnabled = (is2FAEnabled) => (dispatch) => {
  dispatch(userSlice.actions.updateIs2FAEnabled(is2FAEnabled));
};
