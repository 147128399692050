import React, { useState, useEffect, useContext } from "react";
import { Card, Spinner, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../contexts/NotyfContext";
import pos2cloud from "../../assets/img/pos2cloud.png";

const Loader = () => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [activeUser]);

  return (
    <div
      className="spinner-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "50px",
        height: "100%",
        width: "100%",
      }}
    >
      <Image
        src={
          whitelabel?.whitelabelImage && whitelabel?.whitelabelImage?.imagePath
            ? `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${whitelabel?.whitelabelImage?.imagePath}`
            : pos2cloud
        }
        style={{ maxHeight: "300px", width: "auto" }}
        alt="Spinner"
      />
      <Spinner
        animation="border"
        role="status"
        style={{
          "--dynamic-spinner-color": whitelabel?.backgroundColor ?? "#00acdc",
        }}
      />
    </div>
  );
};

export default Loader;
