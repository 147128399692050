import React, { useState, useEffect } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import privileges from "../../../redux/slices/privileges";
import { useTranslation } from "react-i18next";

const Navigation = ({ isWhitelabelAvailable, isMfaAvailable }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">
          {t("settings.profileSettings")}
        </Card.Title>
      </Card.Header>
      <ListGroup
        variant="flush"
        style={{
          "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
          "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
        }}
      >
        <ListGroup.Item tag="General" href="#General" action>
          {t("settings.general")}
        </ListGroup.Item>
        <ListGroup.Item tag="Account" href="#Account" action>
          {t("settings.account")}
        </ListGroup.Item>
        <ListGroup.Item tag="Profile Image" href="#ProfileImage" action>
          {t("settings.profileImage")}
        </ListGroup.Item>
        {isWhitelabelAvailable && (
          <ListGroup.Item tag="Whitelabel" href="#Whitelabel" action>
            {t("settings.whitelabel")}
          </ListGroup.Item>
        )}
        {isMfaAvailable && (
          <ListGroup.Item tag="Privacy" href="#Privacy" action>
            {t("settings.privacyAndSafety")}
          </ListGroup.Item>
        )}
        <ListGroup.Item tag="Notifications" href="#Notifications" action>
          {t("settings.notifications")}
        </ListGroup.Item>
        {(activeUser.role === "tenant" ||
          activeUser.role === "manager" ||
          activeUser.role === "customer") && (
          <ListGroup.Item tag="Billing" href="#Billing" action>
            {t("settings.billing")}
          </ListGroup.Item>
        )}
        {(activeUser.role === "admin" ||
          activeUser.role === "tenant" ||
          activeUser.role === "manager") && (
          <ListGroup.Item tag="Invoice" href="#Invoice" action>
            {t("settings.invoice")}
          </ListGroup.Item>
        )}
      </ListGroup>
    </Card>
  );
};

export default Navigation;
