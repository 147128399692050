import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/spinners/Loader";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Table } from "react-bootstrap";
import { getStatements } from "../../redux/slices/statements";
import StatementsList from "./StatementsList";
import NotyfContext from "../../contexts/NotyfContext";
import pos2cloud from "../../assets/img/pos2cloud.png";

const Statements = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { statements } = useSelector((state) => state.statements);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [activeUser, setActiveUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchStatements = async () => {
      await Promise.all([dispatch(getStatements())])
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            dismissible: false,
            ripple: true,
            duration: 5000,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });

      setIsLoading(false);
    };

    if (activeUser.id) {
      fetchStatements();
    }
  }, [dispatch, activeUser]);

  if (isLoading) {
    return <Loader />;
  } else {
    if (statements.length > 0) {
      return (
        <React.Fragment>
          <Helmet title="Statements">
            <link
              rel="icon"
              href={
                whitelabel?.tabImage
                  ? `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${whitelabel?.tabImage?.imagePath}`
                  : pos2cloud
              }
            />
          </Helmet>
          <Container fluid className="p-0">
            <h1 className="h3 mb-3">{t("statements.title")}</h1>
            <Row>
              <Col md="12">
                <StatementsList data={statements} />
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Helmet title="Statements">
            <link
              rel="icon"
              href={
                whitelabel?.tabImage
                  ? `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${whitelabel?.tabImage?.imagePath}`
                  : pos2cloud
              }
            />
          </Helmet>
          <Container fluid className="p-0">
            <h1 className="h3 mb-3">{t("statements.title")}</h1>
            <Row>
              <Col md="12">
                <Table>
                  <thead>
                    <tr>
                      <th>{t("No data found")}</th>
                    </tr>
                  </thead>
                </Table>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  }
};

export default Statements;
