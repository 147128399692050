import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../../../contexts/NotyfContext";
import {
  deleteReportTemplate,
  getReportTemplates,
} from "../../../../redux/slices/reports";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Row,
  Table,
} from "react-bootstrap";
import {
  useTable,
  useRowSelect,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from "react-table";
import { useTranslation } from "react-i18next";
import getAutomatedReportColumns from "./automatedReportColumns";
import DefaultColumnFilter from "../../../../utils/DefaultColumnFilter";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import NewAutomatedReport from "./NewAutomatedReport";
import Loader from "../../../../components/spinners/Loader";
import EditAutomatedReport from "./EditAutomatedReport";

const AutomatedReports = () => {
  const { user } = useSelector((state) => state.user);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const reportTemplates = useSelector((state) => state.reports.reportTemplates);
  const [activeUser, setActiveUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const { automatedReportColumns } = useMemo(
    () => getAutomatedReportColumns(t),
    [t]
  );
  const [showNewAutomatedReport, setShowNewAutomatedReport] = useState(false);
  const [reportTemplateToEdit, setReportTemplateToEdit] = useState({});
  const [showEditAutomatedReport, setShowEditAutomatedReport] = useState(false);

  const columns = useMemo(() => {
    return [
      ...automatedReportColumns,
      {
        id: "actions",
        Header: t("reports.automated.actions"),
        Cell: ({ row }) => (
          <DropdownButton
            data-boundary="viewport"
            as={ButtonGroup}
            className="actions-dropdown"
            title={t("reports.automated.actions")}
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              border: "none",
            }}
          >
            <Dropdown.Item
              onClick={() => {
                setReportTemplateToEdit(row.original);
                setShowEditAutomatedReport(true);
              }}
            >
              {t("reports.automated.edit")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={async () => {
                await dispatch(deleteReportTemplate({ id: row.original.id }))
                  .then(() => {
                    notyf.open({
                      type: "success",
                      message: "Report template deleted successfully",
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: { x: "center", y: "top" },
                    });
                  })
                  .catch((error) => {
                    notyf.open({
                      type: "danger",
                      message: error,
                      duration: 5000,
                      ripple: true,
                      dismissible: false,
                      position: { x: "center", y: "top" },
                    });
                  });
              }}
            >
              {t("reports.automated.delete")}
            </Dropdown.Item>
          </DropdownButton>
        ),
      },
    ];
  }, [t, whitelabel]);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchReportTemplates = async () => {
      await dispatch(getReportTemplates())
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: { x: "center", y: "top" },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
    };

    if (activeUser.id) {
      fetchReportTemplates();
    }
  }, [activeUser]);

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: reportTemplates,
      initialState: { pageIndex: 0 },
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );
  if (isLoading) {
    return (
      <Card>
        <Card.Body>
          <Loader />
        </Card.Body>
      </Card>
    );
  }
  return (
    <Card>
      <Card.Header>
        <Card.Title>{t("reports.automated.title")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className="g-0">
          <Col sm="12" xl="12" className="col-xxl-12">
            <div className="d-flex mt-2 float-end">
              <Button
                style={{
                  "--dynamic-bg-color":
                    whitelabel?.backgroundColor ?? "#00acdc",
                  "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                  border: "none",
                }}
                onClick={() => setShowNewAutomatedReport(true)}
              >
                {t("reports.automated.new")}
              </Button>
            </div>
          </Col>
        </Row>
        <Table id="clientsTable" hover responsive {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      <span>
                        {column.sortable ? (
                          column.isSorted ? (
                            column.isSortedDesc ? (
                              <FaSortUp className="ms-2 " />
                            ) : (
                              <FaSortDown className="ms-2 " />
                            )
                          ) : (
                            <FaSort className="ms-2" />
                          )
                        ) : null}
                      </span>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <NewAutomatedReport
          showNewAutomatedReport={showNewAutomatedReport}
          setShowNewAutomatedReport={setShowNewAutomatedReport}
          activeUser={activeUser}
        />
        <EditAutomatedReport
          showEditAutomatedReport={showEditAutomatedReport}
          setShowEditAutomatedReport={setShowEditAutomatedReport}
          reportTemplateToEdit={reportTemplateToEdit}
          activeUser={activeUser}
        />
      </Card.Body>
    </Card>
  );
};

export default AutomatedReports;
