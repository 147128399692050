import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import SendSmsForm from "./SendSmsForm";
import Loader from "../../../components/spinners/Loader";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import { sendSms } from "../../../redux/slices/sms";
import NotyfContext from "../../../contexts/NotyfContext";

const SmsModal = ({ openSmsModal, setOpenSmsModal, sims }) => {
  const handleClose = () => {
    setCurrentStep(1);
    setOpenSmsModal(false);
  };
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [selectedSims, setSelectedSims] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();

  useEffect(() => {
    const formattedSelectedSims = sims.map((sim) => ({
      imsi: sim.imsi,
      iccid: sim.iccid,
    }));
    setSelectedSims(formattedSelectedSims);
  }, [sims]);

  const initialValues = {
    imsiList: sims.map((sim) => sim.imsi),
    fromType: "",
    from: "",
    messageType: "",
    textContent: "",
    base64Content: "",
  };

  const validation = Yup.object().shape({
    fromType: Yup.string().required("Required"),
    from: Yup.string().max(20).required("Required"),
    messageType: Yup.string().required("Required"),
    textContent: Yup.string().when("messageType", {
      is: (val) => val === "text" || val === "unicode",
      then: Yup.string().max(160, "Max 160 characters").required("Required"),
      otherwise: Yup.string().max(70, "Max 70 characters").required("Required"),
    }),
    base64Content: Yup.string().when("messageType", {
      is: "binary",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
  });

  return (
    <Modal show={openSmsModal} backdrop="static" centered onHide={handleClose}>
      <Modal.Header>Send SMS</Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={currentStep === 1 ? validation : null}
          onSubmit={async (values, { setValues, setStatus }) => {
            await dispatch(
              sendSms({
                imsiList: values.imsiList,
                fromType: values.fromType,
                from: values.from,
                messageType: values.messageType,
                textContent: values.textContent,
                base64Content: values.base64Content,
              })
            )
              .then(() => {
                setStatus("Success");
                notyf.open({
                  type: "success",
                  message: "SMS sent successfully",
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .catch((error) => {
                setStatus("Error");
                notyf.open({
                  type: "danger",
                  message: error,
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .finally(() => {
                setCurrentStep(1);
                setValues(initialValues);
                setOpenSmsModal(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
            setValues,
            validateForm,
            setFieldValue,
            setFieldTouched,
          }) => {
            const nextStep = () => {
              validateForm().then((errors) => {
                // Only transition to the next step if there are no errors
                if (Object.keys(errors).length === 0) {
                  if (currentStep === 2) {
                    handleSubmit();
                  } else if (currentStep === 1 || currentStep === 3) {
                    setCurrentStep(currentStep + 1);
                  }
                } else {
                  // Force all fields to touched
                  Object.keys(values).forEach((field) => {
                    setFieldTouched(field);
                  });
                }
              });
            };

            const previousStep = () => {
              if (currentStep === 2) {
                setCurrentStep(currentStep - 1);
              }
            };

            return (
              <React.Fragment>
                {currentStep === 1 && (
                  <Form onSubmit={handleSubmit}>
                    <SendSmsForm
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      nextStep={nextStep}
                      currentStep={currentStep}
                    />
                  </Form>
                )}
                {currentStep === 2 && (
                  <Table responsive hover>
                    <thead>
                      <th>IMSI</th>
                      <th>ICCID</th>
                    </thead>
                    <tbody>
                      {selectedSims.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.imsi}</td>
                            <td>{item.iccid}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                {currentStep === 3 && <Loader />}
                {currentStep === 4 && status === "Success" && (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <FaCheckCircle size={120} color="#4BBF73" />
                    <h3>Success</h3>
                  </div>
                )}
                {currentStep === 4 && status === "Error" && (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <FaExclamationCircle size={120} color="#FF0000" />
                    <h3 className="mt-3">Order Error</h3>
                    <p>Please contact the administrator.</p>
                  </div>
                )}
                <Modal.Footer>
                  {currentStep === 1 && (
                    <>
                      <Button
                        variant="primary"
                        onClick={() => {
                          setValues(initialValues);
                          setCurrentStep(1);
                          setOpenSmsModal(false);
                        }}
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        onClick={nextStep}
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                        }}
                      >
                        Next
                      </Button>
                    </>
                  )}

                  {currentStep === 2 && (
                    <>
                      <Button
                        variant="primary"
                        onClick={previousStep}
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                        }}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="primary"
                        onClick={nextStep}
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                        }}
                      >
                        Submit
                      </Button>
                    </>
                  )}
                  {currentStep === 4 && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        setValues(initialValues);
                        setCurrentStep(1);
                        setOpenSmsModal(false);
                      }}
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                        border: "none",
                      }}
                    >
                      Close
                    </Button>
                  )}
                </Modal.Footer>
              </React.Fragment>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default SmsModal;
