import React from "react";
import { useEffect } from "react";
import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getInvoices } from "../../redux/slices/invoices";
import Loader from "../../components/spinners/Loader";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Table } from "react-bootstrap";
import InvoicesList from "./InvoicesList";
import BalanceCard from "./BalanceCard";
import NotyfContext from "../../contexts/NotyfContext";
import pos2cloud from "../../assets/img/pos2cloud.png";

const Invoices = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { invoices } = useSelector((state) => state.invoices);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const notyf = useContext(NotyfContext);
  const [activeUser, setActiveUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [impersonationUser, user]);

  useEffect(() => {
    const fetchInvoices = async () => {
      await Promise.all([dispatch(getInvoices())])
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (activeUser.id) {
      fetchInvoices();
    }
  }, [dispatch, activeUser]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <React.Fragment>
      <Helmet title="Invoices">
        <link
          rel="icon"
          href={
            whitelabel?.tablmage && whitelabel?.tablmage?.imagePath
              ? `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${whitelabel?.tablmage?.imagePath}`
              : pos2cloud
          }
        />
      </Helmet>
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{t("invoices.title")}</h1>
        <InvoicesList data={invoices} />
      </Container>
    </React.Fragment>
  );
};

export default Invoices;
