import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";

import Clients from "./Clients";

import { useDispatch, useSelector } from "react-redux";
import { getAdmins } from "../../redux/slices/admins";
import Loader from "../../components/spinners/Loader";
import NotyfContext from "../../contexts/NotyfContext";
import { useTranslation } from "react-i18next";

const Admins = () => {
  const dispatch = useDispatch();
  const { admins } = useSelector((state) => state.admins);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchAdmins = async () => {
      await dispatch(getAdmins())
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (activeUser.id) {
      fetchAdmins();
    }
  }, [dispatch, activeUser.id]);

  return (
    <React.Fragment>
      <Helmet title="Admins" />
      <Container fluid className="p-0 w-100 h-100">
        <Clients title={"Admins"} clientList={admins} isLoading={isLoading} />
      </Container>
    </React.Fragment>
  );
};

export default Admins;
