import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alerts: [],
};

const alertSlice = createSlice({
  name: "alertSlice",
  initialState,
  reducers: {
    setAlerts(state, action) {
      state.alerts = action.payload;
    },
  },
});

export async function initializeAlerts(id) {
  const accessToken = window.localStorage.getItem("accessToken");
  const impersonationToken = window.localStorage.getItem("impersonationToken");
  if (!accessToken) return Promise.reject("No access token");
  try {
    const data = {
      id: id,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/alerts/init/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${
            impersonationToken ? impersonationToken : accessToken
          }`,
        },
      }
    );
    return Promise.resolve(response.data.message);
  } catch (error) {
    return Promise.reject(error.message);
  }
}

export function getAlerts() {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/alerts`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(alertSlice.actions.setAlerts(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export async function getAlertComparison(id, period) {
  try {
    const accessToken = window.localStorage.getItem("accessToken");
    const response = await axios.get(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/alerts/${id}/count?period=${period}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAlertCountForSim(id, period, imsi) {
  try {
    const accessToken = window.localStorage.getItem("accessToken");
    const response = await axios.get(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/alerts/${id}/countForSim?period=${period}&imsi=${imsi}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export const { setAlerts } = alertSlice.actions;

export default alertSlice.reducer;
