import React, { useMemo } from "react";
import { Card } from "react-bootstrap";
import InvoicesTable from "./InvoicesTable";
import getInvoiceData from "../../inputData/invoiceData";
import { useTranslation } from "react-i18next";

const InvoicesList = (props) => {
  const { t } = useTranslation();
  const { invoicesColumns } = useMemo(() => getInvoiceData(t), [t]);
  return (
    <Card>
      <Card.Body>
        <InvoicesTable data={props.data} columns={invoicesColumns} />
      </Card.Body>
    </Card>
  );
};

export default InvoicesList;
