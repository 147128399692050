import countryList from "react-select-country-list";
import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subaccounts: [],
};

const subaccountsSlice = createSlice({
  name: "subaccounts",
  initialState,
  reducers: {
    setSubaccounts(state, action) {
      state.subaccounts = action.payload;
    },
    updateSubaccount(state, action) {
      const { id, subaccountDetails } = action.payload;
      const updatedSubaccounts = state.subaccounts.map((subaccount) => {
        if (subaccount.id === id) {
          return subaccountDetails;
        }
        return subaccount;
      });
      return {
        ...state,
        subaccounts: updatedSubaccounts,
      };
    },
    removeSubaccount(state, action) {
      const id = action.payload;
      const updatedSubaccounts = state.subaccounts.filter(
        (subaccount) => subaccount.id !== id
      );
      return {
        ...state,
        subaccounts: updatedSubaccounts,
      };
    },
    updateFullName(state, action) {
      const { id, fullName } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            fullName: fullName,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateAddress(state, action) {
      const { id, address } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            address: address,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateCity(state, action) {
      const { id, city } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            city: city,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateCountry(state, action) {
      const { id, country } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            country: country,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updatePostcode(state, action) {
      const { id, postcode } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            postcode: postcode,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateTelephone(state, action) {
      const { id, telephone } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            telephone: telephone,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateMobile(state, action) {
      const { id, mobile } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            mobileNumber: mobile,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateEmail(state, action) {
      const { id, email } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            email: email,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateOccupation(state, action) {
      const { id, occupation } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            occupation: occupation,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateCompany(state, action) {
      const { id, company } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            company: company,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updateTIN(state, action) {
      const { id, tin } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            tinNumber: tin,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    updatePrivilege(state, action) {
      const { id, privilege } = action.payload;
      const updatedUsers = state.subaccounts.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            privilege: privilege,
          };
        }
        return user;
      });
      return {
        ...state,
        subaccounts: updatedUsers,
      };
    },
    addSubaccount: (state, action) => {
      const newUser = action.payload;
      return {
        ...state,
        subaccounts: [...state.subaccounts, newUser],
      };
    },
    removeSubaccount: (state, action) => {
      const idToRemove = action.payload;
      const newUsers = state.subaccounts.filter(
        (user) => user.id !== idToRemove
      );
      return {
        ...state,
        subaccounts: newUsers,
      };
    },
  },
});

export function getSubaccounts() {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/subaccounts`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(subaccountsSlice.actions.setSubaccounts(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      dispatch(subaccountsSlice.actions.setSubaccounts([]));
      return Promise.reject(error.message);
    }
  };
}

export function updateSubaccountDetails({ id, values }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/subaccounts/${id}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        subaccountsSlice.actions.updateSubaccount({
          id,
          subaccountDetails: response.data.data,
        })
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function deleteSubaccount(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/subaccounts/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(subaccountsSlice.actions.removeSubaccount(id));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function updateSubaccountName(id, fullName) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("fullName", fullName);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/fullName",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateFullName({ id, fullName }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export function updateSubaccountAddress(id, address) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("address", address);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/address",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateAddress({ id, address }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export function updateSubaccountCity(id, city) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("city", city);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/city",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateCity({ id, city }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export function updateSubaccountCountry(id, country) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("country", country);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/country",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateCountry({ id, country }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export function updateSubaccountPostcode(id, postcode) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("postcode", postcode);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/postcode",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updatePostcode({ id, postcode }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export function updateSubaccountTelephone(id, telephone) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("telephone", telephone);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/telephone",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateTelephone({ id, telephone }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export function updateSubaccountMobile(id, mobile) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("mobile", mobile);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/mobile",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateMobile({ id, mobile }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export function updateSubaccountEmail(id, email) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("email", email);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/email",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateEmail({ id, email }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export function updateSubAccountOccupation(id, occupation) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("occupation", occupation);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/occupation",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateOccupation({ id, occupation }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export function updateSubaccountCompany(id, company) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("company", company);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/company",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateCompany({ id, company }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export function updateSubaccountTIN(id, tin) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("tin", tin);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/tin",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(subaccountsSlice.actions.updateTIN({ id, tin }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export function updateSubaccountPrivilege(
  userId,
  clientId,
  privilege,
  upperEntityId
) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("entityId", clientId);
    formData.append("privilege", privilege);
    if (privilege !== "ADMIN" && privilege !== "VIEWER") {
      formData.append("upperEntityId", upperEntityId);
    } else {
      formData.append("upperEntityId", -1);
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          userId +
          "/update/privilege",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(
        subaccountsSlice.actions.updatePrivilege({ clientId, privilege })
      );
      dispatch(subaccountsSlice.actions.removeUser({ clientId }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export function addNewSubaccount({ values }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const data = {
        fullName: values.fullName,
        email: values.email,
        username: values.username,
        sendWelcomeMail: values.sendWelcomeEmail,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/subaccounts/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(subaccountsSlice.actions.addSubaccount(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function removeExistingSubaccount(id) {
  return (dispatch) => {
    dispatch(subaccountsSlice.actions.removeSubaccount(id));
  };
}

export const {
  setSubaccounts,
  updateFullName,
  updateAddress,
  updateCity,
  updateCountry,
  updatePostcode,
  updateTelephone,
  updateEmail,
  updateOccupation,
  updateCompany,
  updateTIN,
  updatePrivilege,
  addSubaccount,
  removeSubaccount,
  updateSubaccount,
} = subaccountsSlice.actions;
export default subaccountsSlice.reducer;
