import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  Pagination,
  Table,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { useTable, useExpanded, usePagination } from "react-table";
import Loader from "../../../../components/spinners/Loader";
import { MinusCircle, PlusCircle } from "react-feather";
import { getSimSms } from "../../../../redux/slices/sms";
import NotyfContext from "../../../../contexts/NotyfContext";
import { useTranslation } from "react-i18next";

const SmsUsageModal = ({ imsi, showSmsModal, setShowSmsModal }) => {
  const { t } = useTranslation();
  const handleClose = () => setShowSmsModal(false);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const dispatch = useDispatch();
  const sms = useSelector((state) => state.sms.sms);
  const outboundSms = useSelector((state) => state.sms.outboundSms);
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchSms = async () => {
      await dispatch(getSimSms({ imsi: imsi }))
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => setIsLoading(false));
    };

    if (imsi && activeUser.id && showSmsModal) {
      fetchSms();
    }
  }, [imsi, activeUser, showSmsModal]);

  const columns = React.useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
              <MinusCircle className="feather" />
            ) : (
              <PlusCircle className="feather" />
            )}
          </span>
        ),
        Cell: ({ row }) => (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            {row.isExpanded ? (
              <MinusCircle className="feather" />
            ) : (
              <PlusCircle className="feather" />
            )}
          </span>
        ),
      },
      // Your column definitions here
      {
        Header: t("simProfile.smsUsageModal.direction"),
        accessor: "direction",
      },
      { Header: t("simProfile.smsUsageModal.date"), accessor: "insertedDate" },
      { Header: t("simProfile.smsUsageModal.sender"), accessor: "sender" },
      { Header: t("simProfile.smsUsageModal.message"), accessor: "message" },
      { Header: t("simProfile.smsUsageModal.status"), accessor: "status" },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: sms,
      initialState: { pageIndex: 0 },
    },
    useExpanded,
    usePagination
  );

  return (
    <Modal
      backdrop="static"
      size="lg"
      centered
      show={showSmsModal}
      onHide={handleClose}
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("simProfile.smsUsageModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <Loader />}
        {!isLoading && sms.length === 0 && <p>No data</p>}
        {!isLoading && sms.length > 0 && (
          <React.Fragment>
            <Table {...getTableProps()} responsive="true">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <React.Fragment>
                      {/* Your original row */}
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                      {/* Expanded row content */}
                      {row.isExpanded ? (
                        <tr>
                          <td
                            colSpan={columns.length}
                            style={{ padding: "1rem", background: "#f7f7f7" }}
                          >
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                gap: "20px",
                              }}
                            >
                              <div>
                                <strong>Message Reference:</strong>{" "}
                                {row.original.messageReference}
                                <br />
                                <strong>Sender:</strong> {row.original.sender}
                                <br />
                                <strong>Date Inserted:</strong>{" "}
                                {row.original.insertedDate}
                              </div>
                              <div>
                                <strong>Message:</strong> {row.original.message}
                                <br />
                                <strong>Status:</strong> {row.original.status}{" "}
                                <br />
                                <strong>SMS Type:</strong>{" "}
                                {row.original.smsType}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
            <Row>
              <Col md="9">
                <span className="mx-2">
                  {t("table.page")}{" "}
                  <strong>
                    {pageIndex + 1} {t("table.of")} {pageOptions.length}
                  </strong>
                </span>
                <span className="ms-3 me-2">{t("table.show")}:</span>
                <Form.Select
                  className="d-inline-block w-auto"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </Form.Select>

                <span className="ms-3 me-2">{t("table.goToPage")}:</span>
                <Form.Control
                  className="d-inline-block"
                  type="number"
                  defaultValue={pageIndex + 1}
                  min={1}
                  max={pageCount}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  style={{ width: "75px" }}
                />
              </Col>
              <Col md="3">
                <Pagination className="float-end">
                  <Pagination.First
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  />
                  <Pagination.Prev
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  />
                  <Pagination.Next
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  />
                  <Pagination.Last
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  />
                </Pagination>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
            "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
            border: "none",
          }}
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SmsUsageModal;
