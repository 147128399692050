export const getNotificationData = (t) => {
  const notificationColumns = [
    {
      Header: t("notifications.id"),
      accessor: "id",
      sortable: false,
    },
    {
      Header: t("notifications.type"),
      accessor: "type",
      sortable: true,
      Cell: ({ row }) => {
        if (row.original.type === "INVOICE") {
          return <>{t("Invoice")}</>;
        } else {
          return <>{t("Watchdog")}</>;
        }
      },
    },
    {
      Header: t("notifications.message"),
      accessor: "message",
      sortable: false,
    },
    {
      Header: t("notifications.date"),
      accessor: "createdAt",
      sortable: true,
    },
  ];

  return { notificationColumns };
};

export default getNotificationData;
