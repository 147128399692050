import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Offcanvas, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../../../contexts/NotyfContext";
import { clearAllTenants, getTenants } from "../../../../redux/slices/tenants";
import {
  clearAllManagers,
  getManagers,
} from "../../../../redux/slices/managers";
import {
  clearAllCustomers,
  getCustomers,
} from "../../../../redux/slices/customers";
import EditAutomatedReportForm from "./EditAutomatedReportForm";
import Loader from "../../../../components/spinners/Loader";
import { editReportTemplate } from "../../../../redux/slices/reports";

const EditAutomatedReport = ({
  showEditAutomatedReport,
  setShowEditAutomatedReport,
  reportTemplateToEdit,
  activeUser,
}) => {
  const [usageType, setUsageType] = useState("");
  const [groupByField, setGroupByField] = useState("");
  const [groupByPeriod, setGroupByPeriod] = useState("");
  const [nextCollapse, setNextCollapse] = useState(null);
  const [reportTypeCollapse, setReportTypeCollapse] = useState(
    reportTemplateToEdit?.reportType || ""
  );
  const reportTypeCollapseRef = useRef(reportTypeCollapse);
  const [prevReportType, setPrevReportType] = useState(null);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const tenants = useSelector((state) => state.tenants.tenants);
  const managers = useSelector((state) => state.managers.managers);
  const customers = useSelector((state) => state.customers.customers);
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (reportTemplateToEdit && reportTemplateToEdit.reportType) {
      setReportTypeCollapse(reportTemplateToEdit.reportType);
    }
  }, [reportTemplateToEdit.reportType]);

  useEffect(() => {
    reportTypeCollapseRef.current = reportTypeCollapse;
  }, [reportTypeCollapse]);

  useEffect(() => {
    const clearLists = () => {
      dispatch(clearAllTenants());
      dispatch(clearAllManagers());
      dispatch(clearAllCustomers());
    };

    const fetchCustomersList = async () => {
      await dispatch(getCustomers());
    };
    const fetchTenantsList = async () => {
      await dispatch(getTenants());
    };
    const fetchManagersList = async () => {
      await dispatch(getManagers());
    };

    if (activeUser.id && showEditAutomatedReport) {
      clearLists();
      if (activeUser.role === "admin") {
        fetchTenantsList();
      }
      if (activeUser.role === "admin" || activeUser.role === "tenant") {
        fetchManagersList();
      }
      if (
        activeUser.role === "admin" ||
        activeUser.role === "tenant" ||
        activeUser.role === "manager"
      ) {
        fetchCustomersList();
      }
      if (activeUser.role === "customer") {
        dispatch(clearAllTenants());
        dispatch(clearAllManagers());
        dispatch(clearAllCustomers());
      }
    }
  }, [activeUser]);

  const initialValues = {
    name: reportTemplateToEdit?.name ?? "",
    reportTemplateType: reportTemplateToEdit?.reportType ?? "",
    reportFrequency: reportTemplateToEdit?.reportFrequency ?? "",
    reportFormat: reportTemplateToEdit?.reportFormat ?? "",
    usageType: reportTemplateToEdit?.usageType ?? "",
    groupByField: reportTemplateToEdit?.groupByField ?? "",
    groupByPeriod: reportTemplateToEdit?.groupByPeriod ?? "",
    userId: reportTemplateToEdit?.userId ?? "",
    simSearchCriteria: reportTemplateToEdit?.simSearchCriteria ?? "",
    imsi: reportTemplateToEdit?.imsi ?? "",
    startImsi: reportTemplateToEdit?.startImsi ?? "",
    endImsi: reportTemplateToEdit?.endImsi ?? "",
    iccid: reportTemplateToEdit?.iccid ?? "",
  };

  const validation = Yup.object().shape({
    name: Yup.string().required("Required"),
    reportTemplateType: Yup.string().required("Required"),
    reportFrequency: Yup.string().required("Required"),
  });

  return (
    <Offcanvas
      show={showEditAutomatedReport}
      onHide={() => setShowEditAutomatedReport(false)}
      scroll={true}
      backdrop={false}
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{t("reports.automated.edit")}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={async (values, { setValues }) => {
            await dispatch(
              editReportTemplate({ id: reportTemplateToEdit.id, data: values })
            )
              .then(() => {
                notyf.open({
                  type: "success",
                  message: "Report template updated successfully",
                  ripple: true,
                  dismissible: false,
                  duration: 5000,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .catch((error) => {
                notyf.open({
                  type: "danger",
                  message: error,
                  ripple: true,
                  dismissible: false,
                  duration: 5000,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .finally(() => {
                setShowEditAutomatedReport(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => {
            return (
              <EditAutomatedReportForm
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                isSubmitting={isSubmitting}
                reportTemplateToEdit={reportTemplateToEdit}
                tenants={tenants}
                managers={managers}
                customers={customers}
                notyf={notyf}
                t={t}
                whitelabel={whitelabel}
                activeUser={activeUser}
                nextCollapse={nextCollapse}
                setNextCollapse={setNextCollapse}
                reportTypeCollapse={reportTypeCollapse}
                setReportTypeCollapse={setReportTypeCollapse}
                prevReportType={prevReportType}
                setPrevReportType={setPrevReportType}
                usageType={usageType}
                setUsageType={setUsageType}
                setGroupByPeriod={setGroupByPeriod}
                setGroupByField={setGroupByField}
              />
            );
          }}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default EditAutomatedReport;
