import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Dropdown, Image } from "react-bootstrap";

import { PieChart, Settings, User } from "react-feather";

import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { SIDEBAR_POSITION } from "../../constants";
import useSidebar from "../../hooks/useSidebar";
import defaultUser from "../../assets/img/avatars/default-user.png";

const NavbarUser = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { signOut } = useAuth();
  const { user } = useSelector((state) => state.user);
  const [fullName, setFullName] = useState("");
  const [image, setImage] = useState();
  const { setPosition } = useSidebar();

  function loadAnalytics() {
    navigate("/dashboard/default");
  }

  function loadProfile() {
    navigate("/pages/profile");
  }

  function loadSettings() {
    navigate("/pages/settings");
  }

  function loadHelp() {
    navigate("/pages/support");
  }

  useEffect(() => {
    return () => {
      // Cancel any subscriptions and clear up any asynchronous tasks here
    };
  }, []);

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <Image
            src={
              user.photo
                ? `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${user.photo}`
                : defaultUser
            }
            className="avatar img-fluid rounded-circle me-1"
          />
          <span className="text-dark">{user.fullName}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        <Dropdown.Item onClick={loadProfile}>
          <User size={18} className="align-middle me-2" />
          {t("Profile")}
        </Dropdown.Item>
        <Dropdown.Item onClick={loadAnalytics}>
          <PieChart size={18} className="align-middle me-2" />
          {t("Dashboard")}
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={loadSettings}>{t("Settings")}</Dropdown.Item>
        {/* <Dropdown.Item onClick={loadHelp}>{t("Help")}</Dropdown.Item> */}
        <Dropdown.Item
          onClick={async function () {
            try {
              await signOut();
              setPosition(SIDEBAR_POSITION.LEFT);
              navigate("/auth/sign-in");
            } catch (error) {
              navigate("/auth/sign-in");
            }
          }}
        >
          {t("Sign out")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
