import React, { useState } from "react";
import { Form } from "react-bootstrap";

function NumberRangeColumnFilterInput({
  column: { filterValue = [], preFilteredRows, setFilter, id },
  filterIsShown,
  setFilterIsShown,
}) {
  return (
    <Form.Group className="mt-1">
      <div className="d-flex">
        <Form.Control
          value={filterValue[0] || ""}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              val ? parseInt(val, 10) : undefined,
              old[1],
            ]);
          }}
          placeholder={`Min`}
        />
        <span className="mx-2 mt-1">-</span>
        <Form.Control
          value={filterValue[1] || ""}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              old[0],
              val ? parseInt(val, 10) : undefined,
            ]);
          }}
          placeholder={`Max`}
        />
      </div>
    </Form.Group>
  );
}

export default function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [filterIsShown, setFilterIsShown] = useState(false);

  return (
    <NumberRangeColumnFilterInput
      column={{ filterValue, preFilteredRows, setFilter }}
      filterIsShown={filterIsShown}
      setFilterIsShown={setFilterIsShown}
    />
  );
}
