import axios from "../../utils/axios";

export async function sendWelcomeMail(id) {
  const impersonationToken = window.localStorage.getItem("impersonationToken");
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const data = {
      id: id,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/mail/sendWelcomeEmail`,
      data,
      {
        headers: {
          Authorization: `Bearer ${
            impersonationToken ? impersonationToken : accessToken
          }`,
        },
      }
    );
    return Promise.resolve(response.data.message);
  } catch (error) {
    return Promise.reject(error.message);
  }
}
