import React, { useState, useEffect, useRef, useContext } from "react";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import pos2cloud from "../../../assets/img/pos2cloud.png";
import * as Yup from "yup";
import { Formik } from "formik";
import NotyfContext from "../../../contexts/NotyfContext";
import { FaUpload } from "react-icons/fa";
import { ChromePicker, HuePicker } from "react-color";
import { updateWhitelabelDetails } from "../../../redux/slices/whitelabel";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";

const WhitelabelSettings = ({ activeTab }) => {
  const { t } = useTranslation();
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const faviconRef = useRef(null);
  const whitelabelRef = useRef(null);
  const notyf = useContext(NotyfContext);
  const [faviconPreview, setFaviconPreview] = useState(pos2cloud);
  const [whitelabelPreview, setWhitelabelPreview] = useState(pos2cloud);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (activeTab === "#Whitelabel") {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [activeTab]);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [impersonationUser, user]);

  useEffect(() => {
    if (whitelabel?.tabImage && whitelabel?.tabImage?.imagePath) {
      setFaviconPreview(
        `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${whitelabel?.tabImage?.imagePath}`
      );
    } else {
      setFaviconPreview(pos2cloud);
    }
  }, [whitelabel]);

  useEffect(() => {
    if (whitelabel?.whitelabelImage && whitelabel?.whitelabelImage.imagePath) {
      setWhitelabelPreview(
        `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${whitelabel?.whitelabelImage.imagePath}`
      );
    } else {
      setWhitelabelPreview(pos2cloud);
    }
  }, [whitelabel]);

  const initialValues = {
    backgroundColor: whitelabel?.backgroundColor ?? "#00acdc",
    fontColor: whitelabel?.fontColor ?? "#ffffff",
    favicon: whitelabel?.tabImage?.imagePath ?? pos2cloud,
    whitelabelImage: whitelabel?.whitelabelImage?.imagePath ?? pos2cloud,
    sidebarPosition: whitelabel?.sidebarPosition ?? "left",
  };

  const validation = Yup.object().shape({
    backgroundColor: Yup.string(),
    fontColor: Yup.string(),
    favicon: Yup.mixed(),
    whitelabelImage: Yup.mixed(),
    sidebarPosition: Yup.string(),
  });

  const handleUpload = (ref) => {
    ref.current?.click();
  };

  const handleDisplayFileDetails = (event, field, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setFieldValue(field, file);
        if (field === "favicon") {
          setFaviconPreview(reader.result);
        } else {
          setWhitelabelPreview(reader.result);
        }
      }
    };
    reader.onerror = () => {
      notyf.open({
        type: "danger",
        message: reader.error,
        duration: 5000,
        ripple: true,
        dismissible: false,
        position: {
          x: "center",
          y: "top",
        },
      });
    };
    reader.readAsDataURL(file);
  };

  return (
    <Card style={{ backgroundColor: "#f8f9fa" }}>
      <Card.Header style={{ backgroundColor: "#f8f9fa" }}>
        <Card.Title>{t("settings.whitelabelSettings.title")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && <Loader />}
        {!isLoading && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values) => {
              const data = {
                backgroundColor: values.backgroundColor,
                fontColor: values.fontColor,
                sidebarPosition: values.sidebarPosition,
              };
              if (values.favicon instanceof File) {
                data.favicon = values.favicon;
              }
              if (values.whitelabelImage instanceof File) {
                data.whitelabelImage = values.whitelabelImage;
              }
              await dispatch(updateWhitelabelDetails({ data: data }))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Whitelabel settings updated successfully",
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div
                    className="mb-3 d-flex"
                    style={{
                      padding: "20px",
                      borderRadius: "20px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Col>
                      <Form.Group
                        className="mb-4"
                        style={{ width: "fit-content" }}
                      >
                        <Form.Label>
                          {t("settings.whitelabelSettings.backgroundColor")}
                        </Form.Label>
                        <div className="d-flex mb-3 align-items-center">
                          <Form.Control
                            className="me-3"
                            type="color"
                            name="backgroundColor"
                            value={values.backgroundColor}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.backgroundColor && errors.backgroundColor
                            }
                          />
                          <HuePicker
                            color={values.backgroundColor}
                            onChange={(color) =>
                              setFieldValue("backgroundColor", color.hex)
                            }
                          />
                        </div>
                        <Form.Control
                          type="text"
                          name="backgroundColor"
                          value={values.backgroundColor}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.backgroundColor && errors.backgroundColor
                          }
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-4"
                        style={{ width: "fit-content" }}
                      >
                        <Form.Label>
                          {t("settings.whitelabelSettings.fontColor")}
                        </Form.Label>
                        <div className="d-flex mb-3 align-items-center">
                          <Form.Control
                            className="me-3"
                            type="color"
                            name="fontColor"
                            value={values.fontColor}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.fontColor && errors.fontColor}
                          />
                          <HuePicker
                            color={values.fontColor}
                            onChange={(color) =>
                              setFieldValue("fontColor", color.hex)
                            }
                          />
                        </div>
                        <Form.Control
                          type="text"
                          name="fontColor"
                          value={values.fontColor}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.fontColor && errors.fontColor}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t("settings.whitelabelSettings.sidebarPosition")}
                        </Form.Label>
                        <Form.Check
                          type="radio"
                          label="Left"
                          name="sidebarPosition"
                          checked={values.sidebarPosition === "left"}
                          onChange={(e) =>
                            setFieldValue("sidebarPosition", "left")
                          }
                          onBlur={handleBlur}
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#fff",
                            border: "none",
                          }}
                        />
                        <Form.Check
                          type="radio"
                          label="Right"
                          name="sidebarPosition"
                          checked={values.sidebarPosition === "right"}
                          onChange={(e) =>
                            setFieldValue("sidebarPosition", "right")
                          }
                          onBlur={handleBlur}
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#fff",
                            border: "none",
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </div>
                  <div
                    className="d-flex mb-3"
                    style={{
                      padding: "20px",
                      borderRadius: "20px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Col>
                      <Form.Group className="mb-3 d-flex flex-column align-items-center">
                        <Form.Label>
                          {t("settings.whitelabelSettings.favicon")}
                        </Form.Label>
                        <div
                          className="d-flex flex-column"
                          style={{ width: "fit-content" }}
                        >
                          <Image
                            src={faviconPreview}
                            alt="favicon"
                            roundedCircle
                            width={150}
                            height={150}
                          />
                          <input
                            ref={faviconRef}
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) =>
                              handleDisplayFileDetails(
                                e,
                                "favicon",
                                setFieldValue
                              )
                            }
                          />
                          <Button
                            variant="primary"
                            onClick={() => handleUpload(faviconRef)}
                            className="mt-3"
                            style={{
                              "--dynamic-bg-color":
                                whitelabel?.backgroundColor ?? "#00acdc",
                              "--dynamic-font-color":
                                whitelabel?.fontColor ?? "#ffffff",
                              border: "none",
                              padding: "5px 15px",
                            }}
                          >
                            <FaUpload />{" "}
                            {t("settings.whitelabelSettings.upload")}
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3 d-flex flex-column align-items-center">
                        <Form.Label>
                          {t("settings.whitelabelSettings.whitelabelImage")}
                        </Form.Label>
                        <div
                          style={{ width: "fit-content" }}
                          className="d-flex flex-column"
                        >
                          <Image
                            src={whitelabelPreview}
                            alt="whitelabelImage"
                            roundedCircle
                            width={150}
                            height={150}
                          />
                          <input
                            ref={whitelabelRef}
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) =>
                              handleDisplayFileDetails(
                                e,
                                "whitelabelImage",
                                setFieldValue
                              )
                            }
                          />
                          <Button
                            variant="primary"
                            onClick={() => handleUpload(whitelabelRef)}
                            className="mt-3"
                            style={{
                              "--dynamic-bg-color":
                                whitelabel?.backgroundColor ?? "#00acdc",
                              "--dynamic-font-color":
                                whitelabel?.fontColor ?? "#ffffff",
                              border: "none",
                              padding: "5px 15px",
                            }}
                          >
                            <FaUpload />{" "}
                            {t("settings.whitelabelSettings.upload")}
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>
                  </div>

                  <Row>
                    <Col md="auto ms-auto">
                      <Button
                        type="submit"
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#fff",
                          border: "none",
                        }}
                      >
                        {t("settings.whitelabelSettings.save")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        )}
      </Card.Body>
    </Card>
  );
};

export default WhitelabelSettings;
