import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import SupportHeader from "./SupportHeader";
import SupportContent from "./SupportContent";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../../contexts/NotyfContext";
import pos2cloud from "../../../assets/img/pos2cloud.png";

export const SupportPage = () => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  return (
    <React.Fragment className="p-0">
      <Helmet title="Support">
        <link
          rel="icon"
          href={
            whitelabel?.tablmage && whitelabel?.tablmage?.imagePath
              ? `${process.env.REACT_APP_IMAGE_URL}${whitelabel?.tablmage.imagePath}`
              : pos2cloud
          }
        />
      </Helmet>
      <SupportHeader />
      <SupportContent />
    </React.Fragment>
  );
};

export default SupportPage;
