import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  statements: [],
};

const statementSlice = createSlice({
  name: "statements",
  initialState,
  reducers: {
    setStatements(state, action) {
      state.statements = action.payload;
    },
  },
});

export function getStatements() {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statements`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(statementSlice.actions.setStatements(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export const { setStatements } = statementSlice.actions;
export default statementSlice.reducer;
