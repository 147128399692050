import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";
import { Row, Col, Table } from "react-bootstrap";
import OffersList from "./OffersTable";
import { useTranslation } from "react-i18next";
import { getOffers } from "../../redux/slices/offers";
import Loader from "../../components/spinners/Loader";

const Offers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const offers = useSelector((state) => state.offers.offers);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOffers = async (id) => {
      setIsLoading(true);
      await dispatch(getOffers(id));
      setIsLoading(false);
    };

    if (!impersonationUser.id) {
      fetchOffers(user.id);
    } else {
      fetchOffers(impersonationUser.id);
    }
  }, [dispatch, impersonationUser.id, user.id]);

  if (isLoading) {
    return <Loader />;
  } else {
    if (offers.length > 0) {
      return (
        <React.Fragment>
          <Helmet title="Offers" />
          <Container fluid className="p-0">
            <h1 className="h3 mb-3">Offers</h1>
            <Row>
              <Col md="12">
                <OffersList data={offers} />
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Helmet title="Offers" />
          <Container fluid className="p-0">
            <h1 className="h3 mb-3">{t(`Offers`)}</h1>

            <Row>
              <Col md="12">
                <Table>
                  <thead>
                    <tr>
                      <th>{t("No data found.")}</th>
                    </tr>
                  </thead>
                </Table>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  }
};

export default Offers;
