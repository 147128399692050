import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../contexts/NotyfContext";
import { useTranslation } from "react-i18next";
import { getDataVolumePerHour } from "../../redux/slices/dashboard";
import { Card } from "react-bootstrap";
import Loader from "../spinners/Loader";
import { Bubble } from "react-chartjs-2";

const DataVolumePerHourChart = ({ reload }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [activeUser, setActiveUser] = useState({});
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const notyf = useContext(NotyfContext);
  const data_volume_per_hour = useSelector(
    (state) => state.dashboard.data_volume_per_hour
  );
  const [bgColor, setBgColor] = useState("#00acdc");

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user.id, impersonationUser.id]);

  useEffect(() => {
    setBgColor(whitelabel?.backgroundColor ?? "#00acdc");
  }, [whitelabel]);

  useEffect(() => {
    const fetchDataVolumeByHour = async () => {
      setIsLoading(true);
      await dispatch(getDataVolumePerHour())
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (activeUser.id) {
      fetchDataVolumeByHour();
    }
  }, [activeUser, reload]);

  const dayOfWeekNames = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const hourOfDayNames = [
    "12-3 AM",
    "3-6 AM",
    "6-9 AM",
    "9-12 PM",
    "12-3 PM",
    "3-6 PM",
    "6-9 PM",
    "9-12 AM",
  ];

  // Map the dynamic data into a format that can be used by Chart.js for a bubble plot
  const bubbleData = data_volume_per_hour.map((dataPoint) => ({
    x: dataPoint.hourOfDay, // Hour of day on x-axis
    y: dayOfWeekNames[dataPoint.dayOfWeek - 1], // Day of the week on y-axis
    r:
      Math.sqrt(dataPoint.totalUsage) /
      (activeUser.role === "admin" ||
      (activeUser.role === "subaccount" && activeUser.admin)
        ? 1000
        : 500), // Radius of the point (bubble size) based on data usage
  }));

  // Data configuration for the scatter plot
  const data = {
    datasets: [
      {
        label: "Data Usage per Hour",
        data: bubbleData,
        backgroundColor: bgColor,
        borderColor: bgColor,
        borderWidth: 1,
      },
    ],
  };

  // Options configuration for the scatter plot
  const options = {
    layout: {
      padding: {
        top: 25,
      },
    },
    maintainAspectRatio: false,
    cornerRadius: 15,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const dayNames = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ];
          const hourNames = [
            "12-3 AM",
            "3-6 AM",
            "6-9 AM",
            "9-12 PM",
            "12-3 PM",
            "3-6 PM",
            "6-9 PM",
            "9-12 AM",
          ];
          const dayName = tooltipItem.yLabel;
          const hourIndex = tooltipItem.xLabel;
          const hourName = hourNames[hourIndex];
          const dataItem =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const totalUsage = Math.pow(
            dataItem.r *
              (activeUser.role === "admin" ||
              (activeUser.role === "subaccount" && activeUser.admin)
                ? 1000
                : 500),
            2
          ); // Reverse the radius calculation to get the original totalUsage
          return `${dayName} ${hourName}: ${parseFloat(
            totalUsage / 1000.0 / 1000.0
          ).toFixed(2)}MB`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          labels: [
            "12-3 AM",
            "3-6 AM",
            "6-9 AM",
            "9-12 PM",
            "12-3 PM",
            "3-6 PM",
            "6-9 PM",
            "9-12 AM",
          ],
          title: {
            display: true,
            text: "Hour of Day",
          },
          ticks: {
            callback: function (value, index) {
              return [
                "12-3 AM",
                "3-6 AM",
                "6-9 AM",
                "9-12 PM",
                "12-3 PM",
                "3-6 PM",
                "6-9 PM",
                "9-12 AM",
              ][value];
            },
            stepSize: 1,
          },
          gridLines: {
            color: "transparent",
          },
        },
      ],
      yAxes: [
        {
          type: "category",
          labels: [
            "",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ],
          title: {
            display: true,
            text: "Day of the Week",
          },
          ticks: {
            reverse: true,
            callback: function (value, index) {
              return [
                "",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ][index];
            },
            stepSize: 1,
          },
          gridLines: {
            color: "transparent",
          },
          stacked: true,
        },
      ],
    },
  };

  return (
    <Card className="flex-fill w-100" style={{ minHeight: "450px" }}>
      <Card.Header>
        <Card.Title className="mb-0">
          {t("dashboard.dataVolumePerHour")}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {!isLoading && data_volume_per_hour.length === 0 && (
          <div className="d-flex justify-content-center align-items-center">
            {t("No data available")}
          </div>
        )}
        {!isLoading && data_volume_per_hour.length > 0 && (
          <Bubble data={data} options={options} />
        )}
        {isLoading && (
          <div className="text-center">
            <Loader animation="border" variant="primary" />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default DataVolumePerHourChart;
