import { createSlice } from "@reduxjs/toolkit";
import privileges from "./privileges";
const initialState = {
  impersonationUser: {
    id: "",
    fullName: "",
    address: "",
    city: "",
    country: "",
    postcode: "",
    telephoneNumber: "",
    mobileNumber: "",
    email: "",
    occupation: "",
    company: "",
    tin: "",
    privilege: "",
    photo: "",
    activities: null,
    is2FAEnabled: "",
    domainCompany: "",
  },
};

const impersonationUserSlice = createSlice({
  name: "impersonationUser",
  initialState,
  reducers: {
    setImpersonationUser: (state, action) => {
      const data = {
        id: String(action.payload.id),
        fullName: String(action.payload.fullName),
        address: String(action.payload.address),
        city: String(action.payload.city),
        country: String(action.payload.country),
        postcode: String(action.payload.postcode),
        telephoneNumber: String(action.payload.telephoneNumber),
        mobileNumber: String(action.payload.mobileNumber),
        email: String(action.payload.email),
        occupation: String(action.payload.occupation),
        company: String(action.payload.company),
        tin: String(action.payload.tinNumber),
        username: String(action.payload.username),
        role: String(action.payload.role),
        photo:
          action.payload.image && action.payload.image.imagePath
            ? String(action.payload?.image?.imagePath)
            : "",
        activities: action.payload.activities,
        is2FAEnabled: action.payload.mfaEnabled,
        whitelabelingEnabled: action.payload.whitelabelingEnabled,
        privileges:
          action.payload.role === "subaccount"
            ? action.payload.privileges
            : null,
        admin:
          action.payload.role !== "admin" && action.payload.admin
            ? action.payload.admin
            : null,
        tenant:
          action.payload.role !== "admin" &&
          action.payload.role !== "tenant" &&
          action.payload.tenant
            ? action.payload.tenant
            : null,
        manager:
          action.payload.role !== "admin" &&
          action.payload.role !== "tenant" &&
          action.payload.role !== "manager" &&
          action.payload.manager
            ? action.payload.manager
            : null,
        customer:
          action.payload.role !== "admin" &&
          action.payload.role !== "tenant" &&
          action.payload.role !== "manager" &&
          action.payload.role !== "customer" &&
          action.payload.customer
            ? action.payload.customer
            : null,
        domainCompany: action.payload.domainCompany,
      };
      state.impersonationUser = data;
    },
    clearImpersonationUser: (state) => {
      state.impersonationUser = initialState;
    },
  },
});

export const { setImpersonationUser, clearImpersonationUser } =
  impersonationUserSlice.actions;

export default impersonationUserSlice.reducer;

export const impersonateUser = (data) => async (dispatch) => {
  await dispatch(setImpersonationUser(data));
};

export const exitImpersonationMode = () => async (dispatch) => {
  dispatch(clearImpersonationUser());
  window.localStorage.removeItem("impersonationToken");
};
