import React, { useEffect, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllPrivileges,
  updateSubaccountPrivileges,
} from "../../../redux/slices/privileges";
import NotyfContext from "../../../contexts/NotyfContext";
import * as Yup from "yup";
import { Formik } from "formik";
import Loader from "../../../components/spinners/Loader";
import AssignPermissionsForm from "./AssignPermissionsForm";

const AssignPermissionsModal = ({
  showAssignPermissionsModal,
  setShowAssignPermissionsModal,
  subaccount,
}) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const privileges = useSelector((state) => state.privileges.privileges);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [impersonationUser, user]);

  useEffect(() => {
    const fetchAllPermissions = async () => {
      await dispatch(fetchAllPrivileges())
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
          setShowAssignPermissionsModal(false);
        })
        .then(() => {
          setIsLoading(false);
        });
    };

    if (activeUser.id && subaccount && subaccount.id) {
      fetchAllPermissions();
    }
  }, [activeUser, subaccount]);

  const initialValues = {
    availablePrivileges: privileges.filter(
      (privilege) =>
        subaccount?.privileges &&
        !subaccount.privileges.some(
          (assignedPrivilege) => assignedPrivilege.id === privilege.id
        )
    ),
    privileges: subaccount?.privileges || [],
  };

  const validation = Yup.object().shape({
    privileges: Yup.array().required("Please select at least one permission"),
  });

  return (
    <Modal
      show={showAssignPermissionsModal}
      onHide={() => setShowAssignPermissionsModal(false)}
      centered
      size="lg"
      scrollable
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Assign Permissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <Loader />}
        {!isLoading && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, { setSubmitting, setValues }) => {
              const data = {
                privileges: values.privileges.map((privilege) => privilege.id),
              };
              await dispatch(
                updateSubaccountPrivileges({ id: subaccount.id, data: data })
              )
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Permissions assigned successfully",
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  setShowAssignPermissionsModal(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setValues,
            }) => {
              return (
                <AssignPermissionsForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  availablePrivileges={privileges}
                  setShowAssignPermissionsModal={setShowAssignPermissionsModal}
                  setValues={setValues}
                  initialValues={initialValues}
                />
              );
            }}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AssignPermissionsModal;
