import React from "react";
import {
  Card,
  Row,
  Button,
  Table,
  Badge,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import SimsMap from "../SimsMap";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { formatDistanceToNow } from "date-fns";
import SimDataSessionsModal from "./SimDataSessionsModal";
import SmsUsageModal from "../modals/SmsUsageModal";
import UpdateLocationModal from "../modals/UpdateLocationModal";
import ImeiLockModal from "../modals/ImeiLockModal";
import HlrProfileModal from "../../../hlr/HlrProfileModal";
import { useSelector } from "react-redux";

const Single = ({ sim, cellInfo, network }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [activityRecords, setActivityRecords] = useState([]);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [showSmsModal, setShowSmsModal] = useState(false);
  const [showUpdateLocationModal, setShowUpdateLocationModal] = useState(false);
  const [showImeiLockModal, setShowImeiLockModal] = useState(false);
  const [showHlrProfileModal, setShowHlrProfileModal] = useState(false);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      You lack necessary permissions, please contact your parent account owner.
    </Tooltip>
  );

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    if (sim && sim.simActivity && sim.simActivity.length > 0) {
      const newActivityRecords = [...sim.simActivity].sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setActivityRecords(newActivityRecords);
    }
  }, [sim]);

  return (
    <Card>
      <Card.Body>
        <Row className="g-0 mb-2">
          <strong>{t("simProfile.actions")}</strong>
        </Row>
        <Row className="mb-3">
          <Col>
            <Button
              className="me-2 mb-1 px-2"
              id="simsHLR"
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
              onClick={() => setShowHlrProfileModal(true)}
            >
              <span>{t("simProfile.hlr")}</span>
            </Button>
            <HlrProfileModal
              showHlrProfileModal={showHlrProfileModal}
              setShowHlrProfileModal={setShowHlrProfileModal}
              sim={sim}
            />
            <Button
              variant="primary"
              className="me-2 mb-1 px-2"
              id="simsHLRProfile"
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
              onClick={() => setShowSessionModal(true)}
            >
              <span>{t("simProfile.session")}</span>
            </Button>
            <SimDataSessionsModal
              id={sim.id}
              showSessionModal={showSessionModal}
              setShowSessionModal={setShowSessionModal}
            />
            <Button
              variant="success"
              className="me-2 mb-1 px-2"
              id="simsSMSUsage"
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
              onClick={() => setShowSmsModal(true)}
            >
              <span>{t("simProfile.smsUsage")}</span>
            </Button>
            <SmsUsageModal
              imsi={sim.imsi}
              showSmsModal={showSmsModal}
              setShowSmsModal={setShowSmsModal}
            />
            {(sim.status === "Active" || sim.status === "Productive") && (
              <OverlayTrigger
                placement="top"
                overlay={
                  activeUser.role === "subaccount" &&
                  !activeUser.privileges.some(
                    (privilege) => privilege.name === "UpdateLocation"
                  ) ? (
                    renderTooltip
                  ) : (
                    <></>
                  )
                }
              >
                <span className="d-inline-block">
                  <Button
                    variant="warning"
                    className="me-2 mb-1 px-2"
                    id="simsUpdateLoc"
                    style={{
                      "--dynamic-bg-color":
                        whitelabel?.backgroundColor ?? "#00acdc",
                      "--dynamic-font-color":
                        whitelabel?.fontColor ?? "#ffffff",
                      border: "none",
                    }}
                    onClick={() => {
                      setShowUpdateLocationModal(true);
                    }}
                    disabled={
                      activeUser.role === "subaccount" &&
                      !activeUser.privileges.some(
                        (privilege) => privilege.name === "UpdateLocation"
                      )
                    }
                  >
                    <span>{t("simProfile.updateLoc")}</span>
                  </Button>
                </span>
              </OverlayTrigger>
            )}
            <UpdateLocationModal
              showUpdateLocationModal={showUpdateLocationModal}
              setShowUpdateLocationModal={setShowUpdateLocationModal}
              sim={sim}
            />
            {sim.status === "Productive" && (
              <OverlayTrigger
                placement="top"
                overlay={
                  activeUser.role === "subaccount" &&
                  !activeUser.privileges.some(
                    (privilege) => privilege.name === "IMEILock"
                  ) ? (
                    renderTooltip
                  ) : (
                    <></>
                  )
                }
              >
                <span className="d-inline-block">
                  <Button
                    variant="info"
                    className="me-2 mb-1 px-2"
                    id="simsActionTest"
                    style={{
                      "--dynamic-bg-color":
                        whitelabel?.backgroundColor ?? "#00acdc",
                      "--dynamic-font-color":
                        whitelabel?.fontColor ?? "#ffffff",
                      border: "none",
                    }}
                    onClick={() => setShowImeiLockModal(true)}
                    disabled={
                      activeUser.role === "subaccount" &&
                      !activeUser.privileges.some(
                        (privilege) => privilege.name === "IMEILock"
                      )
                    }
                  >
                    {t("simProfile.imeiLock")}
                  </Button>
                </span>
              </OverlayTrigger>
            )}
            {sim.status === "Active" &&
              sim.status === "Productive" &&
              activeUser.role}
            <ImeiLockModal
              sim={sim}
              showImeiLockModal={showImeiLockModal}
              setShowImeiLockModal={setShowImeiLockModal}
            />
          </Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>
        <Table size="sm" className="my-2">
          <tbody>
            <tr>
              <th>{t("simProfile.usage")}</th>
              <td>
                {sim.totalBytes !== null
                  ? `${parseFloat(sim.totalMBytes).toFixed(3)}MB`
                  : "0.00MB"}
              </td>
            </tr>
            <tr>
              <th>{t("simProfile.imei")}</th>
              <td>{sim.imei}</td>
            </tr>
            <tr>
              <th>{t("simProfile.dataSession")}</th>
              <td>
                <div
                  className={`circle ${
                    sim.status === "Active"
                      ? "pulse green"
                      : sim.status === "Productive" ||
                        sim.status === "TestProductive"
                      ? "blue"
                      : sim.status === "Suspended"
                      ? "yellow"
                      : sim.status === "OnStock"
                      ? "grey"
                      : sim.status === "Deleted"
                      ? "red"
                      : null
                  }`}
                ></div>
              </td>
            </tr>
            <tr>
              <th>{t("simProfile.status")}</th>
              <td>
                <Badge
                  bg={
                    sim.status === "Productive"
                      ? "primary"
                      : sim.status === "Active"
                      ? "success"
                      : sim.status === "Suspended"
                      ? "warning"
                      : sim.status === "Terminated"
                      ? "danger"
                      : sim.status === "OnStock"
                      ? "light"
                      : sim.status === "Deleted"
                      ? "danger"
                      : sim.status === "TestProductive"
                      ? "primary"
                      : sim.status === "AutoSuspended"
                      ? "warning"
                      : null
                  }
                >
                  {t(sim.status)}
                </Badge>
              </td>
            </tr>
          </tbody>
        </Table>

        <hr />
        <strong>{t("simProfile.map")}</strong>
        <SimsMap
          className="my-3"
          lat={parseFloat(cellInfo.latitude)}
          lon={parseFloat(cellInfo.longitude)}
        />
        <Table size="sm">
          <tbody>
            <tr>
              <th>{t("simProfile.provider")}</th>
              <td>{sim.sku}</td>
            </tr>
            <tr>
              <th>{t("simProfile.iccid")}</th>
              <td>{sim.iccid}</td>
            </tr>
            <tr>
              <th>{t("simProfile.msisdn")}</th>
              <td>{sim.msisdn}</td>
            </tr>
            <tr>
              <th>{t("simProfile.imsi")}</th>
              <td>{sim.imsi}</td>
            </tr>
            <tr>
              <th>{t("simProfile.country")}</th>
              <td>{network?.country ? network?.country : "N/A"}</td>
            </tr>
            <tr>
              <th>{t("simProfile.operator")}</th>
              <td>{network?.network ? network?.network : "N/A"}</td>
            </tr>
            <tr>
              <th>{t("simProfile.area")}</th>
              <td>{cellInfo.area ? cellInfo.area : "N/A"}</td>
            </tr>
            <tr>
              <th>{t("simProfile.cell")}</th>
              <td>{cellInfo.cell ? cellInfo.cell : "N/A"}</td>
            </tr>
            <tr>
              <th>{t("simProfile.longitude")}</th>
              <td>{cellInfo.longitude ? cellInfo.longitude : "N/A"}</td>
            </tr>
            <tr>
              <th>{t("simProfile.latitude")}</th>
              <td>{cellInfo.latitude ? cellInfo.latitude : "N/A"}</td>
            </tr>
            <tr>
              <th>{t("simProfile.cellRange")}</th>
              <td>{cellInfo.range ? cellInfo.range : "N/A"}</td>
            </tr>
          </tbody>
        </Table>
        <hr />

        <strong>{t("simProfile.activity")}</strong>

        <ul className="timeline mt-2">
          {activityRecords.slice(0, 5).map((activityElement, index) => (
            <React.Fragment key={index}>
              {activityElement.type === "ACTIVATE" && (
                <li className="timeline-item">
                  <strong>Activated</strong>
                  <span className="float-end text-muted text-sm">
                    {activityElement.createdAt}
                  </span>
                  <p>{activityElement.description}</p>
                </li>
              )}
              {activityElement.type === "SUSPEND" && (
                <li className="timeline-item">
                  <strong>Suspended</strong>
                  <span className="float-end text-muted text-sm">
                    {activityElement.createdAt}
                  </span>
                  <p>{activityElement.description}</p>
                </li>
              )}
              {activityElement.type === "RESUME" && (
                <li className="timeline-item">
                  <strong>Resumed</strong>
                  <span className="float-end text-muted text-sm">
                    {activityElement.createdAt}
                  </span>
                  <p>{activityElement.description}</p>
                </li>
              )}
              {activityElement.type === "MODIFY" && (
                <li className="timeline-item">
                  <strong>Modified</strong>
                  <span className="float-end text-muted text-sm">
                    {activityElement.createdAt}
                  </span>
                  <p>{activityElement.description}</p>
                </li>
              )}
              {activityElement.type === "TERMINATE" && (
                <li className="timeline-item">
                  <strong>Terminated</strong>
                  <span className="float-end text-muted text-sm">
                    {activityElement.createdAt}
                  </span>
                  <p>{activityElement.description}</p>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      </Card.Body>
    </Card>
  );
};

export default Single;
