import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { fetchWhitelabel } from "../../redux/slices/whitelabel";
import NotyfContext from "../../contexts/NotyfContext";

function ResetPassword() {
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const { whitelabel } = useSelector((state) => state.whitelabel);
  const dispatch = useDispatch();
  const currentUrl = window.location.hostname;
  useEffect(() => {
    dispatch(fetchWhitelabel(currentUrl));
  }, []);
  const notyf = useContext(NotyfContext);

  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        await resetPassword(values.email)
          .then(() => {
            notyf.open({
              type: "success",
              message: "Password successfully reset. Please check your email.",
              dismissible: false,
              ripple: true,
              duration: 8000,
              position: {
                x: "center",
                y: "top",
              },
            });
            navigate("/auth/sign-in");
          })
          .catch((error) => {
            notyf.open({
              type: "danger",
              message: error,
              dismissible: false,
              ripple: true,
              duration: 8000,
              position: {
                x: "center",
                y: "top",
              },
            });
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
            >
              Reset password
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ResetPassword;
