import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  zones: [],
};

const zonesSlice = createSlice({
  name: "zones",
  initialState,
  reducers: {
    setZones(state, action) {
      state.zones = action.payload;
    },
  },
});

export const getZones = () => {
  return async (dispatch) => {
    const impersonationToken = localStorage.getItem("impersonationToken");
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      return Promise.reject("No access token");
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/zone`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(zonesSlice.actions.setZones(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
};

export default zonesSlice.reducer;
