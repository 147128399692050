import React from "react";
import { useSelector } from "react-redux";
import { Field } from "formik";
import { Modal, Form, Button, Collapse, Row, Col } from "react-bootstrap";

const AddWatchdogForm = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleBlur,
  handleSubmit,
  setValues,
  initialValues,
  handleClose,
  clearSelectedSims,
}) => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const zones = useSelector((state) => state.zones.zones);

  function generateZonePairs() {
    const pairs = [];
    for (let i = 0; i < zones.length; i++) {
      for (let j = i + 1; j < zones.length; j++) {
        pairs.push({
          value: [zones[i].id, zones[j].id],
          label: `${zones[i].name} - ${zones[j].name}`,
        });
      }
    }
    return pairs;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Action Type</Form.Label>
        <Form.Select
          name="actionType"
          value={values.actionType}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.actionType && errors.actionType}
        >
          <option value="none">None</option>
          <option value="suspend">Suspend</option>
        </Form.Select>
        {!!touched.actionType && (
          <Form.Control.Feedback type="invalid">
            {errors.actionType}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Watchdog Interval</Form.Label>
        <Form.Select
          name="watchdogInterval"
          value={values.watchdogInterval}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.watchdogInterval && errors.watchdogInterval}
        >
          <option value={0}>None</option>
          <option value={1}>1 day</option>
          <option value={7}>1 week</option>
          <option value={30}>1 month</option>
        </Form.Select>
        {!!touched.watchdogInterval && (
          <Form.Control.Feedback type="invalid">
            {errors.watchdogInterval}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Amount</Form.Label>
        <Field
          type="text"
          name="amount"
          value={values.amount}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.amount && errors.amount}
          className="form-control"
        />
        {!!touched.amount && (
          <Form.Control.Feedback type="invalid">
            {errors.amount}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Service</Form.Label>
        <Form.Select
          name="service"
          value={values.service}
          onChange={(e) => {
            if (e.target.value === "sms") {
              setFieldValue("serviceType", "sms");
              setFieldValue("service", "sms");
            } else {
              setFieldValue("serviceType", "data");
              handleChange(e);
            }
          }}
          onBlur={handleBlur}
          isInvalid={touched.service && errors.service}
        >
          <option value={zones.map((zone) => zone.id).join(",")}>
            All Data
          </option>
          <option value="sms">SMS</option>
          {zones.map((zone) => (
            <option key={zone.id} value={zone.id}>{`${zone.name}`}</option>
          ))}
          {generateZonePairs().map((pair) => (
            <option key={pair.value.join("-")} value={pair.value.join(",")}>
              {pair.label}
            </option>
          ))}
        </Form.Select>
        {!!touched.service && (
          <Form.Control.Feedback type="invalid">
            {errors.service}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Collapse in={values.actionType === "suspend"}>
        <Form.Group className="mb-3">
          <Form.Label>Suspend Interval</Form.Label>
          <Form.Select
            name="suspendInterval"
            value={values.suspendInterval}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.suspendInterval && errors.suspendInterval}
          >
            <option value={0}>None</option>
            <option value={1}>1 day</option>
            <option value={7}>1 week</option>
            <option value={30}>1 month</option>
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Form.Group className="mb-3">
        <Form.Label>Unit Type</Form.Label>
        <Form.Select
          name="unitType"
          value={values.unitType}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.unitType && errors.unitType}
        >
          {!values.service.includes("sms") && (
            <>
              <option value="Bytes">Byte</option>
              <option value="KB">Kilobyte</option>
              <option value="MB">Megabyte</option>
            </>
          )}
          {values.service.includes("sms") && <option value="unit">Unit</option>}
        </Form.Select>
        {!!touched.unitType && (
          <Form.Control.Feedback type="invalid">
            {errors.unitType}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Form.Label>Warning</Form.Label>
          <Form.Control
            type="text"
            name="warning"
            value={values.warning}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.warning && errors.warning}
          />
          {!!touched.warning && (
            <Form.Control.Feedback type="invalid">
              {errors.warning}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Warning 1</Form.Label>
          <Form.Control
            type="text"
            name="warning1"
            value={values.warning1}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.warning1 && errors.warning1}
          />
          {!!touched.warning1 && (
            <Form.Control.Feedback type="invalid">
              {errors.warning1}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Warning 2</Form.Label>
          <Form.Control
            type="text"
            name="warning2"
            value={values.warning2}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.warning2 && errors.warning2}
          />
          {!!touched.warning2 && (
            <Form.Control.Feedback type="invalid">
              {errors.warning2}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Row>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            setValues(initialValues);
            clearSelectedSims();
            handleClose();
          }}
          style={{
            "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
            "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
            border: "none",
          }}
        >
          Cancel
        </Button>

        <Button
          variant="primary"
          type="submit"
          style={{
            "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
            "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
            border: "none",
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default AddWatchdogForm;
