import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Spinner, Table, Form } from "react-bootstrap";
import { suspendSims } from "../../../../redux/slices/sims";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import NotyfContext from "../../../../contexts/NotyfContext";
import * as Yup from "yup";
import { Formik } from "formik";
import Loader from "../../../../components/spinners/Loader";

const SuspendSimModal = ({
  sims,
  openSuspendModal,
  setOpenSuspendModal,
  clearSelectedRows,
}) => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const dispatch = useDispatch();
  const handleClose = () => setOpenSuspendModal(false);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [selectedSims, setSelectedSims] = useState([]);
  const notyf = useContext(NotyfContext);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  const initialValues = {
    selectedSims: sims,
  };

  const validation = Yup.object().shape({
    selectedSims: Yup.array().required("Please select at least one SIM"),
  });

  return (
    <Modal show={openSuspendModal} onHide={handleClose} backdrop="static">
      <Modal.Header>
        <Modal.Title>Suspend SIMs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={async (values, { setStatus, setValues }) => {
            const data = {
              sims: values.selectedSims.map((sim) => sim.imsi),
            };
            await dispatch(suspendSims(data.sims))
              .then(() => {
                notyf.open({
                  type: "success",
                  message: "SIMs suspended successfully",
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
                setStatus("Success");
              })
              .catch((error) => {
                notyf.open({
                  type: "danger",
                  message: error,
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
                setStatus("Error");
              })
              .finally(() => {
                setCurrentStep(3);
                setValues(initialValues);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            status,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setValues,
            validateForm,
            setFieldTouched,
          }) => {
            const nextStep = () => {
              validateForm().then((errors) => {
                if (Object.keys(errors).length === 0) {
                  if (currentStep === 1) {
                    setCurrentStep(2);
                    handleSubmit();
                  }
                } else {
                  Object.keys(values).forEach((field) => {
                    setFieldTouched(field);
                  });
                }
              });
            };
            return (
              <Form onSubmit={handleSubmit}>
                {currentStep === 1 && (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>IMSI</th>
                        <th>ICCID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.selectedSims.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.imsi}</td>
                            <td>{item.iccid}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                {currentStep === 2 && <Loader />}
                {currentStep === 3 && status === "Success" && (
                  <div className="text-center">
                    <FaCheckCircle
                      size={120}
                      className="mt-3"
                      color="#4BBF73"
                    />
                    <h3 className="mt-3">Order Complete</h3>
                  </div>
                )}
                {currentStep === 3 && status === "Error" && (
                  <div className="text-center">
                    <FaExclamationCircle
                      size={120}
                      className="mt-3"
                      color="#FF9494"
                    />
                    <h3 className="mt-3">Order Error</h3>
                    <p>Please contact the administrator.</p>
                  </div>
                )}
                <Modal.Footer>
                  {currentStep === 1 && (
                    <>
                      <Button
                        onClick={() => {
                          setValues(initialValues);
                          setOpenSuspendModal(false);
                        }}
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        onClick={nextStep}
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                        }}
                      >
                        Suspend
                      </Button>
                    </>
                  )}
                  {currentStep === 3 && (
                    <Button
                      onClick={() => {
                        setOpenSuspendModal(false);
                        setValues(initialValues);
                        setCurrentStep(1);
                        clearSelectedRows();
                      }}
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                        border: "none",
                      }}
                    >
                      Close
                    </Button>
                  )}
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default SuspendSimModal;
