const getAutomatedReportColumns = (t) => {
  const automatedReportColumns = [
    {
      Header: t("reports.automated.name"),
      accessor: "name",
      sortable: true,
    },
    {
      Header: t("reports.automated.type"),
      accessor: "reportType",
      sortable: true,
    },
    {
      Header: t("reports.automated.frequency"),
      accessor: "reportFrequency",
      sortable: true,
      Cell: ({ row }) => {
        if (row.original.reportFrequency === "daily") {
          return t("reports.automated.daily");
        } else if (row.original.reportFrequency === "weekly") {
          return t("reports.automated.weekly");
        } else if (row.original.reportFrequency === "monthly") {
          return t("reports.automated.monthly");
        }
      },
    },
    {
      Header: t("reports.automated.nextReport"),
      accessor: "nextReportDate",
      sortable: false,
    },
  ];

  return { automatedReportColumns };
};

export default getAutomatedReportColumns;
