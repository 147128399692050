import React, { useState, useContext, useEffect } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../../contexts/NotyfContext";
import axios from "../../../utils/axios";
import TwoFactorConfigModal from "./modals/TwoFactorConfigModal";
import { updateCurrentUserIs2FAEnabled } from "../../../redux/slices/user";
import * as Yup from "yup";
import { Formik } from "formik";
import { disableMfa, enableMfa } from "../../../redux/slices/mfa";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";

function Privacy({ activeTab }) {
  const { t } = useTranslation();
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [setIsClicked] = useState(false);
  const [showConfigure2FAAuthentication, setShowConfigure2FAAuthentication] =
    useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const notyf = useContext(NotyfContext);
  const [isSwitchEnabled, setIsSwitchEnabled] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (activeTab === "#Privacy") {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [activeTab]);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  const initialValues = {
    is2FAEnabled: activeUser.is2FAEnabled,
  };

  const validation = Yup.object().shape({
    is2FAEnabled: Yup.boolean().required(),
  });

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">
          {t("settings.privacySettings.title")}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && <Loader />}
        {!isLoading && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values) => {
              if (values.is2FAEnabled) {
                await enableMfa()
                  .then((response) => {
                    setQrCodeUrl(response);
                    setShowConfigure2FAAuthentication(true);
                  })
                  .catch((error) => {
                    notyf.open({
                      type: "danger",
                      message: error,
                      duration: 5000,
                      dismissible: false,
                      ripple: true,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  });
              } else {
                await dispatch(disableMfa())
                  .then(() => {
                    notyf.open({
                      type: "success",
                      message: "Successfully disabled 2FA",
                      duration: 5000,
                      dismissible: false,
                      ripple: true,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  })
                  .catch((error) => {
                    notyf.open({
                      type: "danger",
                      message: error,
                      duration: 5000,
                      dismissible: false,
                      ripple: true,
                      position: {
                        x: "center",
                        y: "top",
                      },
                    });
                  });
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      label={t("settings.privacySettings.enable2FA")}
                      name="is2FAEnabled"
                      checked={values.is2FAEnabled}
                      onChange={(e) =>
                        setFieldValue("is2FAEnabled", e.target.checked)
                      }
                      onBlur={handleBlur}
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color": whitelabel?.fontColor ?? "#fff",
                        border: "none",
                      }}
                    />
                  </Form.Group>
                  <Row>
                    <Col md="auto ms-auto">
                      <Button
                        type="submit"
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#fff",
                          border: "none",
                        }}
                      >
                        {t("settings.privacySettings.save")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        )}
        <TwoFactorConfigModal
          showConfigure2FAAuthentication={showConfigure2FAAuthentication}
          setShowConfigure2FAAuthentication={setShowConfigure2FAAuthentication}
          qrCodeUrl={qrCodeUrl}
          user={impersonationUser.id ? impersonationUser : user}
        />
      </Card.Body>
    </Card>
  );
}

export default Privacy;
