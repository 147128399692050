import React from "react";
import { Card } from "react-bootstrap";

const OffersLane = ({ id, name, children, onContainerLoaded }) => {
  const handleContainerLoaded = (container) => {
    if (container) {
      onContainerLoaded(container);
    }
  };

  return (
    <Card className="flex-fill w-100 d-flex">
      <Card.Header>
        <Card.Title>{name}</Card.Title>
      </Card.Header>
      <Card.Body>
        <div ref={handleContainerLoaded} id={id} className="h-100">
          {children}
        </div>
      </Card.Body>
    </Card>
  );
};

const OffersCard = ({ id, text }) => (
  <Card className="mb-3 bg-light cursor-grab border" data-id={id}>
    <Card.Body className="p-3">
      <p>{text}</p>
    </Card.Body>
  </Card>
);

export { OffersLane, OffersCard };
