import React, { useMemo } from "react";
import { Card } from "react-bootstrap";
import StatementsTable from "./StatementsTable";
import getStatementsData from "../../inputData/statementsData";
import { useTranslation } from "react-i18next";

const StatementsList = (props) => {
  const { t } = useTranslation();
  const { statementsColumns } = useMemo(() => getStatementsData(t), [t]);
  return (
    <Card>
      <Card.Body>
        <StatementsTable data={props.data} columns={statementsColumns} />
      </Card.Body>
    </Card>
  );
};

export default StatementsList;
