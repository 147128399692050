import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  usageReport: [],
  reportTemplates: [],
  reportHistory: [],
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setUsageReport(state, action) {
      state.usageReport = action.payload;
    },
    setReportTemplates(state, action) {
      state.reportTemplates = action.payload;
    },
    addReportTemplate(state, action) {
      const { newReportTemplate } = action.payload;
      return {
        ...state,
        reportTemplates: [...state.reportTemplates, newReportTemplate],
      };
    },
    updateReportTemplate(state, action) {
      const { id, updatedReportTemplate } = action.payload;
      return {
        ...state,
        reportTemplates: state.reportTemplates.map((reportTemplate) =>
          reportTemplate.id === id ? updatedReportTemplate : reportTemplate
        ),
      };
    },
    removeReportTemplate(state, action) {
      const { id } = action.payload;
      return {
        ...state,
        reportTemplates: state.reportTemplates.filter(
          (reportTemplate) => reportTemplate.id !== id
        ),
      };
    },
    setReportHistory(state, action) {
      state.reportHistory = action.payload;
    },
    deleteReportHistory(state, action) {
      const { id } = action.payload;
      return {
        ...state,
        reportHistory: state.reportHistory.filter((report) => report.id !== id),
      };
    },
  },
});

export const getUserReports = () => {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/reports`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(reportsSlice.actions.setReportHistory(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
};

export function deleteReportFromHistory({ id }) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/reports/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(reportsSlice.actions.deleteReportHistory({ id }));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export const getUsageReport = ({
  usageType,
  groupByField,
  groupByPeriod,
  usageStartDate,
  usageEndDate,
  userId,
  simSearchCriteria,
  imsi,
  startImsi,
  endImsi,
  iccid,
}) => {
  return async (dispatch) => {
    const impersonationToken = localStorage.getItem("impersonationToken");
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      return Promise.reject("No access token");
    }
    try {
      const padZero = (num) => num.toString().padStart(2, "0");
      // Format the date to yyyy-MM-ddTHH:mm:ss
      const formattedUsageStartDate = `${usageStartDate.getFullYear()}-${padZero(
        usageStartDate.getMonth() + 1
      )}-${padZero(usageStartDate.getDate())}T${padZero(
        usageStartDate.getHours()
      )}:${padZero(usageStartDate.getMinutes())}:${padZero(
        usageStartDate.getSeconds()
      )}`;

      // Format the end date to yyyy-MM-ddTHH:mm:ss
      const formattedUsageEndDate = `${usageEndDate.getFullYear()}-${padZero(
        usageEndDate.getMonth() + 1
      )}-${padZero(usageEndDate.getDate())}T${padZero(
        usageEndDate.getHours()
      )}:${padZero(usageEndDate.getMinutes())}:${padZero(
        usageEndDate.getSeconds()
      )}`;

      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/reports/usage?usageType=${usageType}&groupByField=${groupByField}&groupByPeriod=${groupByPeriod}&usageStartDate=${formattedUsageStartDate}&usageEndDate=${formattedUsageEndDate}&simSearchCriteria=${simSearchCriteria}&imsi=${imsi}&startImsi=${startImsi}&endImsi=${endImsi}&iccid=${iccid}&userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Impersonation: impersonationToken,
          },
        }
      );
      dispatch(reportsSlice.actions.setUsageReport(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
};

export async function exportReport({ headers, data, format }) {
  try {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    const dataToSend = {
      headers,
      report: data,
      format,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/reports/export`,
      dataToSend,
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${
            impersonationToken ? impersonationToken : accessToken
          }`,
        },
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error.message);
  }
}

export function getReportTemplates() {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/report-templates`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(reportsSlice.actions.setReportTemplates(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function createReportTemplate({ data }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/report-templates`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        reportsSlice.actions.addReportTemplate({
          newReportTemplate: response.data.data,
        })
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function editReportTemplate({ id, data }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/report-templates/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        reportsSlice.actions.updateReportTemplate({
          id,
          updatedReportTemplate: response.data.data,
        })
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function deleteReportTemplate({ id }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/report-templates/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(reportsSlice.actions.removeReportTemplate({ id }));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export const { setReports } = reportsSlice.actions;
export default reportsSlice.reducer;
