import React from "react";
import { useTranslation } from "react-i18next";

import { Button, ButtonGroup, Col, Row } from "react-bootstrap";

import { RefreshCw } from "react-feather";
import { useSelector } from "react-redux";

const Header = ({ type, setType, setReload }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);

  return (
    <>
      <Row className="mb-2 mb-xl-3">
        <Col xs="auto" className="ms-auto text-end mt-n1">
          <ButtonGroup className="shadow-sm me-2">
            <Button
              key={1}
              onClick={() => setType("day")}
              className={"day" === type ? "active" : null}
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
            >
              {t("dashboard.daily")}
            </Button>
            <Button
              key={2}
              onClick={() => setType("month")}
              className={"month" === type ? "active" : null}
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
            >
              {t("dashboard.monthly")}
            </Button>
            <Button
              key={3}
              onClick={() => setType("year")}
              className={"year" === type ? "active" : null}
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
            >
              {t("dashboard.yearly")}
            </Button>
          </ButtonGroup>
          <Button
            className="shadow-sm border border-0"
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              border: "none",
            }}
            onClick={() => setReload((prev) => !prev)}
          >
            <RefreshCw className="feather" />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Header;
