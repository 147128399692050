import React, { useState, useContext, useMemo } from "react";
import {
  Card,
  Table,
  Pagination,
  Row,
  Col,
  Form,
  DropdownButton,
  ButtonGroup,
  Dropdown,
} from "react-bootstrap";
import { DropdownSubmenu } from "react-bootstrap-submenu";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useTable, usePagination } from "react-table";
import { exportReport } from "../../../../redux/slices/reports";
import FileSaver from "file-saver";
import NotyfContext from "../../../../contexts/NotyfContext";

const UsageTable = ({ usageType, groupByField }) => {
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();
  const usageReport = useSelector((state) => state.reports.usageReport);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const notyf = useContext(NotyfContext);

  const columns = useMemo(() => {
    const baseCols = {
      customerID: { Header: t("reports.customerId"), accessor: "portfolioId" },
      date: { Header: t("reports.date"), accessor: "date" },
      imsi: { Header: t("reports.imsi"), accessor: "imsi" },
      iccid: { Header: t("reports.iccid"), accessor: "iccid" },
      simType: { Header: t("reports.simType"), accessor: "simType" },
      networkCode: {
        Header: t("reports.networkCode"),
        accessor: "networkCode",
      },
      network: { Header: t("reports.networkName"), accessor: "network" },
      country: { Header: t("reports.country"), accessor: "country" },
      service: { Header: "Service Code", accessor: "service" },
      totalBytes: { Header: t("reports.totalBytes"), accessor: "totalBytes" },
      roundedBytes: {
        Header: t("reports.roundedBytes"),
        accessor: "roundedBytes",
      },
      sessions: { Header: t("reports.sessions"), accessor: "sessions" },
      zeroSessions: {
        Header: t("reports.zeroSessions"),
        accessor: "zeroSessions",
      },
      source: { Header: "Source", accessor: "source" },
      destination: { Header: "Destination", accessor: "destination" },
      events: { Header: "Events", accessor: "events" },
    };

    if (usageType === "data") {
      switch (groupByField) {
        case "all":
          return [
            baseCols.customerID,
            baseCols.date,
            baseCols.totalBytes,
            baseCols.roundedBytes,
            baseCols.sessions,
            baseCols.zeroSessions,
          ];
        case "imsi":
          return [
            baseCols.customerID,
            baseCols.imsi,
            baseCols.iccid,
            baseCols.simType,
            baseCols.date,
            baseCols.totalBytes,
            baseCols.roundedBytes,
            baseCols.sessions,
            baseCols.zeroSessions,
          ];
        case "network":
          return [
            baseCols.customerID,
            baseCols.networkCode,
            baseCols.network,
            baseCols.country,
            baseCols.date,
            baseCols.totalBytes,
            baseCols.roundedBytes,
            baseCols.sessions,
            baseCols.zeroSessions,
          ];
        default:
          return [];
      }
    } else if (usageType === "sms") {
      switch (groupByField) {
        case "all":
          return [
            baseCols.customerID,
            baseCols.imsi,
            baseCols.iccid,
            baseCols.simType,
            baseCols.date,
            baseCols.source,
            baseCols.destination,
            baseCols.events,
          ];
        case "imsi":
          return [
            baseCols.customerID,
            baseCols.imsi,
            baseCols.iccid,
            baseCols.simType,
            baseCols.date,
            baseCols.events,
          ];
        default:
          return [];
      }
    }
  }, [usageType, groupByField]);

  const exportToCSV = async () => {
    const headers = columns.map((col) => col.Header);
    const data = usageReport.map((row) =>
      columns.map((col) => row[col.accessor])
    );
    const format = "csv";
    await exportReport({ headers, data, format })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "text/csv",
        });
        FileSaver.saveAs(blob, "report.csv");
      })
      .catch((error) => {
        notyf.open({
          type: "danger",
          message: error,
          ripple: true,
          dismissible: false,
          duration: 5000,
          position: {
            x: "center",
            y: "top",
          },
        });
      });
  };

  const exportToPDF = async () => {
    const headers = columns.map((col) => col.Header);
    const data = usageReport.map((row) =>
      columns.map((col) => row[col.accessor])
    );
    const format = "pdf";
    await exportReport({ headers, data, format })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        FileSaver.saveAs(blob, "report.pdf");
      })
      .catch((error) => {
        notyf.open({
          type: "danger",
          message: error,
          ripple: true,
          dismissible: false,
          duration: 5000,
          position: {
            x: "center",
            y: "top",
          },
        });
      });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: usageReport,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );

  return (
    <React.Fragment>
      <Row>
        <Col className="ms-auto" md="auto">
          <DropdownButton
            as={ButtonGroup}
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              border: "none",
            }}
            title={t("reports.actions")}
          >
            <DropdownSubmenu title={t("reports.export")}>
              <Dropdown.Item eventKey="CSV" onClick={() => exportToCSV()}>
                CSV
              </Dropdown.Item>
              <Dropdown.Item eventKey="PDF" onClick={() => exportToPDF()}>
                PDF
              </Dropdown.Item>
            </DropdownSubmenu>
          </DropdownButton>
        </Col>
      </Row>
      <Table {...getTableProps()} striped hover responsive>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {usageReport.length > 0 && (
        <Row className="mt-4">
          <Col md="9">
            <span className="mx-2">
              {t("table.page")}{" "}
              <strong>
                {pageIndex + 1} {t("table.of")} {pageOptions.length}
              </strong>
            </span>
            <span className="ms-3 me-2">{t("table.show")}:</span>
            <Form.Select
              className="d-inline-block w-auto"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 30, 40, 50].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Form.Select>

            <span className="ms-3 me-2">{t("table.goToPage")}:</span>
            <Form.Control
              className="d-inline-block"
              type="number"
              defaultValue={pageIndex + 1}
              min={1}
              max={pageCount}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "75px" }}
            />
          </Col>
          <Col md="3">
            <Pagination className="float-end">
              <Pagination.First
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              />
              <Pagination.Prev
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              />
              <Pagination.Next
                onClick={() => nextPage()}
                disabled={!canNextPage}
              />
              <Pagination.Last
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              />
            </Pagination>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default UsageTable;
