const getStatementsData = (t) => {
  const statementsColumns = [
    {
      Header: t("statements.cutDate"),
      accessor: "date",
      sortable: true,
    },
    {
      Header: t("statements.description"),
      accessor: "description",
      sortable: true,
    },
    {
      Header: t("statements.amount"),
      accessor: "amount",
      Cell: ({ row: { original } }) => <>{original.amount}€</>,
    },
    {
      Header: t("statements.balance"),
      accessor: "balance",
      Cell: ({ row: { original } }) => <>{original.balance}€</>,
      sortable: true,
    },
  ];
  return { statementsColumns };
};

export default getStatementsData;
