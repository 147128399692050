import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Table, Form } from "react-bootstrap";
import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import NotyfContext from "../../../../contexts/NotyfContext";
import { removeAllSimWatchdogs } from "../../../../redux/slices/watchdogs";

const RemoveAllWatchdogsModal = ({
  openRemoveWatchdogModal,
  setOpenRemoveWatchdogModal,
  selectedSims,
  clearSelectedSims,
}) => {
  const handleClose = () => setOpenRemoveWatchdogModal(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [impersonationUser, user]);

  const initialValues = {
    imsiList: selectedSims.map((sim) => sim.imsi),
  };

  const validation = Yup.object().shape({
    imsiList: Yup.array().required("Required"),
  });

  return (
    <Modal
      show={openRemoveWatchdogModal}
      onHide={handleClose}
      backdrop="static"
      centered
    >
      <Modal.Header>Remove Watchdog</Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={async (values, { setValues }) => {
            await dispatch(removeAllSimWatchdogs({ imsiList: values.imsiList }))
              .then(() => {
                notyf.open({
                  type: "success",
                  message: "Watchdog removed successfully",
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .catch((error) => {
                notyf.open({
                  type: "danger",
                  message: error,
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .finally(() => {
                setValues(initialValues);
                clearSelectedSims();
                handleClose();
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Table>
                  <thead>
                    <tr>
                      <th>IMSI</th>
                      <th>ICCID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedSims.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.imsi}</td>
                          <td>{item.iccid}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() => handleClose()}
                    style={{
                      "--dynamic-bg-color":
                        whitelabel?.backgroundColor ?? "#00acdc",
                      "--dynamic-font-color":
                        whitelabel?.fontColor ?? "#ffffff",
                      border: "none",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    style={{
                      "--dynamic-bg-color":
                        whitelabel?.backgroundColor ?? "#00acdc",
                      "--dynamic-font-color":
                        whitelabel?.fontColor ?? "#ffffff",
                      border: "none",
                    }}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default RemoveAllWatchdogsModal;
