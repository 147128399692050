import React, { useState, useEffect, useContext } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Collapse, Form, Offcanvas, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import NewAutomatedReportForm from "./NewAutomatedReportForm";
import { clearAllTenants, getTenants } from "../../../../redux/slices/tenants";
import {
  clearAllManagers,
  getManagers,
} from "../../../../redux/slices/managers";
import {
  clearAllCustomers,
  getCustomers,
} from "../../../../redux/slices/customers";
import { createReportTemplate } from "../../../../redux/slices/reports";
import NotyfContext from "../../../../contexts/NotyfContext";

const NewAutomatedReport = ({
  showNewAutomatedReport,
  setShowNewAutomatedReport,
  activeUser,
}) => {
  const dispatch = useDispatch();
  const [usageType, setUsageType] = useState("");
  const [groupByField, setGroupByField] = useState("");
  const [groupByPeriod, setGroupByPeriod] = useState("");
  const [nextCollapse, setNextCollapse] = useState(null);
  const [reportTypeCollapse, setReportTypeCollapse] = useState(null);
  const [prevReportType, setPrevReportType] = useState(null);
  const { t } = useTranslation();
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const tenants = useSelector((state) => state.tenants.tenants);
  const managers = useSelector((state) => state.managers.managers);
  const customers = useSelector((state) => state.customers.customers);
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    const clearLists = () => {
      dispatch(clearAllTenants());
      dispatch(clearAllManagers());
      dispatch(clearAllCustomers());
    };

    const fetchCustomersList = async () => {
      await dispatch(getCustomers());
    };
    const fetchTenantsList = async () => {
      await dispatch(getTenants());
    };
    const fetchManagersList = async () => {
      await dispatch(getManagers());
    };

    if (activeUser.id && showNewAutomatedReport) {
      clearLists();
      if (activeUser.role === "admin") {
        fetchTenantsList();
      }
      if (activeUser.role === "admin" || activeUser.role === "tenant") {
        fetchManagersList();
      }
      if (
        activeUser.role === "admin" ||
        activeUser.role === "tenant" ||
        activeUser.role === "manager"
      ) {
        fetchCustomersList();
      }
      if (activeUser.role === "customer") {
        dispatch(clearAllTenants());
        dispatch(clearAllManagers());
        dispatch(clearAllCustomers());
      }
    }
  }, [activeUser]);

  const initialValues = {
    name: "",
    reportTemplateType: "",
    reportFrequency: "",
    reportFormat: "",
    usageType: "",
    groupByField: "all",
    groupByPeriod: "day",
    userId: "",
    simSearchCriteriaType: "all",
    imsi: "",
    startImsi: "",
    endImsi: "",
    iccid: "",
  };

  const validation = Yup.object().shape({
    name: Yup.string().required("Required"),
    reportTemplateType: Yup.string().required("Required"),
    reportFrequency: Yup.string().required("Required"),
    reportFormat: Yup.string().required("Required"),
    usageType: Yup.string().when("reportTemplateType", {
      is: "Usage",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
    groupByField: Yup.string().when("reportTemplateType", {
      is: "Usage",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
    groupByPeriod: Yup.string().when("reportTemplateType", {
      is: "Usage",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
    userId: Yup.string().when("reportTemplateType", {
      is: "Usage",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
    simSearchCriteriaType: Yup.string().when("reportTemplateType", {
      is: "Usage",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
    imsi: Yup.string().when("reportTemplateType", {
      is: "Usage",
      then: Yup.string().when("simSearchCriteriaType", {
        is: "imsi",
        then: Yup.string().required("Required"),
        otherwise: Yup.string().notRequired(),
      }),
      otherwise: Yup.string().notRequired(),
    }),
    iccid: Yup.string().when("reportTemplateType", {
      is: "Usage",
      then: Yup.string().when("simSearchCriteriaType", {
        is: "iccid",
        then: Yup.string().required("Required"),
        otherwise: Yup.string().notRequired(),
      }),
      otherwise: Yup.string().notRequired(),
    }),
    startImsi: Yup.string().when("reportTemplateType", {
      is: "Usage",
      then: Yup.string().when("simSearchCriteriaType", {
        is: "imsiRange",
        then: Yup.string().required("Required"),
        otherwise: Yup.string().notRequired(),
      }),
      otherwise: Yup.string().notRequired(),
    }),
    endImsi: Yup.string().when("reportTemplateType", {
      is: "Usage",
      then: Yup.string().when("simSearchCriteriaType", {
        is: "imsiRange",
        then: Yup.string().required("Required"),
        otherwise: Yup.string().notRequired(),
      }),
      otherwise: Yup.string().notRequired(),
    }),
  });
  return (
    <Offcanvas
      show={showNewAutomatedReport}
      onHide={setShowNewAutomatedReport}
      placement="end"
      backdrop={false}
      size="lg"
      scroll={true}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>New Automated Report</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={async (values, { setValues }) => {
            await dispatch(createReportTemplate({ data: values }))
              .then(() => {
                notyf.open({
                  type: "success",
                  message: "Report template created successfully",
                  duration: 5000,
                  ripple: true,
                  dismissible: false,
                  position: { x: "center", y: "top" },
                });
              })
              .catch((error) => {
                notyf.open({
                  type: "danger",
                  message: error,
                  duration: 5000,
                  ripple: true,
                  dismissible: false,
                  position: { x: "center", y: "top" },
                });
              })
              .finally(() => {
                setValues(initialValues);
                setShowNewAutomatedReport(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <NewAutomatedReportForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                nextCollapse={nextCollapse}
                setNextCollapse={setNextCollapse}
                reportTypeCollapse={reportTypeCollapse}
                setReportTypeCollapse={setReportTypeCollapse}
                prevReportType={prevReportType}
                setPrevReportType={setPrevReportType}
                t={t}
                whitelabel={whitelabel}
                usageType={usageType}
                setUsageType={setUsageType}
                setGroupByPeriod={setGroupByPeriod}
                tenantsList={tenants}
                managersList={managers}
                customersList={customers}
                activeUser={activeUser}
                setGroupByField={setGroupByField}
              />
            );
          }}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default NewAutomatedReport;
