import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  generalPreferences: [],
  notificationPreferences: [],
  billingPreferences: [],
  invoicePreferences: [],
};

const preferencesSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    setGeneralPreferences(state, action) {
      state.generalPreferences = action.payload;
    },
    setNotificationPreferences(state, action) {
      state.notificationPreferences = action.payload;
    },
    setBillingPreferences(state, action) {
      state.billingPreferences = action.payload;
    },
    setInvoicePreferences(state, action) {
      state.invoicePreferences = action.payload;
    },
  },
});

export function getGeneralPreferences() {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/general-preferences`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        preferencesSlice.actions.setGeneralPreferences(response.data.data)
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function updateGeneralPreferences({ data }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/general-preferences`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        preferencesSlice.actions.setGeneralPreferences(response.data.data)
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getNotificationPreferences() {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/notification-preferences`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        preferencesSlice.actions.setNotificationPreferences(response.data.data)
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function updateNotificationPreferences({ data }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/notification-preferences`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        preferencesSlice.actions.setNotificationPreferences(response.data.data)
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getBillingPreferences() {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/billing-preferences`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        preferencesSlice.actions.setBillingPreferences(response.data.data)
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getBillingPreferencesForClient({ clientId }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/billing-preferences/${clientId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(
        preferencesSlice.actions.setBillingPreferences(response.data.data)
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function updateBillingPreferences({ data }) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/billing-preferences`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        preferencesSlice.actions.setBillingPreferences(response.data.data)
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function updateUserBillingPreferences({ id, data }) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/billing-preferences/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        preferencesSlice.actions.setBillingPreferences(response.data.data)
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getInvoicePreferences() {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/invoice-configuration`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        preferencesSlice.actions.setInvoicePreferences(response.data.data)
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function updateInvoicePreferences({ data }) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/invoice-configuration`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        preferencesSlice.actions.setInvoicePreferences(response.data.data)
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export const { setGeneralPreferences } = preferencesSlice.actions;

export default preferencesSlice.reducer;
