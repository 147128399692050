import React, { useEffect, useState, useContext } from "react";
import { Card } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import ReportsFilterForm from "./ReportsFilterForm";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllCustomers,
  getCustomers,
} from "../../../redux/slices/customers";
import { clearAllTenants, getTenants } from "../../../redux/slices/tenants";
import { clearAllManagers, getManagers } from "../../../redux/slices/managers";
import { getUsageReport } from "../../../redux/slices/reports";
import NotyfContext from "../../../contexts/NotyfContext";

const ReportsFilter = (props) => {
  const { customers } = useSelector((state) => state.customers);
  const { tenants } = useSelector((state) => state.tenants);
  const { managers } = useSelector((state) => state.managers);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const { reports } = useSelector((state) => state.reports);
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    props.setReportData(reports);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports]);

  useEffect(() => {
    const clearLists = () => {
      dispatch(clearAllTenants());
      dispatch(clearAllManagers());
      dispatch(clearAllCustomers());
    };

    const fetchCustomersList = async () => {
      await dispatch(getCustomers());
    };
    const fetchTenantsList = async () => {
      await dispatch(getTenants());
    };
    const fetchManagersList = async () => {
      await dispatch(getManagers());
    };

    if (activeUser.id) {
      clearLists();
      if (activeUser.role === "admin") {
        fetchTenantsList();
      }
      if (activeUser.role === "admin" || activeUser.role === "tenant") {
        fetchManagersList();
      }
      if (
        activeUser.role === "admin" ||
        activeUser.role === "tenant" ||
        activeUser.role === "manager"
      ) {
        fetchCustomersList();
      }
      if (activeUser.role === "customer") {
        dispatch(clearAllTenants());
        dispatch(clearAllManagers());
        dispatch(clearAllCustomers());
      }
    }
  }, [activeUser]);

  const initialValues = {
    reportType: "usage",
    usageType: "",
    groupByField: "all",
    groupByPeriod: "day",
    usageStartDate: "",
    usageEndDate: "",
    customer: "",
    simSearchCriteriaType: "all",
    imsi: "",
    startImsi: "",
    endImsi: "",
    iccid: "",
    invoiceNumber: "",
    orderStartDate: "",
    orderEndDate: "",
  };

  return (
    <Card
      style={{ height: "750px", overflowY: "auto", width: "fit-content" }}
      id="reportsFilterCard"
      className="flex-fill w-100"
    >
      <Card.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            usageType: Yup.string().when("reportType", {
              is: "usage",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            groupByField: Yup.string().when("reportType", {
              is: "usage",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            groupByPeriod: Yup.string().when("reportType", {
              is: "usage",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            usageStartDate: Yup.string().when("reportType", {
              is: "usage",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            usageEndDate: Yup.string().when("reportType", {
              is: "usage",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            customer: Yup.string().when("reportType", {
              is: "usage",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            imsi: Yup.string().when("simSearchCriteriaType", {
              is: "imsi",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            iccid: Yup.string().when("simSearchCriteriaType", {
              is: "iccid",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            startImsi: Yup.string().when("simSearchCriteriaType", {
              is: "imsiRange",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            endImsi: Yup.string().when("simSearchCriteriaType", {
              is: "imsiRange",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            orderStartDate: Yup.string().when("reportType", {
              is: "orders",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
            orderEndDate: Yup.string().when("reportType", {
              is: "orders",
              then: Yup.string().required("Required"),
              otherwise: Yup.string().notRequired(),
            }),
          })}
          onSubmit={async (values, { setValues }) => {
            if (values.reportType === "usage") {
              props.setTableIsShown(false);
              await dispatch(
                getUsageReport({
                  usageType: values.usageType,
                  groupByField: values.groupByField,
                  groupByPeriod: values.groupByPeriod,
                  usageStartDate: values.usageStartDate,
                  usageEndDate: values.usageEndDate,
                  userId: values.customer,
                  simSearchCriteria: values.simSearchCriteriaType,
                  imsi: values.imsi,
                  startImsi: values.startImsi,
                  endImsi: values.endImsi,
                  iccid: values.iccid,
                })
              )
                .then(() => {
                  props.setTableIsShown(true);
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                    duration: 5000,
                  });
                  setValues(initialValues);
                });

              props.setReport(values.reportType);
              props.setGroupByField(values.groupByField);
              props.setTableIsShown(true);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            setValues,
            touched,
            values,
          }) => {
            return (
              <ReportsFilterForm
                user={activeUser}
                customersList={customers}
                tenantsList={tenants}
                managersList={managers}
                setGroupByPeriod={props.setGroupByPeriod}
                setUsageType={props.setUsageType}
              />
            );
          }}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default ReportsFilter;
