import { Formik } from "formik";
import React, { useState, useEffect, useContext } from "react";
import { Button, Collapse, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { modifyCustomField } from "../../../../redux/slices/customFields";
import { getSims } from "../../../../redux/slices/sims";
import NotyfContext from "../../../../contexts/NotyfContext";

const ModifyCustomFieldForm = ({
  errors,
  handleBlur,
  setFieldTouched,
  handleChange,
  handleSubmit,
  isSubmitting,
  touched,
  values,
  setValues,
  validateForm,
  setFieldValue,
  initialValues,
  customFields,
  setOpenModifyCustomField,
}) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Custom Field</Form.Label>
        <Form.Select
          name="customFieldId"
          value={values.customFieldId}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.customFieldId && errors.customFieldId)}
        >
          <option key="" value="">
            Select Custom Field
          </option>
          {customFields.map((field) => (
            <option key={field.id} value={field.id}>
              {field.id} - {field.displayName}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors.customFieldId}
        </Form.Control.Feedback>
      </Form.Group>
      <Collapse
        in={values.customFieldId !== null && values.customFieldId !== ""}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>Value</Form.Label>
          <Form.Control
            type="text"
            name="value"
            value={values.value}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.value && errors.value)}
          />
        </Form.Group>
      </Collapse>

      <Modal.Footer>
        <Button
          style={{
            "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
            "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
            border: "none",
          }}
          type="submit"
        >
          Submit
        </Button>
        <Button
          style={{
            "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
            "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
            border: "none",
          }}
          onClick={() => {
            setValues(initialValues);
            setOpenModifyCustomField(false);
          }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const ModifyCustomFieldModal = ({
  openModifyCustomField,
  setOpenModifyCustomField,
  selectedRows,
  page,
  pageSize,
  filters,
}) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const { customFields } = useSelector((state) => state.customFields);
  const [selectedSims, setSelectedSims] = useState([]);
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    const imsiList = selectedRows.map((sim) => sim.imsi);
    setSelectedSims(imsiList);
  }, [selectedRows]);

  return (
    <Modal
      show={openModifyCustomField}
      onHide={() => setOpenModifyCustomField(false)}
      backdrop="static"
      centered
    >
      <Modal.Header>Modify Custom Field</Modal.Header>
      <Modal.Body>
        <Formik
          formikProps
          initialValues={{
            customFieldId: "",
            value: "",
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            customFieldId: Yup.number().required("Required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            await modifyCustomField(
              impersonationUser.id ? impersonationUser.id : user.id,
              values.customFieldId,
              String(values.value),
              selectedSims
            )
              .then(async (response) => {
                await dispatch(
                  getSims(
                    impersonationUser.id ? impersonationUser.id : user.id,
                    page + 1,
                    pageSize,
                    filters
                  )
                );
                notyf.open({
                  type: "success",
                  message: "Successfully Removed Custom Field",
                  duration: 5000,
                  ripple: true,
                  dismissible: false,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
                setOpenModifyCustomField(false);
              })
              .catch((error) => {
                notyf.open({
                  type: "danger",
                  message: "Error Modifying Custom Field",
                  duration: 5000,
                  ripple: true,
                  dismissible: false,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              });
          }}
        >
          {({
            errors,
            handleBlur,
            setFieldTouched,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setValues,
            validateForm,
            setFieldValue,
            initialValues,
          }) => {
            return (
              <ModifyCustomFieldForm
                errors={errors}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                touched={touched}
                values={values}
                setValues={setValues}
                validateForm={validateForm}
                setFieldValue={setFieldValue}
                initialValues={initialValues}
                customFields={customFields}
                setOpenModifyCustomField={setOpenModifyCustomField}
              />
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ModifyCustomFieldModal;
