import { Badge } from "react-bootstrap";
import { FaFileDownload } from "react-icons/fa";

const getReportsHistoryData = (t) => {
  const reportsHistoryColumns = [
    {
      Header: t("reports.history.tableHeaders.reportName"),
      accessor: "name",
      sortable: true,
    },
    {
      Header: t("reports.history.tableHeaders.date"),
      accessor: "createdAt",
      sortable: true,
    },
    {
      Header: t("reports.history.tableHeaders.status"),
      accessor: "status",
      sortable: true,
      Cell: ({ row }) => {
        if (row.original.finishedAt) {
          return <Badge bg="success">{t("reports.history.completed")}</Badge>;
        } else {
          return <Badge bg="danger">{t("reports.history.incomplete")}</Badge>;
        }
      },
    },
    {
      Header: t("reports.history.tableHeaders.download"),
      accessor: "url",
      sortable: false,
      Cell: ({ row }) => {
        return (
          <a
            href={`${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${row.original.url}`}
            target="_blank"
          >
            <FaFileDownload size={18} />
          </a>
        );
      },
    },
  ];

  return { reportsHistoryColumns };
};

export default getReportsHistoryData;
