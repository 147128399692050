import countryList from "react-select-country-list";
import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tenants: [],
};

const tenantsSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    setTenants(state, action) {
      state.tenants = action.payload;
    },
    clearTenants(state, action) {
      state.tenants = [];
    },
    updateTenant(state, action) {
      const { id, tenantDetails } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return tenantDetails;
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    removeTenant(state, action) {
      const id = action.payload;
      const updatedTenants = state.tenants.filter((tenant) => tenant.id !== id);
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updateFullName(state, action) {
      const { id, fullName } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            fullName: fullName,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updateAddress(state, action) {
      const { id, address } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            address: address,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updateCity(state, action) {
      const { id, city } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            city: city,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updateCountry(state, action) {
      const { id, country } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            country: country,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updatePostcode(state, action) {
      const { id, postcode } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            postcode: postcode,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updateTelephone(state, action) {
      const { id, telephone } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            telephone: telephone,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updateMobile(state, action) {
      const { id, mobile } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            mobileNumber: mobile,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updateEmail(state, action) {
      const { id, email } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            email: email,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updateOccupation(state, action) {
      const { id, occupation } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            occupation: occupation,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updateCompany(state, action) {
      const { id, company } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            company: company,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updateTIN(state, action) {
      const { id, tin } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            tinNumber: tin,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updateOffers(state, action) {
      const { id, offers } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            offers: offers,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updateFavicon(state, action) {
      const { id, favicon } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            favicon: favicon,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updatePrivilege(state, action) {
      const { id, privilege } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            privilege: privilege,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updateWhitelabel(state, action) {
      const { id, whitelabelImage } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            whitelabelImage: whitelabelImage,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updateBackgroundColor(state, action) {
      const { id, color } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            backgroundColor: color,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updateFontColor(state, action) {
      const { id, fontColor } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            fontColor: fontColor,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    updateSidebarPosition(state, action) {
      const { id, position } = action.payload;
      const updatedTenants = state.tenants.map((tenant) => {
        if (tenant.id === id) {
          return {
            ...tenant,
            sidebarPosition: position,
          };
        }
        return tenant;
      });
      return {
        ...state,
        tenants: updatedTenants,
      };
    },
    addTenant: (state, action) => {
      const newTenant = action.payload;
      return {
        ...state,
        tenants: [...state.tenants, newTenant],
      };
    },
    removeTenant: (state, action) => {
      const idToRemove = action.payload;
      const newTenants = state.tenants.filter(
        (tenant) => tenant.id !== idToRemove
      );
      return {
        ...state,
        tenants: newTenants,
      };
    },
  },
});

export const clearAllTenants = () => (dispatch) => {
  dispatch(tenantsSlice.actions.clearTenants());
};

export function getTenants() {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/tenants`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(tenantsSlice.actions.setTenants(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      dispatch(tenantsSlice.actions.setTenants([]));
      return Promise.reject(error.message);
    }
  };
}

export async function getTenant(id) {
  const accessToken = window.localStorage.getItem("accessToken");
  if (!accessToken) return Promise.reject("No access token");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/tenants/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data.data);
  } catch (error) {
    return Promise.reject(error.message);
  }
}

export function updateTenantDetails({ id, values }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/tenants/${id}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        tenantsSlice.actions.updateTenant({
          id: id,
          tenantDetails: response.data.data,
        })
      );
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function deleteTenant(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      await axios.delete(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/tenants/${id}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(tenantsSlice.actions.removeTenant(id));
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function updateTenantName(id, fullName) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("fullName", fullName);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/fullName",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(tenantsSlice.actions.updateFullName({ id, fullName }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateTenantAddress(id, address) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("address", address);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/address",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(tenantsSlice.actions.updateAddress({ id, address }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateTenantCity(id, city) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("city", city);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/city",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(tenantsSlice.actions.updateCity({ id, city }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateTenantCountry(id, country) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("country", country);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/country",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(tenantsSlice.actions.updateCountry({ id, country }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateTenantPostcode(id, postcode) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("postcode", postcode);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/postcode",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(tenantsSlice.actions.updatePostcode({ id, postcode }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateTenantTelephone(id, telephone) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("telephone", telephone);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/telephone",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(tenantsSlice.actions.updateTelephone({ id, telephone }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateTenantMobile(id, mobile) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("mobile", mobile);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/mobile",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(tenantsSlice.actions.updateMobile({ id, mobile }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateTenantEmail(id, email) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("email", email);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/email",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(tenantsSlice.actions.updateEmail({ id, email }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateTenantOccupation(id, occupation) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("occupation", occupation);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/occupation",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(tenantsSlice.actions.updateOccupation({ id, occupation }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateTenantCompany(id, company) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("company", company);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/company",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(tenantsSlice.actions.updateCompany({ id, company }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateTenantTIN(id, tin) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("tin", tin);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/tin",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(tenantsSlice.actions.updateTIN({ id, tin }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateTenantOffers(id, offers) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("offers", offers);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/tenants/${id}/update/offers`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(tenantsSlice.actions.updateOffers({ id, offers }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw new Error("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function updateTenantFavicon(id, favicon) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("favicon", favicon);
    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result
            .replace("data:", "")
            .replace(/^.+,/, "");
          resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    };
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/${id}/update/favicon`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const base64Favicon = await convertToBase64(favicon);
      dispatch(tenantsSlice.actions.updateFavicon({ id, base64Favicon }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw new Error("User not found");
      } else if (error.status === 500) {
        throw new Error("Internal Server Error");
      }
    }
  };
}

export function updateTenantWhitelabel(id, whitelabelImage) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("whitelabel", whitelabelImage);
    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result
            .replace("data:", "")
            .replace(/^.+,/, "");
          resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    };
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/${id}/update/whitelabel`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const base64Whitelabel = await convertToBase64(whitelabelImage);
      dispatch(tenantsSlice.actions.updateWhitelabel({ id, base64Whitelabel }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw new Error("User not found");
      } else if (error.status === 500) {
        throw new Error("Internal Server Error");
      }
    }
  };
}

export function updateTenantBackgroundColor(id, color) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("backgroundColor", color);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/${id}/update/backgroundColor`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(tenantsSlice.actions.updateBackgroundColor({ id, color }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw new Error("User not found");
      } else if (error.status === 500) {
        throw new Error("Internal Server Error");
      }
    }
  };
}

export function updateTenantFontColor(id, color) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("fontColor", color);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/${id}/update/fontColor`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(tenantsSlice.actions.updateFontColor({ id, color }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw new Error("User not found");
      } else if (error.status === 500) {
        throw new Error("Internal Server Error");
      }
    }
  };
}

export function updateTenantSidebarPosition(id, position) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("sidebarPosition", position);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/${id}/update/sidebarPosition`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(tenantsSlice.actions.updateSidebarPosition({ id, position }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw new Error("User not found");
      } else if (error.status === 500) {
        throw new Error("Internal Server Error");
      }
    }
  };
}

export function updateTenantPrivilege(
  userId,
  clientId,
  privilege,
  upperEntityId
) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("entityId", clientId);
    formData.append("privilege", privilege);
    if (privilege !== "ADMIN" && privilege !== "VIEWER") {
      formData.append("upperEntityId", upperEntityId);
    } else {
      formData.append("upperEntityId", -1);
    }
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          userId +
          "/update/privilege",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(tenantsSlice.actions.updatePrivilege({ clientId, privilege }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not found");
      } else if (error.status === 500) {
        console.log("Internal Server Error");
      }
    }
  };
}

export function addNewTenant({ values }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const data = {
        fullName: values.fullName,
        address: values.address,
        city: values.city,
        country: values.country,
        postcode: values.postcode,
        telephoneNumber: values.telephoneNumber,
        mobileNumber: values.mobileNumber,
        email: values.email,
        occupation: values.occupation,
        company: values.company,
        tin: values.tin,
        username: values.username,
        portfolioId: values.portfolioId,
        minimumAmountToInvoice: values.minimumAmountToInvoice,
        invoiceType: values.invoiceType,
        invoiceHandlerType: values.invoiceHandlerType,
        currency: values.currency,
        invoiceConfiguration: values.invoiceConfiguration,
        preferredLanguage: values.preferredLanguage,
        billingEmail: values.billingEmail,
        sendWelcomeMail: values.sendWelcomeMail,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/tenants`,
        data,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(tenantsSlice.actions.addTenant(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function removeExistingTenant(id) {
  return (dispatch) => {
    dispatch(tenantsSlice.actions.removeTenant(id));
  };
}

export const {
  setTenants,
  clearTenants,
  updateFullName,
  updateAddress,
  updateCity,
  updateCountry,
  updatePostcode,
  updateTelephone,
  updateEmail,
  updateOccupation,
  updateCompany,
  updateTIN,
  updateOffers,
  updatePrivilege,
  addTenant,
  removeTenant,
  updateFavicon,
  updateWhitelabel,
  updateBackgroundColor,
  updateFontColor,
  updateSidebarPosition,
  updateTenant,
} = tenantsSlice.actions;
export default tenantsSlice.reducer;
