import { Formik } from "formik";
import React, { useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import NotyfContext from "../../contexts/NotyfContext";
import useAuth from "../../hooks/useAuth";

const VerifyMfa = () => {
  const { user } = useSelector((state) => state.user);
  const { verifyMfa } = useAuth();
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  return (
    <Formik
      initialValues={{
        code: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        code: Yup.string().required("Required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await verifyMfa(values.code);
          navigate("/");
        } catch (error) {
          notyf.open({
            type: "danger",
            message: "Invalid MFA Code",
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>MFA Code</Form.Label>
            <Form.Control
              type="text"
              name="code"
              value={values.code}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>
          <div className="text-center mt-2">
            <Button
              type="submit"
              size="lg"
              disabled={isSubmitting}
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default VerifyMfa;
