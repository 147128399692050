import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAvailableOffers } from "../../../../redux/slices/offers";
import {
  Modal,
  ProgressBar,
  Form,
  Spinner,
  Table,
  Button,
} from "react-bootstrap";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import NotyfContext from "../../../../contexts/NotyfContext";
import { Formik } from "formik";
import * as Yup from "yup";
import Loader from "../../../../components/Loader";
import { modifySimProduct } from "../../../../redux/slices/sims";

const ModifySimModal = ({
  sim,
  openModifyProductModal,
  setOpenModifyProductModal,
  clearSelectedRows,
}) => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const handleClose = () => setOpenModifyProductModal(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const availableOffers = useSelector((state) => state.offers.availableOffers);
  const notyf = useContext(NotyfContext);
  const [currentStep, setCurrentStep] = useState(1);

  const initialValues = {
    sim: sim,
    offer: "",
  };

  const validation = Yup.object().shape({
    offer: Yup.string().required("Offer is required"),
  });

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchAvailableOffers = async () => {
      await dispatch(getAvailableOffers()).catch((error) => {
        notyf.open({
          type: "danger",
          message: error,
          dismissible: false,
          duration: 5000,
          ripple: true,
          position: {
            x: "center",
            y: "top",
          },
        });
        setOpenModifyProductModal(false);
      });
    };

    if (activeUser.id && openModifyProductModal) {
      fetchAvailableOffers();
    }
  }, [activeUser, openModifyProductModal]);

  return (
    <Modal
      show={openModifyProductModal}
      onHide={() => {
        setOpenModifyProductModal(false);
        setCurrentStep(1);
      }}
      backdrop="static"
      centered
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>Modify SIM</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={async (values, { setValues, setStatus }) => {
            await dispatch(
              modifySimProduct({
                sim: values.sim.imsi,
                targetOffer: values.offer,
              })
            )
              .then(() => {
                notyf.open({
                  type: "success",
                  message: "SIM modified successfully",
                  dismissible: false,
                  duration: 5000,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
                setStatus("Success");
              })
              .catch((error) => {
                notyf.open({
                  type: "danger",
                  message: error,
                  dismissible: false,
                  ripple: true,
                  duration: 5000,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
                setStatus("Error");
              })
              .finally(() => {
                setCurrentStep(3);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validateForm,
            status,
            setFieldTouched,
            setValues,
          }) => {
            const nextStep = () => {
              validateForm().then((errors) => {
                if (Object.keys(errors).length === 0) {
                  if (currentStep === 1) {
                    setCurrentStep(2);
                    handleSubmit();
                  }
                } else {
                  Object.keys(values).forEach((field) => {
                    setFieldTouched(field);
                  });
                }
              });
            };
            return (
              <Form onSubmit={handleSubmit}>
                {currentStep === 1 && (
                  <React.Fragment>
                    <Form.Group className="mb-3">
                      <Table hover responsive>
                        <thead>
                          <tr>
                            <th>IMSI</th>
                            <th>ICCID</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{values.sim.imsi}</td>
                            <td>{values.sim.iccid}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Offer</Form.Label>
                      <Form.Select
                        name="offer"
                        value={values.offer}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={Boolean(touched.offer && errors.offer)}
                      >
                        <option value="">Select an offer</option>
                        {availableOffers.map((offer) => (
                          <option key={offer.id} value={offer.name}>
                            {offer.name}
                          </option>
                        ))}
                      </Form.Select>
                      {!!touched.offer && (
                        <Form.Control.Feedback type="invalid">
                          {errors.offer}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </React.Fragment>
                )}
                {currentStep === 2 && <Loader />}
                {currentStep === 3 && status === "Success" && (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <FaCheckCircle size={120} color="#4BBF73" />
                    <h3>Success</h3>
                  </div>
                )}
                {currentStep === 3 && status === "Error" && (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <FaExclamationCircle size={120} color="#FF0000" />
                    <h3 className="mt-3">Order Error</h3>
                    <p>Please contact the administrator.</p>
                  </div>
                )}
                <Modal.Footer>
                  {currentStep === 1 && (
                    <React.Fragment>
                      <Button
                        variant="primary"
                        onClick={() => {
                          setOpenModifyProductModal(false);
                          setCurrentStep(1);
                          setValues(initialValues);
                        }}
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        onClick={nextStep}
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                        }}
                      >
                        Submit
                      </Button>
                    </React.Fragment>
                  )}
                  {currentStep === 3 && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        setOpenModifyProductModal(false);
                        setCurrentStep(1);
                        setValues(initialValues);
                        clearSelectedRows();
                      }}
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                        border: "none",
                      }}
                    >
                      Close
                    </Button>
                  )}
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ModifySimModal;
