import React, { useState, useEffect, useContext, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import getNotificationData from "./notificationData";
import NotyfContext from "../../contexts/NotyfContext";
import { getNotifications } from "../../redux/slices/notifications";
import { useTranslation } from "react-i18next";
import {
  useTable,
  useRowSelect,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from "react-table";
import { Card, Col, Form, Pagination, Row, Table } from "react-bootstrap";
import Loader from "../../components/spinners/Loader";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";

const Notifications = () => {
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const user = useSelector((state) => state.user);
  const impersonationUser = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const { t } = useTranslation();
  const { notificationColumns } = useMemo(() => getNotificationData(t), [t]);
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchNotifications = async () => {
      setIsLoading(true);
      await Promise.all([dispatch(getNotifications())])
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
          setIsLoading(false);
        });
    };

    if (activeUser.id) {
      fetchNotifications();
    }
  }, [activeUser]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: notificationColumns,
      data: notifications,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  return (
    <Card>
      <Card.Header>
        <Card.Title>{t("notifications.title")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && <Loader />}
        {!isLoading && (
          <Table hover responsive {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <span {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                        <span>
                          {column.sortable ? (
                            column.isSorted ? (
                              column.isSortedDesc ? (
                                <FaSortUp className="ms-2 " />
                              ) : (
                                <FaSortDown className="ms-2 " />
                              )
                            ) : (
                              <FaSort className="ms-2" />
                            )
                          ) : null}
                        </span>
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </Card.Body>
      {!isLoading && notifications.length > 0 && (
        <Card.Footer>
          <Row>
            <Col md="9">
              <span className="mx-2">
                {t("table.page")}{" "}
                <strong>
                  {pageIndex + 1} {t("table.of")} {pageOptions.length}
                </strong>
              </span>
              <span className="ms-3 me-2">{t("table.show")}:</span>
              <Form.Select
                className="d-inline-block w-auto"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Form.Select>

              <span className="ms-3 me-2">{t("table.goToPage")}:</span>
              <Form.Control
                className="d-inline-block"
                type="number"
                defaultValue={pageIndex + 1}
                min={1}
                max={pageCount}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "75px" }}
              />
            </Col>
            <Col md="3">
              <Pagination className="float-end">
                <Pagination.First
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                />
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
                <Pagination.Last
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                />
              </Pagination>
            </Col>
          </Row>
        </Card.Footer>
      )}
    </Card>
  );
};

export default Notifications;
