import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Image } from "react-bootstrap";
import pos2cloud from "../../assets/img/pos2cloud.png";
import VerifyMfa from "../../components/auth/VerifyMfa";
import { useDispatch, useSelector } from "react-redux";
import { fetchWhitelabel } from "../../redux/slices/whitelabel";

const VerifyMfaPage = () => {
  const dispatch = useDispatch();
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const currentUrl = window.location.hostname;

  useEffect(() => {
    dispatch(fetchWhitelabel(currentUrl));
  }, []);
  return (
    <React.Fragment>
      <Helmet title="Sign In" />

      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center">
              <Image
                src={
                  whitelabel?.whitelabelImage &&
                  whitelabel?.whitelabelImage?.imagePath
                    ? `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${whitelabel?.whitelabelImage?.imagePath}`
                    : pos2cloud
                }
                alt=""
                className="img-fluid"
                width="150"
                height="150"
              />
            </div>
            <VerifyMfa />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default VerifyMfaPage;
