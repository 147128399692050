import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Card } from "react-bootstrap";
import Header from "./Header";
import Statistics from "./statistics";
import WorldMap from "./WorldMap";
import DataVolumeBarChart from "./DataVolumeBarChart";
import TopMetricsBarChart from "./TopMetricsBarChart";
import WatchdogAlerts from "./WatchdogTable";
import NotyfContext from "../../contexts/NotyfContext";
import SimInventoryPieChart from "./SimInventoryPie";
import OffersDistributionBar from "./OffersDistributionPie";
import NetworksDistributionPieChart from "./NetworksDistributionPieChart";
import DataVolumeNetworkPieChart from "./DataVolumeNetworPieChart";
import {
  getActiveSims,
  getAlertCounts,
  getAverageDataVolume,
  getCounts,
  getDataSessionCounts,
  getDataVolumeCounts,
  getZeroSessionCounts,
} from "../../redux/slices/dashboard";
import DataVolumePerHourChart from "./DataVolumePerHourChart";
import Loader from "../spinners/Loader";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import AdditionalStatistics from "./additionalStatistics";

const Dashboard = () => {
  const notyf = useContext(NotyfContext);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [type, setType] = useState("day");
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboard);
  const [isLoading, setIsLoading] = useState(false);
  const {
    data_volume,
    data_volume_difference_percentage,
    data_sessions,
    data_sessions_difference_percentage,
    alerts,
    alerts_difference_percentage,
    zero_sessions,
    zero_sessions_difference_percentage,
    average_data_volume,
    active_sims,
  } = dashboard;
  const { t } = useTranslation();
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [impersonationUser, user]);

  if (!impersonationUser) {
    if (user && user.privilege === "VIEWER") {
      notyf.open({
        type: "warning",
        message:
          "This platform is just a Demo. For increased functionality, contact the Administrator",
        duration: 10000,
        ripple: true,
        dismissible: false,
        position: {
          x: "center",
          y: "top",
        },
      });
    }
  }

  useEffect(() => {
    const fetchCounts = async () => {
      await Promise.all([
        dispatch(getDataSessionCounts({ type: type })),
        dispatch(getDataVolumeCounts({ type: type })),
        dispatch(getZeroSessionCounts({ type: type })),
        dispatch(getAlertCounts({ type: type })),
        dispatch(getActiveSims()),
        dispatch(getAverageDataVolume()),
      ]).catch((error) => {
        notyf.open({
          type: "danger",
          message: error,
          duration: 5000,
          dismissible: false,
          ripple: true,
          position: {
            x: "center",
            y: "top",
          },
        });
      });
    };

    if (activeUser.id) {
      fetchCounts();
    }
  }, [activeUser, type, reload]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <React.Fragment>
      <Helmet title="Analytics Dashboard">
        <link
          rel="icon"
          href={`${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${whitelabel?.tabImage?.imagePath}`}
        />
      </Helmet>
      <Container fluid className="p-0">
        <Header type={type} setType={setType} setReload={setReload} />

        <Row>
          <Col md="12" lg="12" xl="5" className="d-flex">
            <Statistics
              type={type}
              data_volume={data_volume}
              data_volume_difference_percentage={
                data_volume_difference_percentage
              }
              data_sessions={data_sessions}
              data_sessions_difference_percentage={
                data_sessions_difference_percentage
              }
              alerts={alerts}
              alerts_difference_percentage={alerts_difference_percentage}
              zero_sessions={zero_sessions}
              zero_sessions_difference_percentage={
                zero_sessions_difference_percentage
              }
              average_data_volume={average_data_volume}
            />
          </Col>
          <Col md="12" lg="12" xl="7" className="d-flex">
            <WorldMap period={type} reload={reload} />
          </Col>
        </Row>
        <AdditionalStatistics
          average_data_volume={average_data_volume}
          active_sims={active_sims}
          reload={reload}
        />
        <Row>
          <Col className="d-flex">
            <DataVolumePerHourChart reload={reload} />
          </Col>
        </Row>
        <Row>
          <Col lg="8" className="d-flex">
            <DataVolumeBarChart period={type} reload={reload} />
          </Col>
          <Col lg="4" className="d-flex">
            <TopMetricsBarChart period={type} reload={reload} />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="12" lg xl xxl="6" className="d-flex">
            <OffersDistributionBar reload={reload} />
          </Col>
          <Col xs="12" sm="12" md="12" lg xl xxl="6" className="d-flex">
            <SimInventoryPieChart reload={reload} />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="12" lg xl xxl="6" className="d-flex">
            <DataVolumeNetworkPieChart reload={reload} />
          </Col>
          <Col xs="12" sm="12" md="12" lg xl xxl="6" className="d-flex">
            <NetworksDistributionPieChart reload={reload} />
          </Col>
        </Row>
        <Row>
          <Col>
            <WatchdogAlerts reload={reload} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Dashboard;
