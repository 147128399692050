import React, { useContext, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Card } from "react-bootstrap";
import AlertsTable from "./AlertsTable";
import getAlertData from "../../../inputData/alertData";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { getAlerts } from "../../../redux/slices/alerts";
import NotyfContext from "../../../contexts/NotyfContext";
import Loader from "../../../components/spinners/Loader";
import { useTranslation } from "react-i18next";

const AlertsPage = () => {
  const { t } = useTranslation();
  const { alertColumns } = useMemo(() => getAlertData(t), [t]);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const { alerts } = useSelector((state) => state.alerts);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const notyf = useContext(NotyfContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchWatchdogAlerts = async () => {
      await Promise.all([dispatch(getAlerts())])
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (activeUser.id) fetchWatchdogAlerts();
  }, [activeUser, dispatch]);

  if (isLoading)
    return (
      <React.Fragment>
        <Helmet title="Alerts" />
        <Container fluid className="p-0 w-100 h-100">
          <Card className="w-100 h-100">
            <Card.Body>
              <Loader />
            </Card.Body>
          </Card>
        </Container>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <Helmet title="Alerts" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{t("alerts.title")}</h1>
        <Row>
          <Col>
            <AlertsTable columns={alertColumns} data={alerts} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AlertsPage;
