import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { getEmails } from "../../../../redux/slices/distribution";
import NotyfContext from "../../../../contexts/NotyfContext";
import AddWatchdogForm from "./AddWatchdogForm";
import Loader from "../../../../components/spinners/Loader";
import { getZones } from "../../../../redux/slices/zones";
import { createWatchdog } from "../../../../redux/slices/watchdogs";

const AddWatchdogModal = ({
  openAddWatchdogModal,
  setOpenAddWatchdogModal,
  selectedSims,
  clearSelectedRows,
}) => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const handleClose = () => setOpenAddWatchdogModal(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const notyf = useContext(NotyfContext);
  const [isLoading, setIsLoading] = useState(true);
  const zones = useSelector((state) => state.zones.zones);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchDistribution = async () => {
      await Promise.all([dispatch(getEmails()), dispatch(getZones())])
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
          setOpenAddWatchdogModal(false);
        })
        .finally(() => setIsLoading(false));
    };

    if (activeUser.id && openAddWatchdogModal) fetchDistribution();
  }, [activeUser, openAddWatchdogModal]);

  const initialValues = {
    actionType: "none",
    amount: 0.0,
    serviceType: "data",
    service: zones.map((zone) => zone.id).join(","),
    suspendInterval: "",
    warning: "",
    warning1: "",
    warning2: "",
    unitType: "byte",
    watchdogInterval: "",
    notificationType: "none",
  };

  const validationSchema = Yup.object().shape({
    actionType: Yup.string().required("Required"),
    amount: Yup.number()
      .min(0.01, "Amount must be greater than 0")
      .required("Required"),
    serviceType: Yup.string().required("Required"),
    service: Yup.object().nullable(),
    suspendInterval: Yup.string().when("actionType", {
      is: "suspend",
      then: Yup.number().min(1).required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
    warning: Yup.number().notRequired().nullable(),
    warning1: Yup.number().notRequired().nullable(),
    warning2: Yup.number().notRequired().nullable(),
    unitType: Yup.string().required("Required"),
    watchdogInterval: Yup.number().min(0).required("Required"),
  });

  return (
    <Modal
      show={openAddWatchdogModal}
      onHide={handleClose}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>Add Watchdog</Modal.Header>
      <Modal.Body>
        {isLoading && <Loader />}
        {!isLoading && (
          <Formik
            formikProps
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { setValues }) => {
              await dispatch(
                createWatchdog({
                  imsiList: selectedSims.map((sim) => sim.imsi),
                  ...values,
                })
              )
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Watchdog created successfully",
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  clearSelectedRows();
                  setValues(initialValues);
                  handleClose();
                });
            }}
          >
            {({
              errors,
              handleBlur,
              setFieldTouched,
              handleChange,
              handleSubmit,
              touched,
              values,
              setValues,
              validateForm,
              setFieldValue,
            }) => {
              return (
                <AddWatchdogForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleSubmit={handleSubmit}
                  setValues={setValues}
                  initialValues={initialValues}
                  handleClose={handleClose}
                  clearSelectedSims={clearSelectedRows}
                />
              );
            }}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddWatchdogModal;
