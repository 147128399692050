import React, { useEffect, useState, useContext } from "react";
import { Card, Col, Container, Row, Fade, Button } from "react-bootstrap";
import { IoPersonAddSharp } from "react-icons/io5";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import Single from "./Single.js";
import CreateEntityModal from "./modals/CreateEntityModal.js";
import ClientsList from "./ClientsList.js";
import NotyfContext from "../../contexts/NotyfContext.js";
import pos2cloud from "../../assets/img/pos2cloud.png";
import Loader from "../../components/spinners/Loader.js";

function Clients({ title: propsTitle, clientList, isLoading }) {
  const { t } = useTranslation();
  const [shownClient, setShownClient] = useState({
    id: "",
    fullName: "",
    address: "",
    city: "",
    country: "",
    postcode: "",
    telNumber: "",
    email: "",
    occupation: "",
    company: "",
    tinNumber: "",
    privilege: "",
    activities: {},
    image: "",
  });
  const [open, setOpen] = useState(false);
  const [singleIsShown, setSingleIsShown] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const [privilege, setPrivilege] = useState();
  const [showCreateEntityModal, setShowCreateEntityModal] = useState(false);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [activeUser]);

  useEffect(() => {
    if (activeUser) {
      setPrivilege(activeUser.role);
    }
  }, [activeUser]);

  useEffect(() => {
    if (clientList.length > 0) {
      setShownClient(clientList[0]);
    }
  }, [clientList]);

  if (isLoading) {
    return (
      <Card className="w-100 h-100 d-flex justify-content-center align-items-center">
        <Card.Body className="w-100 h-100 d-flex-justify-content-center align-items-center">
          <Loader />
        </Card.Body>
      </Card>
    );
  }
  return (
    <React.Fragment>
      <Helmet title={propsTitle}>
        <link
          rel="icon"
          href={
            whitelabel?.tablmage && whitelabel?.tablmage?.imagePath
              ? `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${whitelabel?.tablmage?.imagePath}`
              : pos2cloud
          }
        />
      </Helmet>
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">
          {propsTitle === "Admins"
            ? t("crm.admins")
            : propsTitle === "Tenants"
            ? t("crm.tenants")
            : propsTitle === "Managers"
            ? t("crm.managers")
            : propsTitle === "Customers"
            ? t("crm.customers")
            : t("crm.subaccounts")}
        </h1>
        <Row>
          <Col
            md="12"
            xl={open ? "8" : "12"}
            style={{ transition: "all 0.5s" }}
          >
            <ClientsList
              clientList={clientList}
              shownClient={shownClient}
              setShownClient={setShownClient}
              setOpen={setOpen}
              setSingleIsShown={setSingleIsShown}
              title={propsTitle}
              showCreateEntityModal={showCreateEntityModal}
              setShowCreateEntityModal={setShowCreateEntityModal}
            />
          </Col>
          <Fade in={singleIsShown}>
            <Col md="12" xl="4">
              <Single
                shownClient={shownClient}
                setOpen={setOpen}
                setSingleIsShown={setSingleIsShown}
                privilege={privilege}
                title={propsTitle}
              />
            </Col>
          </Fade>
        </Row>
        <CreateEntityModal
          showCreateEntityModal={showCreateEntityModal}
          setShowCreateEntityModal={setShowCreateEntityModal}
          entityType={
            propsTitle === "Admins"
              ? "Admin"
              : propsTitle === "Tenants"
              ? "Tenant"
              : propsTitle === "Managers"
              ? "Manager"
              : propsTitle === "Customers"
              ? "Customer"
              : propsTitle === "Subaccounts"
              ? "Subaccount"
              : null
          }
        />
      </Container>
    </React.Fragment>
  );
}

export default Clients;
