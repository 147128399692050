import React, { useState } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { Search } from "react-feather";

function SearchBar(props) {
  const [searchText, setSearchText] = useState("");

  const handleSearch = (event) => {
    event.preventDefault();
    setSearchText("");
    props.onSearch(searchText);
  };

  return (
    <Form inline="true" onSubmit={handleSearch} className="support-search-form">
      <InputGroup className="support-search-input-grp">
        <Form.Control
          placeholder="Search"
          aria-label="Search"
          aria-describedby="basic-addon2"
          className="support-search-input"
        />
        <Button className="support-search-btn">
          <Search />
        </Button>
      </InputGroup>
    </Form>
  );
}

export default SearchBar;
