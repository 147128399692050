import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { getSims } from "../../../redux/slices/sims.js";
import { Container, Row, Col, Fade, Card } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

import Loader from "../../../components/spinners/Loader.js";
import Single from "./SimSingle";
import SimsList from "./SimsList.js";
import { getCustomFields } from "../../../redux/slices/customFields.js";
import NotyfContext from "../../../contexts/NotyfContext.js";
import SimFiltersContext from "../../../contexts/SimFiltersContext.js";
import { useTranslation } from "react-i18next";

function Sims() {
  const dispatch = useDispatch();
  const sims = useSelector((state) => state.sims.sims);
  const count = useSelector((state) => state.sims.count);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const notyf = useContext(NotyfContext);
  const { customFields } = useSelector((state) => state.customFields);
  const [open, setOpen] = useState(false);
  const [shownSim, setShownSim] = useState();
  const [singleIsShown, setSingleIsShown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfRecordsRetrieved, setNumberOfRecordsRetrieved] =
    useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [numberOfRecords, setNumberOfRecords] = useState(null);
  const [cellInfo, setCellInfo] = useState({
    area: "",
    cell: "",
    cellId: "",
    lat: "",
    lon: "",
    mcc: "",
    range: "",
    unit: "",
  });
  // const [filters] = useState({
  //   status: "",
  //   imsi: "",
  //   iccid: "",
  //   msisdn: "",
  //   eid: "",
  //   minData: "",
  //   maxData: "",
  // });
  const { filters, setFilters } = useContext(SimFiltersContext);
  const [mergedFilters, setMergedFilters] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setMergedFilters(filters);
  }, [filters]);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchSims = async () => {
      await Promise.all([
        dispatch(
          getSims({
            page: page + 1,
            pageSize: pageSize,
            filters: filters,
          })
        ),
      ])
        .then(() => {
          setNumberOfRecords(sims.count);
          setNumberOfRecordsRetrieved(true);
        })
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (activeUser.id) {
      fetchSims();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, count, activeUser]);

  useEffect(() => {
    setMergedFilters(
      customFields.reduce(
        (acc, field) => {
          acc[field.displayName.trim()] = "";
          return acc;
        },
        { ...filters }
      )
    );
  }, [customFields, filters]);

  const result = Math.ceil(numberOfRecords / pageSize);

  if (isLoading || !numberOfRecordsRetrieved) {
    return (
      <Card className="w-100 h-100">
        <Card.Body className="w-100 h-100 d-flex justify-content-center align-items-center">
          <Loader />
        </Card.Body>
      </Card>
    );
  } else {
    return (
      <React.Fragment>
        <Helmet title="SIMs">
          <link
            rel="icon"
            href={`${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${whitelabel?.tabImage?.imagePath}`}
          />
        </Helmet>
        <Container fluid className="p-0">
          <h1 className="h3 mb-3">{t("sims.title")}</h1>
          <Row>
            <Col
              md="12"
              xl={open ? "8" : "12"}
              style={{ transition: "all 0.5s" }}
            >
              <SimsList
                setOpen={setOpen}
                setSingleIsShown={setSingleIsShown}
                shownSim={shownSim}
                setShownSim={setShownSim}
                data={sims.sims}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                numberOfRecords={sims.pages}
                setCellInfo={setCellInfo}
                filters={filters}
                setFilters={setFilters}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                customFields={customFields}
              />
            </Col>
            <Fade in={singleIsShown}>
              <Col md="12" xl="4">
                <Single
                  setOpen={setOpen}
                  setSingleIsShown={setSingleIsShown}
                  shownSim={shownSim}
                  cellInfo={cellInfo}
                  page={page}
                  pageSize={pageSize}
                  filters={mergedFilters}
                  clearSelectedRows={() => setSelectedRows([])}
                />
              </Col>
            </Fade>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Sims;
