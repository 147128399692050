import {
  Bell,
  BookOpen,
  //Calendar,
  //CheckSquare,
  //Grid,
  //Heart,
  //Layout,
  //List,
  //PieChart,
  //Sliders,
  //MapPin,
  //Users,
  //Share,
  HelpCircle,
} from "react-feather";
import {
  FaSimCard,
  FaUsers,
  FaUser,
  FaHome,
  FaFile,
  FaExclamationTriangle,
  FaCog,
  FaLockOpen,
  FaRecycle,
  FaTimesCircle,
  FaHistory,
} from "react-icons/fa";
import { FiBox } from "react-icons/fi";

const pagesSection = [
  {
    href: "/dashboard/default",
    icon: FaHome,
    title: "sidebar.dashboard",
  },
  {
    href: "/crm",
    icon: FaUsers,
    title: "sidebar.crm",
    children: [
      {
        href: "/crm/admins",
        title: "sidebar.admins",
      },
      {
        href: "/crm/tenants",
        title: "sidebar.tenants",
      },
      {
        href: "/crm/managers",
        title: "sidebar.managers",
      },
      {
        href: "/crm/customers",
        title: "sidebar.customers",
      },
      {
        href: "/crm/users",
        title: "sidebar.subaccounts",
      },
    ],
  },
  {
    href: "/sims",
    icon: FaSimCard,
    title: "sidebar.sims",
  },
  {
    href: "/account",
    icon: FaUser,
    title: "sidebar.account",
    children: [
      {
        href: "/profile",
        title: "sidebar.profile",
      },
      {
        href: "/account/billingproducts",
        title: "sidebar.billingProducts",
      },
      {
        href: "/account/invoices",
        title: "sidebar.invoices",
      },
      {
        href: "/account/statements",
        title: "sidebar.statements",
      },
      {
        href: "/account/orders",
        title: "sidebar.orders",
      },
      {
        href: "/account/emails",
        title: "sidebar.emails",
      },
    ],
  },
  {
    href: "/catalogue",
    icon: FiBox,
    title: "Catalog",
    children: [
      {
        href: "/catalogue/new",
        title: "New Offer",
      },
      {
        href: "/catalogue/offers",
        title: "View Offers",
      },
    ],
  },
  {
    href: "/reports",
    icon: FaFile,
    title: "sidebar.reports",
    children: [
      {
        href: "/reports/single",
        title: "sidebar.generateReport",
        icon: FaFile,
      },
      {
        href: "/reports/automated",
        title: "sidebar.automatedReports",
        icon: FaTimesCircle,
      },
      {
        href: "/reports/history",
        title: "sidebar.reportHistory",
        icon: FaHistory,
      },
    ],
  },
  {
    href: "/alerts",
    icon: FaExclamationTriangle,
    title: "sidebar.alerts",
  },
  // {
  //   href: "/support",
  //   icon: HelpCircle,
  //   title: "Support",
  // },
  {
    href: "/notifications",
    icon: Bell,
    title: "sidebar.notifications",
  },
  {
    href: "/pages/settings",
    icon: FaCog,
    title: "sidebar.settings",
  },
  {
    href: "/pages/privileges",
    icon: FaLockOpen,
    title: "sidebar.privileges",
  },

  /*{
    href: "/pages",
    icon: Layout,
    title: "Pages",
    children: [
      {
        href: "/pages/profile",
        title: "Profile",
      },
      {
        href: "/pages/settings",
        title: "Settings",
      },
      {
        href: "/pages/clients",
        title: "Clients",
      },
      {
        href: "/pages/projects",
        title: "Projects",
      },
      {
        href: "/pages/invoice",
        title: "Invoice",
      },
      {
        href: "/pages/pricing",
        title: "Pricing",
      },
      {
        href: "/pages/tasks",
        title: "Tasks",
      },
      {
        href: "/pages/chat",
        title: "Chat",
        badge: "New",
      },
      {
        href: "/pages/blank",
        title: "Blank Page",
      },
    ],
  },*/
  /*{
    href: "/auth",
    icon: Users,
    title: "Auth",
    children: [
      {
        href: "/auth/sign-in",
        title: "Sign In",
      },
      {
        href: "/auth/sign-up",
        title: "Sign Up",
      },
      {
        href: "/auth/reset-password",
        title: "Reset Password",
      },
      {
        href: "/auth/404",
        title: "404 Page",
      },
      {
        href: "/auth/500",
        title: "500 Page",
      },
    ],
  },*/
  {
    href: "/docs/introduction",
    icon: BookOpen,
    title: "sidebar.documentation",
  },
];

const navItems = [
  {
    pages: pagesSection,
  },
];

export default navItems;
