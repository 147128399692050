import * as React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, isMfaAuthenticated } = useAuth();
  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  } else if (isInitialized && isAuthenticated && !isMfaAuthenticated) {
    return <Navigate to="/auth/2fa" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
