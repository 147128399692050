import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "../../../redux/slices/orders";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Order from "./Order";
import Loader from "../../../components/spinners/Loader";
import OrderModal from "./modals/OrderModal";
import NotyfContext from "../../../contexts/NotyfContext";
import { useTranslation } from "react-i18next";

const Lane = ({ name, children, showOrderModal, setShowOrderModal, user }) => {
  const { t } = useTranslation();
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  return (
    <Card>
      <Card.Header>
        <Card.Title>{name}</Card.Title>
      </Card.Header>
      <Card.Body>
        <div>
          {children && children.length > 0 ? children : t("orders.noOrders")}
        </div>
        <div className="d-grid">
          {children && children.length > 0 && (
            <Button
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
              onClick={() => setShowOrderModal(true)}
            >
              {t("orders.showAllOrders")}
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

const Orders = () => {
  const { t } = useTranslation();
  const { orders } = useSelector((state) => state.orders);
  const [openOrders, setOpenOrders] = useState([]);
  const [parkedOrders, setParkedOrders] = useState([]);
  const [finishedOrders, setFinishedOrders] = useState([]);
  const [errorOrders, setErrorOrders] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const notyf = useContext(NotyfContext);
  const [isLoading, setIsLoading] = useState(true);
  const [showOpenOrderModal, setShowOpenOrderModal] = useState(false);
  const [showErrorOrderModal, setShowErrorOrderModal] = useState(false);
  const [showFinishedOrderModal, setShowFinishedOrderModal] = useState(false);
  const [showParkedOrderModal, setShowParkedOrderModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [impersonationUser, user]);

  useEffect(() => {
    const fetchOrders = async () => {
      await Promise.all([dispatch(getOrders())])
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (activeUser.id) {
      fetchOrders();
    }
  }, [dispatch, activeUser]);

  useEffect(() => {
    const open = orders.filter((order) => order.status === "Open");
    const parked = orders.filter((order) => order.status === "Parked");
    const finished = orders.filter((order) => order.status === "Done");
    const error = orders.filter((order) => order.status === "Error");
    setOpenOrders(open);
    setParkedOrders(parked);
    setFinishedOrders(finished);
    setErrorOrders(error);
  }, [orders]);

  if (!isLoading) {
    return (
      <React.Fragment>
        <Helmet title="Orders" />
        <Container fluid className="p-0">
          <h1 className="h3 mb-3">{t("orders.title")}</h1>
          <Row>
            <Col lg="6">
              <Lane
                name={t("orders.open")}
                showOrderModal={showOpenOrderModal}
                setShowOrderModal={setShowOpenOrderModal}
                user={impersonationUser.id ? impersonationUser : user}
              >
                {openOrders.map((order) => (
                  <Order key={order.id} order={order} />
                ))}
              </Lane>
              <OrderModal
                showOrderModal={showOpenOrderModal}
                setShowOrderModal={setShowOpenOrderModal}
                orders={openOrders}
              />
            </Col>
            <Col lg="6">
              <Lane
                name={t("orders.parked")}
                showOrderModal={showParkedOrderModal}
                setShowOrderModal={setShowParkedOrderModal}
                user={impersonationUser.id ? impersonationUser : user}
              >
                {parkedOrders.map((order) => (
                  <Order key={order.id} order={order} />
                ))}
              </Lane>
              <OrderModal
                showOrderModal={showParkedOrderModal}
                setShowOrderModal={setShowParkedOrderModal}
                orders={parkedOrders}
              />
            </Col>
            <Col lg="6">
              <Lane
                name={t("orders.finished")}
                showOrderModal={showFinishedOrderModal}
                setShowOrderModal={setShowFinishedOrderModal}
                user={impersonationUser.id ? impersonationUser : user}
              >
                {finishedOrders.slice(0, 5).map((order) => (
                  <Order key={order.id} order={order} />
                ))}
              </Lane>
              <OrderModal
                showOrderModal={showFinishedOrderModal}
                setShowOrderModal={setShowFinishedOrderModal}
                orders={finishedOrders}
              />
            </Col>
            <Col lg="6">
              <Lane
                name={t("orders.error")}
                showOrderModal={showErrorOrderModal}
                setShowOrderModal={setShowErrorOrderModal}
                user={impersonationUser.id ? impersonationUser : user}
              >
                {errorOrders.slice(0, 5).map((order) => (
                  <Order key={order.id} order={order} />
                ))}
              </Lane>
              <OrderModal
                showOrderModal={showErrorOrderModal}
                setShowOrderModal={setShowErrorOrderModal}
                orders={errorOrders}
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Helmet title="Orders" />
        <Container fluid className="p-0 w-100 h-100">
          <Card className="w-100 h-100">
            <Card.Body>
              <Loader />
            </Card.Body>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
};

export default Orders;
