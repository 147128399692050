import React from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

const Loader = () => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);

  return (
    <Container fluid className="vh-50 d-flex">
      <Row className="justify-content-center align-self-center w-100 text-center">
        <Spinner
          animation="border"
          variant="primary"
          style={{
            "--dynamic-spinner-color": whitelabel?.backgroundColor ?? "#00acdc",
          }}
        />
      </Row>
    </Container>
  );
};

export default Loader;
