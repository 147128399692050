import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBillingPreferencesForClient,
  updateUserBillingPreferences,
} from "../../../redux/slices/preferences";
import NotyfContext from "../../../contexts/NotyfContext";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Loader from "../../../components/spinners/Loader";
import * as Yup from "yup";
import { Formik } from "formik";
import CurrencyList from "currency-list";
import { useTranslation } from "react-i18next";

const BillingSettingsModal = ({
  client,
  showBillingSettingsModal,
  setShowBillingSettingsModal,
}) => {
  const currencyList = CurrencyList.getAll("en_US");
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const billingPreferences = useSelector(
    (state) => state.preferences.billingPreferences
  );
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchClientBillingPreferences = async () => {
      await dispatch(getBillingPreferencesForClient({ clientId: client.id }))
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (client.id && showBillingSettingsModal) {
      fetchClientBillingPreferences();
    }
  }, [client, dispatch, showBillingSettingsModal]);

  const initialValues = {
    currency: billingPreferences?.currency ?? "",
    billingFrequency:
      billingPreferences?.billingFrequency === "MONTHLY"
        ? "monthly"
        : billingPreferences?.billingFrequency === "QUARTERLY"
        ? "quarterly"
        : "annually" ?? "",
    daysToPayInvoice: billingPreferences?.daysToPayInvoice ?? "",
    minimumAmountToInvoice: billingPreferences?.minimumAmountToInvoice ?? "",
    invoiceType:
      billingPreferences?.invoiceType === "NONE"
        ? "none"
        : billingPreferences?.invoiceType === "STANDARD"
        ? "standard"
        : billingPreferences?.invoiceType === "PORTFOLIO"
        ? "portfolio"
        : "domain" ?? "",
    invoiceConfiguration: billingPreferences?.invoiceConfiguration ?? "",
    preferredLanguage: billingPreferences?.preferredLanguage ?? "",
    billingEmail: billingPreferences?.billingEmail ?? "",
  };

  const validation = Yup.object().shape({
    currency: Yup.string().required("Required"),
    billingFrequency: Yup.string().required("Required"),
    daysToPayInvoice: Yup.number().min(0).required("Required"),
    minimumAmountToInvoice: Yup.number().min(0).required("Required"),
    invoiceType: Yup.string().required("Invoice type is required"),
    invoiceConfiguration: Yup.string().required(
      "Invoice configuration is required"
    ),
    preferredLanguage: Yup.string().required("Preferred language is required"),
    billingEmail: Yup.string()
      .email("Must be an email")
      .required("Billing email is required"),
  });

  return (
    <Modal
      show={showBillingSettingsModal}
      onHide={() => setShowBillingSettingsModal(false)}
      centered
      backdrop="static"
      scrollable
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("crm.billingModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <Loader />}
        {!isLoading && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, { setValues }) => {
              await dispatch(
                updateUserBillingPreferences({ id: client.id, data: values })
              )
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Billing settings updated successfully",
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  setShowBillingSettingsModal(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setValues,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("crm.billingModal.currency")}</Form.Label>
                    <Form.Select
                      name="currency"
                      value={values.currency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.currency && errors.currency}
                    >
                      <option value="">Select Currency</option>
                      {Object.keys(currencyList).map((currencyCode) => {
                        const currency = currencyList[currencyCode];
                        return (
                          <option key={currencyCode} value={currencyCode}>
                            {currencyCode} - {currency.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                    {!!touched.currency && (
                      <Form.Control.Feedback type="invalid">
                        {errors.currency}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("crm.billingModal.billingFrequency")}
                    </Form.Label>
                    <Form.Select
                      name="billingFrequency"
                      value={values.billingFrequency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        touched.billingFrequency && errors.billingFrequency
                      }
                    >
                      <option value="">Select Billing Frequency</option>
                      <option value="monthly">Monthly</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="annually">Annually</option>
                    </Form.Select>
                    {!!touched.billingFrequency && (
                      <Form.Control.Feedback type="invalid">
                        {errors.billingFrequency}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("crm.billingModal.daysToPayInvoice")}
                    </Form.Label>
                    <Form.Control
                      name="daysToPayInvoice"
                      type="text"
                      value={values.daysToPayInvoice}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        touched.daysToPayInvoice && errors.daysToPayInvoice
                      }
                    />
                    {!!touched.daysToPayInvoice && (
                      <Form.Control.Feedback type="invalid">
                        {errors.daysToPayInvoice}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("crm.billingModal.minimumAmountToInvoice")}
                    </Form.Label>
                    <Form.Control
                      name="minimumAmountToInvoice"
                      type="text"
                      value={values.minimumAmountToInvoice}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        touched.minimumAmountToInvoice &&
                        errors.minimumAmountToInvoice
                      }
                    />
                    {!!touched.minimumAmountToInvoice && (
                      <Form.Control.Feedback type="invalid">
                        {errors.minimumAmountToInvoice}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>
                        {t("crm.billingModal.invoiceType")}
                      </Form.Label>
                      <Form.Select
                        name="invoiceType"
                        value={values.invoiceType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.invoiceType && errors.invoiceType}
                      >
                        <option value="none">None</option>
                        <option value="standard">Standard</option>
                        <option value="portfolio">Portfolio</option>
                        <option value="domain">Domain</option>
                      </Form.Select>
                      {!!touched.invoiceType && (
                        <Form.Control.Feedback type="invalid">
                          {errors.invoiceType}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>
                        {t("crm.billingModal.preferredLanguage")}
                      </Form.Label>
                      <Form.Select
                        name="preferredLanguage"
                        value={values.preferredLanguage}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.preferredLanguage && errors.preferredLanguage
                        }
                      >
                        <option value="">Select Preferred Language</option>
                        <option value="English">English</option>
                      </Form.Select>
                      {!!touched.preferredLanguage && (
                        <Form.Control.Feedback type="invalid">
                          {errors.preferredLanguage}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("crm.billingModal.billingEmail")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="billingEmail"
                      value={values.billingEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.billingEmail && errors.billingEmail}
                    />
                    {!!touched.billingEmail && (
                      <Form.Control.Feedback type="invalid">
                        {errors.billingEmail}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                        border: "none",
                      }}
                      onClick={() => {
                        setValues(initialValues);
                        setShowBillingSettingsModal(false);
                      }}
                    >
                      {t("crm.billingModal.cancel")}
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                        border: "none",
                      }}
                    >
                      {t("crm.billingModal.submit")}
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default BillingSettingsModal;
