import { Formik } from "formik";
import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import countryList from "react-select-country-list";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { updateUserAccount } from "../../../redux/slices/user";
import NotyfContext from "../../../contexts/NotyfContext";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";

const AccountSettings = ({ activeTab }) => {
  const { t } = useTranslation();
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const countryOptions = useMemo(() => {
    const options = countryList().getData();
    return [{ label: "Select a country", value: "" }, ...options];
  }, []);
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (activeTab === "#Account") {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [activeTab]);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [impersonationUser, user]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const initialValues = {
    fullName: activeUser.fullName,
    address: activeUser.role !== "subaccount" ? activeUser.address : "",
    city: activeUser.role !== "subaccount" ? activeUser.city : "",
    country: activeUser.role !== "subaccount" ? activeUser.country : "",
    postcode: activeUser.role !== "subaccount" ? activeUser.postcode : "",
    telephoneNumber:
      activeUser.role !== "subaccount" ? activeUser.telephoneNumber : "",
    mobileNumber:
      activeUser.role !== "subaccount" ? activeUser?.mobileNumber ?? "" : "",
    email: activeUser.email,
    occupation:
      activeUser.role !== "admin" && activeUser.role !== "subaccount"
        ? activeUser.occupation
        : "",
    company:
      activeUser.role !== "admin" && activeUser.role !== "subaccount"
        ? activeUser.company
        : "",
    tin:
      activeUser.role !== "admin" && activeUser.role !== "subaccount"
        ? activeUser.tin
        : "",
    username: activeUser.username,
    changePassword: false,
    password: "",
    confirmPassword: "",
  };

  const validation = Yup.object().shape({
    fullName: Yup.string().required("Full name is required"),
    address:
      activeUser.role !== "subaccount"
        ? Yup.string().required("Address is required")
        : Yup.string(),
    city:
      activeUser.role !== "subaccount"
        ? Yup.string().required("City is required")
        : Yup.string(),
    country:
      activeUser.role !== "subaccount"
        ? Yup.string().required("Country is required")
        : Yup.string(),
    postcode:
      activeUser.role !== "subaccount"
        ? Yup.string().required("Postcode is required")
        : Yup.string(),
    telephoneNumber:
      activeUser.role !== "subaccount"
        ? Yup.string().required("Telephone number is required")
        : Yup.string(),
    mobileNumber:
      activeUser.role !== "subaccount" ? Yup.string() : Yup.string(),
    email: Yup.string().email("Invalid email").required("Email is required"),
    occupation:
      activeUser.role !== "admin" && activeUser.role !== "subaccount"
        ? Yup.string().required("Occupation is required")
        : Yup.string(),
    company:
      activeUser.role !== "admin" && activeUser.role !== "subaccount"
        ? Yup.string().required("Company is required")
        : Yup.string(),
    tin:
      activeUser.role !== "admin" && activeUser.role !== "subaccount"
        ? Yup.string().required("TIN is required")
        : Yup.string(),
    username: Yup.string().required("Username is required"),
    changePassword: Yup.boolean(),
    password: Yup.string().when("changePassword", {
      is: true,
      then: Yup.string().required("Password is required"),
    }),
    confirmPassword: Yup.string().when("changePassword", {
      is: true,
      then: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    }),
  });

  return (
    <Card>
      <Card.Header>
        <Card.Title>{t("settings.accountSettings.title")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && <Loader />}
        {!isLoading && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, { setValues }) => {
              const data = {
                fullName: values.fullName,
                email: values.email,
                username: values.username,
              };
              if (values.changePassword) {
                data.password = values.password;
              }
              if (activeUser.role !== "subaccount") {
                data.address = values.address;
                data.city = values.city;
                data.country = values.country;
                data.postcode = values.postcode;
                data.telephoneNumber = values.telephoneNumber;
                data.mobileNumber = values.mobileNumber;
              }
              if (
                activeUser.role !== "admin" &&
                activeUser.role !== "subaccount"
              ) {
                data.occupation = values.occupation;
                data.company = values.company;
                data.tin = values.tin;
              }
              await dispatch(updateUserAccount({ data: data }))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Account updated successfully",
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    dismissible: false,
                    ripple: true,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("settings.accountSettings.fullName")}
                    </Form.Label>
                    <Form.Control
                      name="fullName"
                      type="text"
                      value={values.fullName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.fullName && errors.fullName}
                    />
                  </Form.Group>
                  {activeUser.role !== "subaccount" && (
                    <Form.Group className="mb-3">
                      <Form.Label>
                        {t("settings.accountSettings.address")}
                      </Form.Label>
                      <Form.Control
                        name="address"
                        type="text"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.address && errors.address}
                      />
                    </Form.Group>
                  )}
                  {activeUser.role !== "subaccount" && (
                    <React.Fragment>
                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Form.Label>
                            {t("settings.accountSettings.city")}
                          </Form.Label>
                          <Form.Control
                            name="city"
                            type="text"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.city && errors.city}
                          />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>
                            {t("settings.accountSettings.country")}
                          </Form.Label>
                          <Form.Select
                            name="country"
                            value={values.country}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.country && errors.country}
                          >
                            {countryOptions.map((country) => (
                              <option key={country.value} value={country.label}>
                                {country.label}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>
                            {t("settings.accountSettings.postcode")}
                          </Form.Label>
                          <Form.Control
                            name="postcode"
                            type="text"
                            value={values.postcode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.postcode && errors.postcode}
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Form.Label>
                            {t("settings.accountSettings.telephone")}
                          </Form.Label>
                          <Form.Control
                            name="telephoneNumber"
                            type="text"
                            value={values.telephoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.telephoneNumber && errors.telephoneNumber
                            }
                          />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>
                            {t("settings.accountSettings.mobileNumber")}
                          </Form.Label>
                          <Form.Control
                            name="mobileNumber"
                            type="text"
                            value={values.mobileNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.mobileNumber && errors.mobileNumber
                            }
                          />
                        </Form.Group>
                      </Row>
                    </React.Fragment>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("settings.accountSettings.email")}
                    </Form.Label>
                    <Form.Control
                      name="email"
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.email && errors.email}
                    />
                  </Form.Group>
                  {activeUser.role !== "admin" &&
                    activeUser.role !== "subaccount" && (
                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Form.Label>
                            {t("settings.accountSettings.occupation")}
                          </Form.Label>
                          <Form.Control
                            name="occupation"
                            type="text"
                            value={values.occupation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.occupation && errors.occupation}
                          />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>
                            {t("settings.accountSettings.company")}
                          </Form.Label>
                          <Form.Control
                            name="company"
                            type="text"
                            value={values.company}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.company && errors.company}
                          />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>
                            {t("settings.accountSettings.tin")}
                          </Form.Label>
                          <Form.Control
                            name="tin"
                            type="text"
                            value={values.tin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.tin && errors.tin}
                          />
                        </Form.Group>
                      </Row>
                    )}
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("settings.accountSettings.username")}
                    </Form.Label>
                    <Form.Control
                      name="username"
                      type="text"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.username && errors.username}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="checkbox"
                      label={t("settings.accountSettings.changePassword")}
                      name="changePassword"
                      checked={values.changePassword}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Collapse in={values.changePassword} dimension="height">
                    <div>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t("settings.accountSettings.password")}
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            name="password"
                            type={showPassword ? "text" : "password"}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.password && errors.password}
                          />
                          <InputGroup.Text
                            style={{
                              borderTopRightRadius: "20px",
                              borderBottomRightRadius: "20px",
                              backgroundColor: "transparent",
                              borderLeft: "none",
                            }}
                          >
                            <Button
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                                color: "gray",
                              }}
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </Button>
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t("settings.accountSettings.confirmPassword")}
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            name="confirmPassword"
                            type={showConfirmPassword ? "text" : "password"}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.confirmPassword && errors.confirmPassword
                            }
                          />
                          <InputGroup.Text
                            style={{
                              borderTopRightRadius: "20px",
                              borderBottomRightRadius: "20px",
                              backgroundColor: "transparent",
                              borderLeft: "none",
                            }}
                          >
                            <Button
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                                color: "gray",
                              }}
                              onClick={toggleConfirmPasswordVisibility}
                            >
                              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </Button>
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </div>
                  </Collapse>
                  <Row>
                    <Col md="auto ms-auto">
                      <Button
                        type="submit"
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#fff",
                          border: "none",
                        }}
                      >
                        {t("settings.accountSettings.save")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        )}
      </Card.Body>
    </Card>
  );
};

export default AccountSettings;
