import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";
import { impersonateUser } from "./impersonationUser";
import { loginUser } from "./user";

export async function enableMfa() {
  const accessToken = window.localStorage.getItem("accessToken");
  const impersonationToken = window.localStorage.getItem("impersonationToken");
  if (!accessToken) return Promise.reject("No access token");
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/2fa/enable`,
      {
        headers: {
          Authorization: `Bearer ${
            impersonationToken ? impersonationToken : accessToken
          }`,
        },
      }
    );
    return Promise.resolve(response.data.data);
  } catch (error) {
    return Promise.reject(error.message);
  }
}

export const disableMfa = () => {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/2fa/disable`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      if (impersonationToken) {
        dispatch(impersonateUser(response.data.data));
      } else {
        dispatch(loginUser(response.data.data));
      }
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
};

export const verifyMfa = (code) => {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const data = {
        mfaCode: code,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/auth/verify`,
        data,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      if (impersonationToken) {
        dispatch(impersonateUser(response.data.data));
      } else {
        dispatch(loginUser(response.data.data));
      }
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
};
