import React from "react";
import { Row, Col, Pagination, Form } from "react-bootstrap";

const ClientPagination = ({
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
  pageCount,
  gotoPage,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  t,
}) => {
  return (
    <Row>
      <Col md="9">
        <span className="mx-2">
          {t("table.page")}{" "}
          <strong>
            {pageIndex + 1} {t("table.of")} {pageOptions.length}
          </strong>
        </span>
        <span className="ms-3 me-2">{t("table.show")}:</span>
        <Form.Select
          className="d-inline-block w-auto"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Form.Select>

        <span className="ms-3 me-2">{t("table.goToPage")}:</span>
        <Form.Control
          className="d-inline-block"
          type="number"
          defaultValue={pageIndex + 1}
          min={1}
          max={pageCount}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
          style={{ width: "75px" }}
        />
      </Col>
      <Col md="3">
        <Pagination className="float-end">
          <Pagination.First
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          />
          <Pagination.Prev
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          />
          <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
          <Pagination.Last
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          />
        </Pagination>
      </Col>
    </Row>
  );
};

export default ClientPagination;
