import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  distribution: [],
};

const distributionSlice = createSlice({
  name: "distribution",
  initialState,
  reducers: {
    setDistributionList: (state, action) => {
      state.distribution = action.payload;
    },
    addEmailToDistributionList(state, action) {
      return {
        ...state,
        distribution: [...state.distribution, action.payload],
      };
    },
    updateDistributionEmail(state, action) {
      const { id, email } = action.payload;
      const updatedEmails = state.distribution.map((distributionEmail) => {
        if (distributionEmail.id === id) {
          return {
            id: email.id,
            name: email.name,
            address: email.address,
            category: email.category,
          };
        }
        return distributionEmail;
      });
      return {
        ...state,
        distribution: updatedEmails,
      };
    },
    removeEmailFromDistribution(state, action) {
      const idToRemove = action.payload;
      const newEmails = state.distribution.filter(
        (email) => email.id !== idToRemove
      );
      return {
        ...state,
        distribution: newEmails,
      };
    },
  },
});

export function getEmails() {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token found");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/distribution`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        distributionSlice.actions.setDistributionList(response.data.data)
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function createEmail({ data }) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/distribution`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        distributionSlice.actions.addEmailToDistributionList(response.data.data)
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function updateEmail({ id, data }) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/distribution/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        distributionSlice.actions.updateDistributionEmail({
          id,
          email: response.data.data,
        })
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function deleteEmail(id) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/distribution/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(distributionSlice.actions.removeEmailFromDistribution(id));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export const {
  setDistributionList,
  addEmailToDistributionList,
  removeEmailFromDistribution,
} = distributionSlice.actions;

export default distributionSlice.reducer;
