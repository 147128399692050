import React, { useState, useContext, useEffect } from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getHlrInfo } from "../../redux/slices/sims";
import NotyfContext from "../../contexts/NotyfContext";
import Loader from "../../components/spinners/Loader";
import { useTranslation } from "react-i18next";

export const HlrProfileModal = ({
  showHlrProfileModal,
  setShowHlrProfileModal,
  sim,
}) => {
  const { t } = useTranslation();
  const handleClose = () => setShowHlrProfileModal(false);
  const [scope, setScope] = useState("basicInfo");
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const hlrInfo = useSelector((state) => state.sims.hlrInfo);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchHlrInfo = async () => {
      await dispatch(getHlrInfo({ imsi: sim.imsi, scope: scope }))
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (activeUser.id && sim.imsi && scope) {
      fetchHlrInfo(activeUser.id);
    }
  }, [activeUser, sim, scope, notyf]);

  return (
    <Modal
      show={showHlrProfileModal}
      onHide={handleClose}
      centered
      backdrop="static"
      size="xl"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("simProfile.hlrModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-4">
          <Col md={2} className="float-end">
            <Form.Group>
              <Form.Label>{t("simProfile.hlrModal.selectScope")}:</Form.Label>
              <Form.Select
                value={scope}
                onChange={(e) => setScope(e.target.value)}
              >
                <option key={1} value="basicInfo">
                  {t("simProfile.hlrModal.basicInfo")}
                </option>
                <option key={2} value="locationInfo">
                  {t("simProfile.hlrModal.locationInfo")}
                </option>
                <option key={3} value="csInfo">
                  {t("simProfile.hlrModal.csBasicInfo")}
                </option>
                <option key={4} value="gprsInfo">
                  {t("simProfile.hlrModal.gprsService2g3g")}
                </option>
                <option key={5} value="telephonyService">
                  {t("simProfile.hlrModal.telephonyService")}
                </option>
                <option key={6} value="supplementaryService">
                  {t("simProfile.hlrModal.supplementaryService")}
                </option>
                <option key={7} value="epcLocation">
                  {t("simProfile.hlrModal.epcService4G")}
                </option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        {isLoading && <Loader />}
        {!isLoading && (
          <Table size="sm">
            <tbody>
              {hlrInfo.map((hlr) => (
                <tr>
                  <th>{hlr.category}</th>
                  <td>{hlr.value}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default HlrProfileModal;
