import React from "react";
import {
  Card,
  Accordion,
  Container,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import { SocialIcon } from "react-social-icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function SupportContent() {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  return (
    <Container fluid className="mt-4">
      <Row>
        <Col md={6}>
          <Card>
            <Card.Header>
              <Card.Title>Popular Articles</Card.Title>
            </Card.Header>
            <Card.Body>
              <ul className="support-popular-articles">
                <li>
                  <Link to="/docs/getting-started">Getting started</Link>
                </li>
                <li>
                  <Link to="/docs/getting-started">Account Management</Link>
                </li>
                <li>
                  <Link to="/docs/getting-started">Platform Guides</Link>
                </li>
                <li>
                  <Link to="/docs/getting-started">Billing Information</Link>
                </li>
                <li>
                  <Link to="/docs/getting-started">Reporting</Link>
                </li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Header className="pb-0">
              <Card.Title>Frequently Asked Questions</Card.Title>
            </Card.Header>
            <Card.Body>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0" className="bg-white">
                  <Accordion.Header>Accordion Item #1</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Accordion Item #2</Accordion.Header>
                  <Accordion.Body className="bg-white">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Accordion Item #3</Accordion.Header>
                  <Accordion.Body className="bg-white">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header className="pb-0">
              <Card.Title>Contact Us</Card.Title>
              <Card.Body>
                <Container>
                  <Row>
                    <Col md={6} sm={12}>
                      <Row className="mt-2">
                        <Col>
                          <Card.Title>Chat with us</Card.Title>
                          <Button
                            className="mt-3"
                            style={{
                              "--dynamic-bg-color":
                                whitelabel?.backgroundColor ?? "#00acdc",
                              "--dynamic-font-color":
                                whitelabel?.fontColor ?? "#ffffff",
                              border: "none",
                            }}
                          >
                            Chat
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col>
                          <Card.Title>Call us</Card.Title>
                          <a href="tel: +302310365067">+30-2310365067</a>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col>
                          <Card.Title>Find us</Card.Title>
                          <div className="d-flex social-icons mt-3">
                            <div className="facebook-icon">
                              <SocialIcon
                                url="https://www.facebook.com/skytrackgr"
                                style={{
                                  width: 40,
                                  height: 40,
                                }}
                              />
                            </div>
                            <div className="ms-2 instagram-icon">
                              <SocialIcon
                                url="https://www.instagram.com/skytrack_org/"
                                style={{
                                  width: 40,
                                  height: 40,
                                }}
                              />
                            </div>
                            <div className="ms-2 linkedin-icon">
                              <SocialIcon
                                url="https://www.linkedin.com/company/skytrack/"
                                style={{
                                  width: 40,
                                  height: 40,
                                }}
                              />
                            </div>
                            <div className="ms-2 twitter-icon">
                              <SocialIcon
                                url="https://twitter.com/skytrack_org"
                                style={{ width: 40, height: 40 }}
                              />
                            </div>
                            <div className="ms-2 youtube-icon">
                              <SocialIcon
                                url="https://www.youtube.com/skytrack"
                                style={{ width: 40, height: 40 }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} sm={12}>
                      <Row>
                        <Col>
                          <Card.Title>Get a quote</Card.Title>
                          <Form>
                            <Form.Group className="mb-3">
                              <Form.Label>Full Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="fullName"
                                placeholder="Full Name"
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                placeholder="E-mail"
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Message</Form.Label>
                              <Form.Control
                                as="textarea"
                                name="message"
                                placeholder="Message"
                                rows="5"
                              />
                            </Form.Group>
                            <Button
                              style={{
                                "--dynamic-bg-color":
                                  whitelabel?.backgroundColor ?? "#00acdc",
                                "--dynamic-font-color":
                                  whitelabel?.fontColor ?? "#ffffff",
                                border: "none",
                              }}
                            >
                              Submit
                            </Button>
                          </Form>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card.Header>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default SupportContent;
