import React from "react";
import { Pie } from "react-chartjs-2";

import { Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SimsByStatusModal from "../../pages/pages/dashboard/modals/SimsByStatusModal";
import { getSimInventory } from "../../redux/slices/dashboard";
import NotyfContext from "../../contexts/NotyfContext";
import Loader from "../spinners/Loader";

const SimInventoryPieChart = ({ reload }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [simInventory, setSimInventory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [showSimsByStatusModal, setShowSimsByStatusModal] = useState(false);
  const [status, setStatus] = useState("");
  const sim_inventory = useSelector((state) => state.dashboard.sim_inventory);
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchSimInventory = async () => {
      setIsLoading(true);
      await dispatch(getSimInventory())
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (activeUser.id) {
      fetchSimInventory();
    }
  }, [activeUser, reload]);

  const data = {
    labels: sim_inventory.map((item) => item.status),
    datasets: [
      {
        data: sim_inventory.map((item) => item.count),
        backgroundColor: sim_inventory.map((item) => {
          let color = "";
          if (item.status === "Active") {
            color = "#00FF00";
          } else if (item.status === "Productive") {
            color = "#36A2EB";
          } else if (item.status === "OnStock") {
            color = "#808080";
          } else if (item.status === "Deleted") {
            color = "#FF0000";
          } else if (item.status === "Suspended") {
            color = "#FFCE56";
          } else if (item.status === "TestProductive") {
            color = "#2600ff";
          } else if (item.status === "AutoSuspended") {
            color = "#e1ff00";
          }
          return color;
        }),
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "right",
    },
    onClick: (e, element) => {
      if (element.length > 0) {
        const newStatus = element[0]._model.label;
        setStatus(newStatus);
        setShowSimsByStatusModal(true);
      }
    },
  };

  return (
    <Card id="pieChart">
      <Card.Header>
        <Card.Title>{t("dashboard.simInventory")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && <Loader />}
        {!isLoading && (
          <React.Fragment>
            <div className="chart chart-sm">
              <Pie data={data} options={options} />
            </div>
            <SimsByStatusModal
              showSimsByStatusModal={showSimsByStatusModal}
              setShowSimsByStatusModal={setShowSimsByStatusModal}
              status={status}
            />
          </React.Fragment>
        )}
      </Card.Body>
    </Card>
  );
};

export default SimInventoryPieChart;
