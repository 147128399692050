import React from "react";
import { Badge, Dropdown, Image } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import defaultUser from "../../assets/img/avatars/default-user.png";

const NavbarImpersonation = () => {
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const { stopImpersonation } = useAuth();
  const [firstInitial, setFirstInitial] = useState("");
  const [secondInitial, setSecondInitial] = useState("");
  const navigate = useNavigate();
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);

  if (impersonationUser) {
    return (
      <Dropdown className="me-2 nav-item" align="end">
        <Dropdown.Toggle as="a" className="nav-link nav-impersonate">
          <Image
            src={
              impersonationUser.photo
                ? `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${impersonationUser.photo}`
                : defaultUser
            }
            className="rounded-pill"
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#fff",
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            key="logout"
            onClick={async () => {
              await stopImpersonation();
              navigate("/");
            }}
          >
            Exit Impersonation
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  } else {
    return null;
  }
};

export default NavbarImpersonation;
