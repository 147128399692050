import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  DropdownButton,
  ButtonGroup,
  Dropdown,
  Badge,
} from "react-bootstrap";
import { DropdownSubmenu } from "react-bootstrap-submenu";
import ActivateSimModal from "../sims/modals/ActivateSimModal";
import SuspendSimModal from "./modals/SuspendSimModal";
import ResumeSimModal from "./modals/ResumeSimModal";
import TerminateSimModal from "./modals/TerminateSimModal";
import { exportSims } from "../../../redux/slices/sims";
import { useDispatch, useSelector } from "react-redux";
import ModifySimModal from "./modals/ModifySimModal";
import AddWatchdogModal from "./modals/AddWatchdogModal";
import RemoveAllWatchdogsModal from "./modals/RemoveAllWatchdogsModal";
import SmsModal from "../sms/SmsModal";
import AddCustomFieldModal from "./modals/AddCustomFieldModal";
import RemoveCustomFieldModal from "./modals/RemoveCustomFieldModal";
import ModifyCustomFieldModal from "./modals/ModifyCustomFieldModal";
import FileSaver from "file-saver";
import NotyfContext from "../../../contexts/NotyfContext";
import { initiliazeWatchdogs } from "../../../redux/slices/watchdogs";
import { useTranslation } from "react-i18next";

const SimActionsDropdown = ({
  selectedRows,
  clearSelectedRows,
  pageIndex,
  pageRecords,
  filters,
  setIsLoading,
  sims,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const { customFields } = useSelector((state) => state.customFields);
  const [customFieldsLength, setCustomFieldsLength] = useState();
  const [isBulkActivateAvailable, setIsBulkActivateAvailable] = useState(false);
  const [isBulkSuspendAvailable, setIsBulkSuspendAvailable] = useState(false);
  const [isBulkResumeAvailable, setIsBulkResumeAvailable] = useState(false);
  const [isBulkTerminateAvailable, setIsBulkTerminateAvailable] =
    useState(false);
  const [isBulkTestModeAvailable, setIsBulkTestModeAvailable] = useState(false);
  const [isSmsAvailable, setIsSmsAvailable] = useState(true);
  const [isClearSelectionAvailable, setIsClearSelectionAvailable] =
    useState(false);
  const [isModifyProductAvailable, setIsModifyProductAvailable] =
    useState(false);
  const [isBulkAddWatchDogAvailable, setIsBulkAddWatchDogAvailable] =
    useState(false);
  const [isBulkRemoveWatchdogAvailable, setIsBulkRemoveWatchdogAvailable] =
    useState(false);
  const [isAddCustomFieldAvailable, setIsAddCustomFieldAvailable] =
    useState(false);
  const [isRemoveCustomFieldAvailable, setIsRemoveCustomFieldAvailable] =
    useState(false);
  const [isModifyCustomFieldAvailable, setIsModifyCustomFieldAvailable] =
    useState(false);
  const [isWatchdogInitAvailable, setIsWatchdogInitAvailable] = useState(false);
  const [isExportToCsvAvailable, setIsExportToCsvAvailable] = useState(false);
  const [isExportToPdfAvailable, setIsExportToPdfAvailable] = useState(false);

  const [openActivateModal, setOpenActivateModal] = useState(false);
  const [openSuspendModal, setOpenSuspendModal] = useState(false);
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const [openTerminateModal, setOpenTerminateModal] = useState(false);
  const [openModifyProductModal, setOpenModifyProductModal] = useState(false);
  const [openAddWatchdogModal, setOpenAddWatchdogModal] = useState(false);
  const [openRemoveWatchdogModal, setOpenRemoveWatchdogModal] = useState(false);
  const [openSmsModal, setOpenSmsModal] = useState(false);
  const [openAddCustomFieldModal, setOpenAddCustomFieldModal] = useState(false);
  const [openRemoveCustomFieldModal, setOpenRemoveCustomFieldModal] =
    useState(false);
  const [openModifyCustomFieldModal, setOpenModifyCustomFieldModal] =
    useState(false);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    if (selectedRows.length === 0) {
      setIsBulkActivateAvailable(false);
    } else {
      const allOnStockOrTestProductive = selectedRows.every(
        (row) => row.status === "OnStock" || row.status === "TestProductive"
      );
      setIsBulkActivateAvailable(allOnStockOrTestProductive);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (selectedRows.length === 0) {
      setIsBulkSuspendAvailable(false);
    } else {
      const allProductive = selectedRows.every(
        (row) => row.state === "Productive"
      );
      setIsBulkSuspendAvailable(allProductive);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (selectedRows.length === 0) {
      setIsBulkResumeAvailable(false);
    } else {
      const allSuspended = selectedRows.every(
        (row) => row.status === "Suspended"
      );
      setIsBulkResumeAvailable(allSuspended);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (selectedRows.length === 0) {
      setIsBulkTerminateAvailable(false);
    } else {
      const allProductiveOrSuspended = selectedRows.every(
        (row) => row.status === "Productive" || row.status === "Suspended"
      );
      setIsBulkTerminateAvailable(allProductiveOrSuspended);
    }
  }, [selectedRows]);

  useEffect(() => {
    const allOnStock = selectedRows.every((row) => row.status === "OnStock");
    setIsBulkTestModeAvailable(allOnStock);
  }, [selectedRows]);

  useEffect(() => {
    const allActive = selectedRows.every(
      (row) => row.status === "Active" || row.status === "Productive"
    );
    setIsSmsAvailable(allActive);
  }, [selectedRows]);

  useEffect(() => {
    setIsClearSelectionAvailable(selectedRows.length > 0);
  }, [selectedRows]);

  useEffect(() => {
    setIsModifyProductAvailable(selectedRows.length !== 1);
  }, [selectedRows]);

  useEffect(() => {
    const allActive = selectedRows.every(
      (row) => row.status === "Active" || row.status === "Productive"
    );
    setIsWatchdogInitAvailable(
      selectedRows.length > 0 && selectedRows.length <= 50 && allActive
    );
  }, [selectedRows]);

  useEffect(() => {
    if (selectedRows.length === 0) {
      setIsBulkAddWatchDogAvailable(false);
      setIsBulkRemoveWatchdogAvailable(false);
    } else {
      const firstSku = selectedRows[0].sku;
      const allSameProvider = selectedRows.every(
        (row) => row.sku === firstSku && row.state === "Productive"
      );
      setIsBulkAddWatchDogAvailable(allSameProvider);
      setIsBulkRemoveWatchdogAvailable(allSameProvider);
    }
  }, [selectedRows]);

  useEffect(() => {
    setCustomFieldsLength(customFields.length);
  }, [customFields]);

  useEffect(() => {
    if (customFieldsLength < 10) {
      setIsAddCustomFieldAvailable(true);
    } else {
      setIsAddCustomFieldAvailable(false);
    }
  }, [customFieldsLength]);

  useEffect(() => {
    if (customFieldsLength > 0) {
      setIsRemoveCustomFieldAvailable(true);
    } else {
      setIsRemoveCustomFieldAvailable(false);
    }
  }, [customFieldsLength]);

  useEffect(() => {
    if (customFieldsLength > 0 && selectedRows.length > 0) {
      setIsModifyCustomFieldAvailable(true);
    } else {
      setIsModifyCustomFieldAvailable(false);
    }
  }, [customFieldsLength, selectedRows]);

  useEffect(() => {
    setIsExportToCsvAvailable(selectedRows.length > 0);
    setIsExportToPdfAvailable(selectedRows.length > 0);
  }, [selectedRows]);

  return (
    <Row className="g-0">
      <Col sm="12" xl="12" className="col-xxl-12">
        <div className="d-flex mt-2 float-end" id="tableActionsPanel">
          {selectedRows.length > 0 && (
            <Badge
              className="me-3 text-center d-flex align-items-center custom-badge"
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
            >
              {selectedRows.length} rows selected
            </Badge>
          )}
          <DropdownButton
            as={ButtonGroup}
            // style={{
            //   background: "#31d5ca",
            //   border: "none",
            // }}
            title={t("sims.actions")}
            className="me-3"
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              border: "none",
            }}
          >
            <DropdownSubmenu title={t("sims.modify")}>
              <Dropdown.Item
                eventKey="2"
                disabled={isModifyProductAvailable}
                onClick={() => setOpenModifyProductModal(true)}
              >
                {t("sims.product")}
              </Dropdown.Item>
              {/* <Dropdown.Item eventKey="3">Custom Fields</Dropdown.Item>
              <Dropdown.Item eventKey="4">IMEI Lock</Dropdown.Item> */}
            </DropdownSubmenu>
            <Dropdown.Item
              eventKey="clearSelection"
              disabled={!isClearSelectionAvailable}
              onClick={clearSelectedRows}
            >
              {t("sims.clearSelection")}
            </Dropdown.Item>
          </DropdownButton>
          <DropdownButton
            as={ButtonGroup}
            title={t("sims.bulk")}
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              border: "none",
            }}
          >
            <Dropdown.Item
              eventKey="bulkActivate"
              disabled={!isBulkActivateAvailable}
              onClick={() => setOpenActivateModal(true)}
            >
              {t("sims.activate")}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="bulkSuspend"
              disabled={!isBulkSuspendAvailable}
              onClick={() => setOpenSuspendModal(true)}
            >
              {t("sims.suspend")}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="bulkResume"
              disabled={!isBulkResumeAvailable}
              onClick={() => setOpenResumeModal(true)}
            >
              {t("sims.resume")}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="bulkTerminate"
              disabled={!isBulkTerminateAvailable}
              onClick={() => setOpenTerminateModal(true)}
            >
              {t("sims.terminate")}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="bulkTerminate"
              disabled={!isBulkTestModeAvailable}
            >
              {t("sims.testMode")}
            </Dropdown.Item>
            {activeUser.role !== "admin" &&
              (activeUser.role === "tenant" ||
                activeUser.role === "manager" ||
                activeUser.role === "customer" ||
                (activeUser.role === "subaccount" &&
                  activeUser.admin === null)) && (
                <Dropdown.Item
                  eventKey="sendSms"
                  disabled={!isSmsAvailable}
                  onClick={() => setOpenSmsModal(true)}
                >
                  {t("sims.sendSms")}
                </Dropdown.Item>
              )}
            <DropdownSubmenu title={t("sims.watchdogs")}>
              <Dropdown.Item
                eventKey="bulkAddWatchdog"
                disabled={!isBulkAddWatchDogAvailable}
                onClick={() => {
                  setOpenAddWatchdogModal(true);
                }}
              >
                {t("sims.createWatchdog")}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="bulkRemoveWatchdog"
                disabled={!isBulkRemoveWatchdogAvailable}
                onClick={() => {
                  setOpenRemoveWatchdogModal(true);
                }}
              >
                {t("sims.removeAllWatchdogs")}
              </Dropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title={t("sims.export")}>
              <Dropdown.Item
                eventKey="exportCsv"
                disabled={!isExportToCsvAvailable}
                onClick={async () => {
                  await exportSims({
                    imsiList: selectedRows.map((sim) => sim.imsi),
                    format: "csv",
                  })
                    .then((response) => {
                      const blob = new Blob([response.data], {
                        type: "text/csv",
                      });
                      FileSaver.saveAs(blob, "sims.csv");
                    })
                    .catch((error) => {
                      notyf.open({
                        type: "danger",
                        message: error,
                        ripple: true,
                        dismissible: false,
                        duration: 5000,
                        position: {
                          x: "center",
                          y: "top",
                        },
                      });
                    })
                    .finally(() => {
                      clearSelectedRows();
                    });
                }}
              >
                CSV
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="exportPdf"
                disabled={!isExportToPdfAvailable}
                onClick={async () => {
                  await exportSims({
                    imsiList: selectedRows.map((sim) => sim.imsi),
                    format: "pdf",
                  })
                    .then((response) => {
                      const blob = new Blob([response.data], {
                        type: "application/pdf",
                      });
                      FileSaver.saveAs(blob, "sims.pdf");
                    })
                    .catch((error) => {
                      notyf.open({
                        type: "danger",
                        message: error,
                        ripple: true,
                        dismissible: false,
                        duration: 5000,
                        position: {
                          x: "center",
                          y: "top",
                        },
                      });
                    })
                    .finally(() => {
                      clearSelectedRows();
                    });
                }}
              >
                PDF
              </Dropdown.Item>
            </DropdownSubmenu>
          </DropdownButton>
        </div>
        <ActivateSimModal
          openActivateModal={openActivateModal}
          selectedSims={selectedRows}
          clearSelectedRows={clearSelectedRows}
          setOpenActivateModal={setOpenActivateModal}
        />
        <SuspendSimModal
          openSuspendModal={openSuspendModal}
          sims={selectedRows}
          clearSelectedRows={clearSelectedRows}
          setOpenSuspendModal={setOpenSuspendModal}
          page={pageIndex}
          pageSize={pageRecords}
          filters={filters}
        />
        <ResumeSimModal
          openResumeModal={openResumeModal}
          sims={selectedRows}
          clearSelectedRows={clearSelectedRows}
          setOpenResumeModal={setOpenResumeModal}
          page={pageIndex}
          pageSize={pageRecords}
          filters={filters}
        />
        <TerminateSimModal
          openTerminateModal={openTerminateModal}
          sims={selectedRows}
          clearSelectedRows={clearSelectedRows}
          setOpenTerminateModal={setOpenTerminateModal}
          page={pageIndex}
          pageSize={pageRecords}
          filters={filters}
        />
        <ModifySimModal
          sim={selectedRows[0]}
          setOpenModifyProductModal={setOpenModifyProductModal}
          openModifyProductModal={openModifyProductModal}
          clearSelectedRows={clearSelectedRows}
          page={pageIndex}
          pageSize={pageRecords}
          filters={filters}
        />
        <AddWatchdogModal
          openAddWatchdogModal={openAddWatchdogModal}
          setOpenAddWatchdogModal={setOpenAddWatchdogModal}
          selectedSims={selectedRows}
          clearSelectedRows={clearSelectedRows}
        />
        <RemoveAllWatchdogsModal
          openRemoveWatchdogModal={openRemoveWatchdogModal}
          setOpenRemoveWatchdogModal={setOpenRemoveWatchdogModal}
          selectedSims={selectedRows}
          clearSelectedSims={clearSelectedRows}
        />
        <SmsModal
          openSmsModal={openSmsModal}
          setOpenSmsModal={setOpenSmsModal}
          sims={selectedRows}
        />
        <AddCustomFieldModal
          openAddCustomFieldModal={openAddCustomFieldModal}
          setOpenAddCustomFieldModal={setOpenAddCustomFieldModal}
        />
        <RemoveCustomFieldModal
          openRemoveCustomFieldModal={openRemoveCustomFieldModal}
          setOpenRemoveCustomFieldModal={setOpenRemoveCustomFieldModal}
        />
        <ModifyCustomFieldModal
          openModifyCustomField={openModifyCustomFieldModal}
          setOpenModifyCustomField={setOpenModifyCustomFieldModal}
          selectedRows={selectedRows}
          page={pageIndex}
          pageSize={pageRecords}
          filters={filters}
        />
      </Col>
    </Row>
  );
};

export default SimActionsDropdown;
