import React from "react";
import { matchPath } from "react-router-dom";

import SidebarNavListItem from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";

const filterChildrenByPrivilege = ({ title, children, privilege, user }) => {
  if (title === "sidebar.crm") {
    switch (privilege) {
      case "admin":
        return children;
      case "manager":
        return children.filter(
          (child) =>
            child.title !== "sidebar.admins" &&
            child.title !== "sidebar.managers" &&
            child.title !== "sidebar.tenants"
        );
      case "tenant":
        return children.filter(
          (child) =>
            child.title !== "sidebar.admins" &&
            child.title !== "sidebar.tenants"
        );
      case "customer":
        return children.filter(
          (child) =>
            child.title !== "sidebar.admins" &&
            child.title !== "sidebar.managers" &&
            child.title !== "sidebar.tenants" &&
            child.title !== "sidebar.customers"
        );
      case "subaccount":
        if (user.admin !== null) {
          return children;
        } else if (user.tenant !== null) {
          return children.filter(
            (child) =>
              child.title !== "sidebar.admins" &&
              child.title !== "sidebar.tenants"
          );
        } else if (user.manager !== null) {
          return children.filter(
            (child) =>
              child.title !== "sidebar.admins" &&
              child.title !== "sidebar.managers" &&
              child.title !== "sidebar.tenants"
          );
        } else if (user.customer !== null) {
          return children.filter(
            (child) =>
              child.title !== "sidebar.admins" &&
              child.title !== "sidebar.managers" &&
              child.title !== "sidebar.tenants" &&
              child.title !== "sidebar.customers"
          );
        }
      default:
        return [];
    }
  } else if (title === "sidebar.account") {
    switch (privilege) {
      case "admin":
        return children.filter(
          (child) =>
            child.title === "sidebar.profile" ||
            child.title === "sidebar.orders"
        );
      case "tenant":
        return children;
      case "manager":
        return children;
      case "customer":
        return children;
      case "subaccount":
        if (user.admin !== null) {
          return children.filter(
            (child) =>
              child.title === "sidebar.profile" ||
              child.title === "sidebar.orders"
          );
        } else {
          return children;
        }
      default:
        return [];
    }
  } else if (title === "sidebar.reports") {
    return children;
  }
};

const reduceChildRoutes = (props) => {
  const { items, page, depth, currentRoute, privilege, user } = props;
  if (!page.children) {
    if (privilege === "viewer" && page.title !== "sidebar.dashboard") {
      return items;
    }
    if (privilege !== "admin" && page.title === "sidebar.documentation") {
      return items;
    }
    if (privilege !== "admin" && page.title === "sidebar.privileges") {
      return items;
    }
    items.push(
      <SidebarNavListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        title={page.title}
      />
    );
  } else if (page.title === "sidebar.crm") {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : false;

    const filteredChildren = filterChildrenByPrivilege({
      title: page.title,
      children: page.children,
      privilege: privilege,
      user: user,
    });

    if (filteredChildren.length === 0) {
      return items;
    }

    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
      >
        <SidebarNavList depth={depth + 1} pages={filteredChildren} />
      </SidebarNavListItem>
    );
  } else if (page.title === "sidebar.account") {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : false;

    const filteredChildren = filterChildrenByPrivilege({
      title: page.title,
      children: page.children,
      privilege: privilege,
      user: user,
    });

    if (filteredChildren.length === 0) {
      return items;
    }
    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
      >
        <SidebarNavList depth={depth + 1} pages={filteredChildren} />
      </SidebarNavListItem>
    );
  } else if (page.title === "Catalog") {
    return items;
  } else if (page.title === "sidebar.reports") {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : false;

    const filteredChildren = filterChildrenByPrivilege({
      title: page.title,
      children: page.children,
      privilege: privilege,
      user: user,
    });

    if (filteredChildren.length === 0) {
      return items;
    }

    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
      >
        <SidebarNavList depth={depth + 1} pages={filteredChildren} />
      </SidebarNavListItem>
    );
  }

  return items;
};

export default reduceChildRoutes;
