import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  billingProducts: [],
};

const billingProductsSlice = createSlice({
  name: "billingProducts",
  initialState,
  reducers: {
    setbillingProducts(state, action) {
      state.billingProducts = action.payload;
    },
  },
});

export function getBillingProducts() {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/billingProducts`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        billingProductsSlice.actions.setbillingProducts(response.data.data)
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export const { setbillingProducts } = billingProductsSlice.actions;
export default billingProductsSlice.reducer;
