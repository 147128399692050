import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customFields: [],
};

const customFieldsSlice = createSlice({
  name: "customFields",
  initialState,
  reducers: {
    setCustomFields(state, action) {
      state.customFields = action.payload;
    },
    addCustomFieldToSlice(state, action) {
      const newCustomField = action.payload;
      return {
        ...state,
        customFields: [...state.customFields, newCustomField],
      };
    },
    removeCustomFieldFromSlice(state, action) {
      const idToRemove = action.payload;
      const newCustomFields = state.customFields.filter(
        (field) => field.id !== idToRemove
      );
      return {
        ...state,
        customFields: newCustomFields,
      };
    },
  },
});

export function getCustomFields(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/customField/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(customFieldsSlice.actions.setCustomFields(response.data));
      return Promise.resolve("Success");
    } catch (error) {
      dispatch(customFieldsSlice.actions.setCustomFields([]));
      return Promise.reject("Error retrieving customFields");
    }
  };
}

export function addCustomField(id, displayName, type) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const data = {
      displayName: displayName,
      type: type,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/customField/${id}/add`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(customFieldsSlice.actions.addCustomFieldToSlice(data));
      dispatch(getCustomFields(id));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export async function modifyCustomFieldValue(
  id,
  imsiList,
  customFieldId,
  value
) {
  const accessToken = window.localStorage.getItem("accessToken");
  const data = {
    imsiList: imsiList,
    customFieldId: customFieldId,
    value: value,
  };
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/customField/${id}/modify`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Error");
  }
}

export function removeCustomField(id, customFieldId) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/customField/${id}/delete/${customFieldId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(
        customFieldsSlice.actions.removeCustomFieldFromSlice(customFieldId)
      );
      dispatch(getCustomFields(id));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export async function modifyCustomField(id, customFieldId, value, imsiList) {
  const accessToken = window.localStorage.getItem("accessToken");
  const data = {
    imsiList: imsiList,
    customFieldId: customFieldId,
    value: value,
  };
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/customField/${id}/modify`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Error");
  }
}

export const {
  setCustomFields,
  addCustomFieldToSlice,
  removeCustomFieldFromSlice,
} = customFieldsSlice.actions;

export default customFieldsSlice.reducer;
