import React, { useState, useEffect, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../contexts/NotyfContext";
import { deleteEmail, getEmails } from "../../redux/slices/distribution";
import { Helmet } from "react-helmet-async";
import pos2cloud from "../../assets/img/pos2cloud.png";
import Loader from "../../components/spinners/Loader";
import getEmailsData from "./data/emailsData";
import {
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  defaultColumn,
  useFilters,
} from "react-table";
import { FaSortUp, FaSortDown, FaSort, FaPlus } from "react-icons/fa";
import {
  Container,
  Card,
  Col,
  Table,
  Form,
  Row,
  Pagination,
  Button,
  Dropdown,
  ButtonGroup,
  DropdownButton,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FiltersCard from "../../utils/FiltersCard";
import DefaultColumnFilter from "../../utils/DefaultColumnFilter";
import CreateEmailModal from "./modals/CreateEmailModal";
import UpdateEmailModal from "./modals/UpdateEmailModal";

const Emails = () => {
  const { t } = useTranslation();
  const { emailColumns } = useMemo(() => getEmailsData(t), [t]);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const distribution = useSelector((state) => state.distribution.distribution);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const dispatch = useDispatch();
  const [activeUser, setActiveUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const notyf = useContext(NotyfContext);
  const [faviconUrl, setFaviconUrl] = useState(pos2cloud);
  const [showCreateEmailModal, setShowCreateEmailModal] = useState(false);
  const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(false);
  const [emailToUpdate, setEmailToUpdate] = useState({});

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [impersonationUser, user]);

  useEffect(() => {
    const fetchEmails = async () => {
      await Promise.all([dispatch(getEmails())])
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => setIsLoading(false));
    };

    if (activeUser.id) {
      fetchEmails();
    }
  }, [activeUser]);

  useEffect(() => {
    if (whitelabel && whitelabel?.tabImage && whitelabel?.tabImage?.imagePath) {
      setFaviconUrl(
        `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${whitelabel.tabImage?.imagePath}`
      );
    }
  }, [whitelabel]);

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: emailColumns,
      data: distribution,
      initialState: { pageIndex: 0 },
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: "Actions",
          sortable: false,
          Header: t("emails.actions"),
          Cell: ({ row }) => (
            <DropdownButton
              as={ButtonGroup}
              title="Actions"
              variant="primary"
              data-boundary="viewport"
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
            >
              <Dropdown.Item
                eventKey="edit"
                onClick={() => {
                  setEmailToUpdate(row.original);
                  setShowUpdateEmailModal(true);
                }}
              >
                {t("emails.edit")}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="delete"
                onClick={async () => {
                  await dispatch(deleteEmail(row.original.id))
                    .then(() => {
                      notyf.open({
                        type: "success",
                        message: "Email deleted successfully",
                        duration: 5000,
                        ripple: true,
                        dismissible: false,
                        position: {
                          x: "center",
                          y: "top",
                        },
                      });
                    })
                    .catch((error) => {
                      notyf.open({
                        type: "danger",
                        message: error,
                        duration: 5000,
                        ripple: true,
                        dismissible: false,
                        position: {
                          x: "center",
                          y: "top",
                        },
                      });
                    });
                }}
              >
                {t("emails.delete")}
              </Dropdown.Item>
            </DropdownButton>
          ),
        },
      ]);
    }
  );

  if (isLoading) {
    return <Loader />;
  }
  return (
    <React.Fragment>
      <Helmet title="Emails">
        <link rel="icon" href={faviconUrl} />
      </Helmet>
      <Container fluid className="p-0">
        <FiltersCard headerGroups={headerGroups} />
        <Card>
          <Card.Header>
            <Row className="align-items-center mb-4">
              <Col>
                <Card.Title>Emails</Card.Title>
              </Col>
              <Col md="auto" className="ms-auto">
                <Button
                  variant="primary"
                  style={{
                    "--dynamic-bg-color":
                      whitelabel?.backgroundColor ?? "#00acdc",
                    "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                    border: "none",
                  }}
                  onClick={() => {
                    setShowCreateEmailModal(true);
                  }}
                >
                  <FaPlus className="me-2" />
                  {t("emails.newEmail")}
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Table id="emailsTable" hover responsive {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        <span {...column.getSortByToggleProps()}>
                          {column.render("Header")}
                          <span>
                            {column.sortable ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <FaSortUp className="ms-2 " />
                                ) : (
                                  <FaSortDown className="ms-2 " />
                                )
                              ) : (
                                <FaSort className="ms-2" />
                              )
                            ) : null}
                          </span>
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Row>
              <Col md="8">
                <span className="mx-2">
                  {t("table.page")}{" "}
                  <strong>
                    {pageIndex + 1} {t("table.of")} {pageOptions.length}
                  </strong>
                </span>
                <span className="ms-3 me-2">{t("table.show")}:</span>
                <Form.Select
                  className="d-inline-block w-auto"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </Form.Select>

                <span className="ms-3 me-2">{t("table.goToPage")}:</span>
                <Form.Control
                  className="d-inline-block"
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  style={{ width: "75px" }}
                />
              </Col>
              <Col md="4">
                <Pagination className="float-end">
                  <Pagination.First
                    onClick={() => {
                      gotoPage(0);
                    }}
                    disabled={!canPreviousPage}
                  />
                  <Pagination.Prev
                    onClick={() => {
                      previousPage();
                    }}
                    disabled={!canPreviousPage}
                  />
                  <Pagination.Next
                    onClick={() => {
                      nextPage();
                    }}
                    disabled={!canNextPage}
                  />
                  <Pagination.Last
                    onClick={() => {
                      gotoPage(pageCount - 1);
                    }}
                    disabled={!canNextPage}
                  />
                </Pagination>
              </Col>
            </Row>
            <CreateEmailModal
              showCreateEmailModal={showCreateEmailModal}
              setShowCreateEmailModal={setShowCreateEmailModal}
            />
            <UpdateEmailModal
              showUpdateEmailModal={showUpdateEmailModal}
              setShowUpdateEmailModal={setShowUpdateEmailModal}
              email={emailToUpdate}
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Emails;
