import React, { useEffect } from "react";
import { Form, Collapse, Row, Col, Button } from "react-bootstrap";

const NewAutomatedReportForm = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleBlur,
  handleSubmit,
  nextCollapse,
  reportTypeCollapse,
  setReportTypeCollapse,
  prevReportType,
  setPrevReportType,
  t,
  whitelabel,
  setNextCollapse,
  usageType,
  setUsageType,
  setGroupByPeriod,
  tenantsList,
  managersList,
  customersList,
  activeUser,
}) => {
  useEffect(() => {
    // Close all collapses
    setNextCollapse(null);

    // Open the relevant collapse after a delay
    setTimeout(() => {
      setNextCollapse(values.simSearchCriteriaType);
    }, 300);
  }, [values.simSearchCriteriaType]);

  useEffect(() => {
    // Check if transitioning between "invoiceDetail" and "invoiceOverview"
    const isInvoiceTransition =
      (values.reportTemplateType === "invoiceDetail" &&
        prevReportType === "invoiceOverview") ||
      (values.reportTemplateType === "invoiceOverview" &&
        prevReportType === "invoiceDetail");

    // If transitioning between "invoiceDetail" and "invoiceOverview", do not reset collapse
    if (!isInvoiceTransition) {
      setReportTypeCollapse(null);
      setTimeout(() => {
        setReportTypeCollapse(values.reportTemplateType);
      }, 400);
    }

    // Update the previous reportType value
    setPrevReportType(values.reportTemplateType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.reportTemplateType]);
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.name && errors.name}
        />
        {!!touched.name && (
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Report Frequency</Form.Label>
        <Form.Select
          name="reportFrequency"
          value={values.reportFrequency}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.reportFrequency && errors.reportFrequency}
        >
          <option value="">Select Report Frequency</option>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </Form.Select>
        {!!touched.reportFrequency && (
          <Form.Control.Feedback type="invalid">
            {errors.reportFrequency}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Report Format</Form.Label>
        <Form.Select
          name="reportFormat"
          value={values.reportFormat}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.reportFormat && errors.reportFormat}
        >
          <option value="">Select Report Format</option>
          <option value="pdf">PDF</option>
          <option value="csv">CSV</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Report Type</Form.Label>
        <Form.Select
          name="reportTemplateType"
          value={values.reportTemplateType}
          onChange={(e) => {
            setReportTypeCollapse(e.target.value);
            handleChange(e);
          }}
          onBlur={handleBlur}
          isInvalid={touched.reportTemplateType && errors.reportTemplateType}
        >
          <option value="">Select Report Type</option>
          <option value="Usage">Usage</option>
        </Form.Select>
        {!!touched.reportTemplateType && (
          <Form.Control.Feedback type="invalid">
            {errors.reportTemplateType}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Collapse in={reportTypeCollapse === "Usage"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>{t("reports.usageType")}</Form.Label>
          <Form.Select
            name="usageType"
            value={values.usageType}
            onChange={(e) => {
              setUsageType(e.target.value);
              handleChange(e);
            }}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.usageType && errors.usageType)}
          >
            <option key="" value="">
              {t("reports.selectUsageType")}
            </option>
            <option key="data" value="data">
              {t("reports.data")}
            </option>
            <option key="sms" value="sms">
              {t("reports.sms")}
            </option>
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "Usage"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>
            {t("reports.groupBy")} <small>{t("reports.field")}</small>
          </Form.Label>
          <Form.Select
            name="groupByField"
            value={values.groupByField}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.groupByField && errors.groupByField)}
          >
            <option key="all" value="all">
              {t("reports.all")}
            </option>
            <option key="imsi" value="imsi">
              {t("reports.imsi")}
            </option>
            {values.usageType === "data" && (
              <option key="network" value="network">
                {t("reports.network")}
              </option>
            )}
            {/* <option key="service" value="service">
              Service
            </option> */}
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "Usage"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>
            {t("reports.groupBy")} <small>{t("reports.period")}</small>
          </Form.Label>
          <Form.Select
            name="groupByPeriod"
            value={values.groupByPeriod}
            onChange={(e) => {
              setGroupByPeriod(e.target.value);
              handleChange(e);
            }}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.groupByPeriod && errors.groupByPeriod)}
          >
            <option key="day" value="day">
              {t("reports.day")}
            </option>
            <option key="month" value="month">
              {t("reports.month")}
            </option>
            <option key="year" value="year">
              {t("reports.year")}
            </option>
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "Usage"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>{t("reports.customer")}</Form.Label>
          <Form.Select
            name="userId"
            value={values.userId}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.customer && errors.customer)}
          >
            <option key="" value="">
              {t("reports.select")}...
            </option>
            <option key={activeUser.id} value={activeUser.id}>
              {activeUser.role === "admin" && `${activeUser.fullName} (Admin)`}
              {activeUser.role !== "admin" &&
                activeUser.role !== "subaccount" &&
                `${activeUser.fullName} (${activeUser.company})`}
            </option>
            {tenantsList?.map((tenant) => (
              <option key={tenant.id} value={tenant.id}>
                {tenant.fullName} ({tenant.company})
              </option>
            ))}
            {managersList?.map((manager) => (
              <option key={manager.id} value={manager.id}>
                {manager.fullName} ({manager.company})
              </option>
            ))}
            {customersList?.map((customer) => (
              <option key={customer.id} value={customer.id}>
                {customer.fullName} ({customer.company})
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "Usage"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>{t("reports.simSearchCriteria")}</Form.Label>
          <Form.Select
            name="simSearchCriteriaType"
            value={values.simSearchCriteriaType}
            onChange={(e) => {
              handleChange(e);
              setFieldValue("imsi", "");
              setFieldValue("startImsi", "");
              setFieldValue("endImsi", "");
              setFieldValue("iccid", "");
            }}
            onBlur={handleBlur}
            isInvalid={Boolean(
              touched.simSearchCriteriaType && errors.simSearchCriteriaType
            )}
          >
            <option key="1" value="all">
              {t("reports.all")}
            </option>
            <option key="2" value="imsi">
              {t("reports.bySpecificImsi")}
            </option>
            <option key="3" value="imsiRange">
              {t("reports.byImsiRange")}
            </option>
            <option key="4" value="iccid">
              {t("reports.byIccid")}
            </option>
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Collapse
        in={nextCollapse === "imsi" && reportTypeCollapse === "Usage"}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>{t("reports.imsi")}</Form.Label>
          <Form.Control
            type="text"
            name="imsi"
            value={values.imsi}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.imsi && errors.imsi)}
          />
        </Form.Group>
      </Collapse>
      <Collapse
        in={nextCollapse === "imsiRange" && reportTypeCollapse === "Usage"}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>{t("reports.startImsi")}</Form.Label>
          <Form.Control
            type="text"
            name="startImsi"
            value={values.startImsi}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.startImsi && errors.startImsi)}
          />
        </Form.Group>
      </Collapse>
      <Collapse
        in={nextCollapse === "imsiRange" && reportTypeCollapse === "Usage"}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>{t("reports.endImsi")}</Form.Label>
          <Form.Control
            type="text"
            name="endImsi"
            value={values.endImsi}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.endImsi && errors.endImsi)}
          />
        </Form.Group>
      </Collapse>
      <Collapse
        in={nextCollapse === "iccid" && reportTypeCollapse === "usage"}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>{t("reports.iccid")}</Form.Label>
          <Form.Control
            type="text"
            name="iccid"
            value={values.iccid}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.iccid && errors.iccid)}
          />
        </Form.Group>
      </Collapse>
      <Collapse
        in={
          reportTypeCollapse === "invoiceDetail" ||
          reportTypeCollapse === "invoiceOverview"
        }
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>Invoice Number</Form.Label>
          <Form.Control
            type="text"
            name="invoiceNumber"
            value={values.invoiceNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.invoiceNumber && errors.invoiceNumber)}
          />
        </Form.Group>
      </Collapse>
      <Row className="mb-3">
        <Col>
          <Button
            type="submit"
            variant="primary"
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              border: "none",
            }}
          >
            {t("reports.automated.save")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default NewAutomatedReportForm;
