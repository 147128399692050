import React, { useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "qrcode.react";
import NotyfContext from "../../../../contexts/NotyfContext";
import axios from "../../../../utils/axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { verifyMfa } from "../../../../redux/slices/mfa";

const TwoFactorConfigModal = ({
  showConfigure2FAAuthentication,
  setShowConfigure2FAAuthentication,
  qrCodeUrl,
}) => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();

  const initialValues = {
    mfaCode: "",
  };

  const validation = Yup.object().shape({
    mfaCode: Yup.string().required("Required"),
  });

  return (
    <Modal
      show={showConfigure2FAAuthentication}
      onHide={() => setShowConfigure2FAAuthentication(false)}
      centered
      backdrop="static"
      size="lg"
    >
      <Modal.Header>Enable Multi-Factor Authentication</Modal.Header>
      <Modal.Body>
        <p className="mb-4">
          Use the Google Authenticator app on your device. Scan the QR code (or
          manually input the shared key),enter the 6-digit code that is
          displayed below into the form.
        </p>
        <div className="text-center mb-4">
          <QRCode value={qrCodeUrl} />
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            code: Yup.string().required("Required"),
          })}
          onSubmit={async (
            values,
            { setValues, setErrors, setStatus, setSubmitting }
          ) => {
            await dispatch(verifyMfa(values.code))
              .then(() => {
                notyf.open({
                  type: "success",
                  message: "2FA enabled successfully",
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .catch((error) => {
                notyf.open({
                  type: "danger",
                  message: error,
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .finally(() => {
                setValues(initialValues);
                setShowConfigure2FAAuthentication(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setValues,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>2FA Verification Code</Form.Label>
                <Form.Control
                  type="text"
                  name="code"
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={Boolean(touched.code && errors.code)}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.code}
                </Form.Control.Feedback>
              </Form.Group>
              <Modal.Footer>
                <Button
                  type="submit"
                  style={{
                    "--dynamic-bg-color":
                      whitelabel?.backgroundColor ?? "#00acdc",
                    "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                    border: "none",
                  }}
                >
                  Verify
                </Button>
                <Button
                  style={{
                    "--dynamic-bg-color":
                      whitelabel?.backgroundColor ?? "#00acdc",
                    "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                    border: "none",
                  }}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default TwoFactorConfigModal;
