import React from "react";
import { useTranslation } from "react-i18next";
import { Badge, Col, Card, Row, Spinner } from "react-bootstrap";
import {
  getDataVolumeComparison,
  getSessionComparison,
  getZeroSessionComparison,
} from "../../redux/slices/dataSessions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import CountUp from "react-countup";
import { useRef } from "react";
import { getAlertComparison } from "../../redux/slices/alerts";

const Statistics = ({
  type,
  data_volume,
  data_volume_difference_percentage,
  data_sessions,
  data_sessions_difference_percentage,
  alerts,
  alerts_difference_percentage,
  zero_sessions,
  zero_sessions_difference_percentage,
  average_data_volume,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const comparison = useSelector((state) => state.dataSessions.comparison);
  const zeroSessionComparison = useSelector(
    (state) => state.dataSessions.zeroSessionComparison
  );
  const dataVolumeComparison = useSelector(
    (state) => state.dataSessions.dataVolumeComparison
  );
  const [alertComparison, setAlertComparison] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [isLoading, setIsLoading] = useState(false);

  const formatDataVolume = (volume) => {
    if (volume < 1000) {
      return `${volume.toFixed(2)} Bytes`;
    } else if (volume < 1000 * 1000) {
      return `${(volume / 1000).toFixed(2)} KB`;
    } else if (volume < 1000 * 1000 * 1000) {
      return `${(volume / (1000 * 1000)).toFixed(2)} MB`;
    } else {
      return `${(volume / (1000 * 1000 * 1000)).toFixed(2)} GB`;
    }
  };

  return (
    <div className="w-100">
      <Row>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill" style={{ height: "160px" }}>
            {!isLoading ? (
              <Card.Body>
                <Row>
                  <Col className="mt-0">
                    <h5 className="card-title">{t("dashboard.dataVolume")}</h5>
                  </Col>
                </Row>
                <span className="h1 d-inline-block mt-3 mb-4">
                  <CountUp
                    start={0}
                    end={data_volume}
                    duration={2.75}
                    decimals={2}
                    redraw={true}
                    formattingFn={formatDataVolume}
                  ></CountUp>
                </span>
                <div className="mb-0">
                  <Badge
                    bg=""
                    className={
                      data_volume_difference_percentage > 0
                        ? "badge-soft-success me-2"
                        : "badge-soft-danger me-2"
                    }
                  >
                    {parseFloat(data_volume_difference_percentage).toFixed(2)}%
                  </Badge>
                  {type === "day" && (
                    <span className="text-muted">
                      {t("dashboard.sinceYesterday")}
                    </span>
                  )}
                  {type === "month" && (
                    <span className="text-muted">
                      {t("dashboard.sinceLastMonth")}
                    </span>
                  )}
                  {type === "year" && (
                    <span className="text-muted">
                      {t("dashboard.sinceLastYear")}
                    </span>
                  )}
                </div>
              </Card.Body>
            ) : (
              <Card.Body>
                <Spinner animation="border" variant="primary" />
              </Card.Body>
            )}
          </Card>
        </Col>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill" style={{ height: "160px" }}>
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("dashboard.dataSessions")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                {isLoading ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <CountUp
                    start={0}
                    end={data_sessions}
                    duration={2.75}
                    redraw
                  />
                )}
              </span>
              <div className="mb-0">
                <Badge
                  bg=""
                  className={
                    data_sessions_difference_percentage > 0
                      ? "badge-soft-success me-2"
                      : "badge-soft-danger me-2"
                  }
                >
                  {parseFloat(data_sessions_difference_percentage).toFixed(2)}%
                </Badge>
                {type === "day" && (
                  <span className="text-muted">
                    {t("dashboard.sinceYesterday")}
                  </span>
                )}
                {type === "month" && (
                  <span className="text-muted">
                    {t("dashboard.sinceLastMonth")}
                  </span>
                )}
                {type === "year" && (
                  <span className="text-muted">
                    {t("dashboard.sinceLastYear")}
                  </span>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill" style={{ height: "160px" }}>
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("dashboard.alerts")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                {isLoading ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <CountUp start={0} end={alerts} duration={2.75} />
                )}
              </span>
              <div className="mb-0">
                <Badge
                  bg=""
                  className={
                    alerts_difference_percentage > 0
                      ? "badge-soft-success me-2"
                      : "badge-soft-danger me-2"
                  }
                >
                  {parseFloat(alerts_difference_percentage).toFixed(2)}%
                </Badge>
                {type === "day" && (
                  <span className="text-muted">
                    {t("dashboard.sinceYesterday")}
                  </span>
                )}
                {type === "month" && (
                  <span className="text-muted">
                    {t("dashboard.sinceLastMonth")}
                  </span>
                )}
                {type === "year" && (
                  <span className="text-muted">
                    {t("dashboard.sinceLastYear")}
                  </span>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill" style={{ height: "160px" }}>
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("dashboard.zeroSessions")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                <CountUp start={0} end={zero_sessions} duration={2.75} redraw />
              </span>
              <div className="mb-0">
                <Badge
                  bg=""
                  className={
                    zero_sessions_difference_percentage > 0
                      ? "badge-soft-success me-2"
                      : "badge-soft-danger me-2"
                  }
                >
                  {parseFloat(zero_sessions_difference_percentage).toFixed(2)}%
                </Badge>
                {type === "day" && (
                  <span className="text-muted">
                    {t("dashboard.sinceYesterday")}
                  </span>
                )}
                {type === "month" && (
                  <span className="text-muted">
                    {t("dashboard.sinceLastMonth")}
                  </span>
                )}
                {type === "year" && (
                  <span className="text-muted">
                    {t("dashboard.sinceLastYear")}
                  </span>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Statistics;
