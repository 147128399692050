import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";
import { updateTenant } from "./tenants";
import { updateManager } from "./managers";

const initialState = {
  whitelabel: [],
};

const whitelabelSlice = createSlice({
  name: "whitelabelSlice",
  initialState,
  reducers: {
    setWhitelabel(state, action) {
      state.whitelabel = action.payload;
    },
  },
});

export function fetchWhitelabel(url) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/serverdetails/byurl?url=${url}`
      );
      dispatch(whitelabelSlice.actions.setWhitelabel(response.data.data));
      return Promise.resolve("Success");
    } catch (error) {
      dispatch(whitelabelSlice.actions.setWhitelabel([]));
      return Promise.reject("Error");
    }
  };
}

export function getWhitelabelDetails(id) {
  return async (dispatch) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/serverdetails/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(whitelabelSlice.actions.setWhitelabel(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      console.error(error.message);
      return Promise.reject(error.message);
    }
  };
}

export function enableWhitelabeling({ id, price, url, role }) {
  return async (dispatch) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const requestData = {
        id: id,
        price: price,
        url: url,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/serverdetails`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (role === "TENANT") {
        dispatch(updateTenant({ id, tenantDetails: response.data.data }));
      } else if (role === "MANAGER") {
        dispatch(updateManager({ id, managerDetails: response.data.data }));
      }
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function updateWhitelabelDetails({ data }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const formData = new FormData();
      formData.append("backgroundColor", data.backgroundColor);
      formData.append("fontColor", data.fontColor);
      formData.append("sidebarPosition", data.sidebarPosition);
      if (data.favicon) {
        formData.append("favicon", data.favicon);
      }
      if (data.whitelabelImage) {
        formData.append("whitelabelImage", data.whitelabelImage);
      }
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/serverdetails`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(whitelabelSlice.actions.setWhitelabel(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export const { setWhitelabel } = whitelabelSlice.actions;

export default whitelabelSlice.reducer;
