import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../../../contexts/NotyfContext";
import {
  deleteReportFromHistory,
  getUserReports,
} from "../../../../redux/slices/reports";
import {
  useTable,
  useRowSelect,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from "react-table";
import { useTranslation } from "react-i18next";
import getReportsHistoryData from "./reportsHistoryData";
import {
  ButtonGroup,
  Card,
  Dropdown,
  DropdownButton,
  Table,
} from "react-bootstrap";
import { FaSort, FaSortDown, FaSortUp, FaTrash } from "react-icons/fa";
import DefaultColumnFilter from "../../../../utils/DefaultColumnFilter";
import Loader from "../../../../components/spinners/Loader";

const ReportsHistory = () => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const reportHistory = useSelector((state) => state.reports.reportHistory);
  const [activeUser, setActiveUser] = useState({});
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const { reportsHistoryColumns } = useMemo(
    () => getReportsHistoryData(t),
    [t]
  );

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchReportsHistory = async () => {
      await dispatch(getUserReports())
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "right",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
    };

    if (activeUser.id) {
      fetchReportsHistory();
    }
  }, [activeUser]);

  const columns = useMemo(() => {
    return [
      ...reportsHistoryColumns,
      {
        id: "actions",
        Header: t("reports.history.tableHeaders.actions"),
        Cell: ({ row }) => {
          return (
            <DropdownButton
              data-boundary="viewport"
              as={ButtonGroup}
              className="actions-dropdown"
              title={t("reports.history.tableHeaders.actions")}
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
            >
              <Dropdown.Item
                onClick={async () => {
                  await dispatch(
                    deleteReportFromHistory({ id: row.original.id })
                  )
                    .then(() => {
                      notyf.open({
                        type: "success",
                        message: "Report deleted successfully",
                        duration: 5000,
                        ripple: true,
                        dismissible: false,
                        position: { x: "center", y: "top" },
                      });
                    })
                    .catch((error) => {
                      notyf.open({
                        type: "danger",
                        message: error,
                        duration: 5000,
                        ripple: true,
                        dismissible: false,
                        position: { x: "center", y: "top" },
                      });
                    });
                }}
              >
                <FaTrash size={18} />
                {t("reports.history.delete")}
              </Dropdown.Item>
            </DropdownButton>
          );
        },
      },
    ];
  }, [t, whitelabel]);

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: reportHistory,
      initialState: { pageIndex: 0 },
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  if (isLoading) {
    return (
      <Card>
        <Card.Body>
          <Loader />
        </Card.Body>
      </Card>
    );
  }
  return (
    <Card>
      <Card.Header>
        <Card.Title>{t("reports.history.title")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Table id="clientsTable" hover responsive {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      <span>
                        {column.sortable ? (
                          column.isSorted ? (
                            column.isSortedDesc ? (
                              <FaSortUp className="ms-2 " />
                            ) : (
                              <FaSortDown className="ms-2 " />
                            )
                          ) : (
                            <FaSort className="ms-2" />
                          )
                        ) : null}
                      </span>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ReportsHistory;
