import SelectColumnFilter from "../utils/SelectColumnFilter";

const getAlertData = (t) => {
  const alertColumns = [
    {
      Header: "ID",
      accessor: (row, i) => i + 1,
      sortable: true,
      Filter: () => null,
      canRenderFilter: false,
    },
    {
      Header: t("alerts.alert"),
      accessor: (row) => `${row.context} ${row.action}`,
      sortable: true,
      Filter: SelectColumnFilter,
      filter: "includes",
      canRenderFilter: true,
      Cell: ({ row: { original } }) => (
        <>
          {original.context} {original.action}
        </>
      ),
    },
    {
      Header: t("alerts.date"),
      accessor: "alertDate",
      sortable: true,
      canRenderFilter: true,
      Cell: ({ row: { original } }) => (
        <>{original.alertDate.replace("T", " ")}</>
      ),
    },
    {
      Header: t("alerts.imsi"),
      accessor: "imsi",
      sortable: true,
      canRenderFilter: true,
    },
  ];

  return { alertColumns };
};

export default getAlertData;
