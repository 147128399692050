import React, { useEffect, useState } from "react";

import GoogleMapReact from "google-map-react";

import { RiErrorWarningFill } from "react-icons/ri";

const SimsMap = (props) => {
  const [lat, setLat] = useState();
  const [lon, setLon] = useState();
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    setLat(parseFloat(props.lat));
    setLon(parseFloat(props.lon));
  }, [props.lat, props.lon]);

  const renderMarkers = (map, maps) => {
    if (marker) {
      marker.setMap(null);
    }
    if (map && maps && lat && lon) {
      const newMarker = new maps.Marker({
        position: {
          lat: parseFloat(lat),
          lng: parseFloat(lon),
        },
        map,
      });
      setMarker(newMarker);

      map.addListener("zoom_changed", () => {
        const zoomLevel = map.getZoom();
        const scale = Math.pow(2, zoomLevel);

        // If using the default marker, assume its size is 20x32 pixels
        const defaultMarkerSize = { width: 20, height: 32 };
        const offsetX = 0.5 * defaultMarkerSize.width;
        const offsetY = defaultMarkerSize.height;

        const projection = map.getProjection();
        const latlng = marker.getPosition();
        const point = projection.fromLatLngToPoint(latlng);
        const newPoint = new maps.Point(
          (point.x * scale - offsetX) / scale,
          (point.y * scale - offsetY) / scale
        );
        const newLatLng = projection.fromPointToLatLng(newPoint);
        marker.setPosition(newLatLng);
      });
    }
  };

  useEffect(() => {
    if (lat && lon) {
      renderMarkers(map, maps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lon, map, maps]);

  const getMapOptions = (maps) => {
    return {
      fullscreenControl: false,
      mapTypeControl: false,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: false,
      scrollwheel: false,
      streetViewControl: false,
    };
  };

  const WarningMessage = () => (
    <div
      style={{
        position: "relative",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "#e8eaed",
        padding: "10px",
        borderRadius: "5px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div>
        <RiErrorWarningFill size={70} />
      </div>
      <h1 style={{ marginTop: "20px" }}>Something went wrong</h1>
      <p>No location available</p>
    </div>
  );

  if (lat && lon) {
    return (
      <div style={{ height: 300, width: "100%" }} className="my-3">
        <GoogleMapReact
          options={getMapOptions}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_POS2CLOUD_GOOGLE_MAPS_KEY,
          }}
          defaultCenter={{
            lat: 40.712784,
            lng: -74.005941,
          }}
          center={{
            lat: lat,
            lng: lon,
          }}
          defaultZoom={14}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            setMap(map);
            setMaps(maps);
          }}
        ></GoogleMapReact>
      </div>
    );
  } else {
    return (
      <div style={{ height: 300, width: "100%" }} className="my-3">
        <WarningMessage />
      </div>
    );
  }
};

export default SimsMap;
