const getBillingProductsColumns = (t) => {
  const billingProductsColumns = [
    {
      Header: t("billingProducts.name"),
      accessor: "name",
      sortable: true,
    },
    {
      Header: t("billingProducts.pricePlan"),
      accessor: "pricePlan",
      sortable: true,
    },
    {
      Header: t("billingProducts.price"),
      accessor: "price",
      sortable: true,
      Cell: ({ row: { original } }) => {
        const scale = Math.pow(10, -original.scale);
        const calculatedPrice = original.price * scale;
        return (
          <>
            {calculatedPrice.toFixed(2)}€/{original.pricePeriod}
          </>
        );
      },
    },
    {
      Header: t("billingProducts.number"),
      accessor: "amount",
      sortable: true,
    },
  ];

  return { billingProductsColumns };
};

export default getBillingProductsColumns;
