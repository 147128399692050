import SelectColumnFilter from "../../../utils/SelectColumnFilter";

const getEmailsData = (t) => {
  const emailColumns = [
    {
      Header: "ID",
      accessor: "id",
      sortable: true,
      canRenderFilter: false,
    },
    {
      Header: t("emails.category"),
      accessor: "category",
      sortable: true,
      canRenderFilter: true,
      Filter: SelectColumnFilter,
    },
    {
      Header: t("emails.name"),
      accessor: "name",
      sortable: true,
      canRenderFilter: true,
    },
    {
      Header: t("emails.address"),
      accessor: "address",
      sortable: true,
      canRenderFilter: true,
    },
  ];

  return { emailColumns };
};

export default getEmailsData;
