import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../../contexts/NotyfContext";
import {
  getInvoicePreferences,
  updateInvoicePreferences,
} from "../../../redux/slices/preferences";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import Loader from "../../../components/Loader";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

const InvoiceSettings = ({ activeTab }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const invoicePreferences = useSelector(
    (state) => state.preferences.invoicePreferences
  );
  const [activeUser, setActiveUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchInvoiceConfiguration = async () => {
      setIsLoading(true);
      await dispatch(getInvoicePreferences())
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
    };

    if (activeUser.id && activeTab === "#Invoice") {
      fetchInvoiceConfiguration();
    }
  }, [activeUser, activeTab]);

  const initialValues = {
    name:
      invoicePreferences && invoicePreferences.name
        ? invoicePreferences.name
        : "",
    showGuis:
      invoicePreferences && invoicePreferences.showGuis
        ? invoicePreferences.showGuis
        : false,
    showSubtotal:
      invoicePreferences && invoicePreferences.showSubtotal
        ? invoicePreferences.showSubtotal
        : false,
    showLineOfService:
      invoicePreferences && invoicePreferences.showLineOfService
        ? invoicePreferences.showLineOfService
        : false,
  };

  const validation = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    showGuis: Yup.boolean(),
    showSubtotal: Yup.boolean(),
    showLineOfService: Yup.boolean(),
  });

  return (
    <Card>
      <Card.Header>
        <Card.Title>{t("settings.invoiceSettings.title")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && <Loader />}
        {!isLoading && (
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, { setValues }) => {
              await dispatch(updateInvoicePreferences({ data: values }))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Invoice preferences updated successfully",
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("settings.invoiceSettings.name")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.name && errors.name}
                    />
                    {touched.name && (
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("settings.invoiceSettings.showGuis")}
                    </Form.Label>
                    <Form.Check
                      name="showGuis"
                      type="switch"
                      checked={values.showGuis}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("settings.invoiceSettings.showSubtotal")}
                    </Form.Label>
                    <Form.Check
                      name="showSubtotal"
                      type="switch"
                      checked={values.showSubtotal}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("settings.invoiceSettings.showLineOfService")}
                    </Form.Label>
                    <Form.Check
                      name="showLineOfService"
                      type="switch"
                      checked={values.showLineOfService}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                      }}
                    />
                  </Form.Group>
                  <Row>
                    <Col md="auto" className="ms-auto">
                      <Button
                        type="submit"
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                        }}
                      >
                        {t("settings.invoiceSettings.save")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        )}
      </Card.Body>
    </Card>
  );
};

export default InvoiceSettings;
