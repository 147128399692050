import React, { useState, useContext, useEffect } from "react";
import { Card, Form, Row, Button } from "react-bootstrap";
import FilterGroup from "./FilterGroup";
import { getSims, getSimInventoryCount } from "../../../redux/slices/sims";
import { useSelector, useDispatch } from "react-redux";
import NotyfContext from "../../../contexts/NotyfContext";
import { useTranslation } from "react-i18next";

const FiltersCard = ({
  headerGroups,
  filters,
  setFilters,
  page,
  pageSize,
  setIsLoading,
  customFields,
}) => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  const handleSearch = async () => {
    setIsLoading(true);
    if (activeUser.id) {
      await Promise.all([
        dispatch(getSims({ page: page + 1, pageSize, filters })),
      ])
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleClear = async () => {
    const newFilters = {};
    setFilters(newFilters);
    setIsLoading(true);
    if (activeUser.id) {
      await Promise.all([
        dispatch(getSims({ page: page + 1, pageSize, filters: newFilters })),
      ])
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>{t("sims.filters")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form>
          <Row>
            {headerGroups.map((headerGroup, idx) =>
              headerGroup.headers.map((column, index) => (
                <FilterGroup
                  key={index}
                  column={column}
                  filters={filters}
                  setFilters={setFilters}
                  page={page}
                  pageSize={pageSize}
                />
              ))
            )}
          </Row>
          <div className="d-flex mt-2 justify-content-end">
            <Button
              className="me-3"
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
              onClick={handleSearch}
            >
              {t("sims.search")}
            </Button>
            <Button
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
              onClick={handleClear}
            >
              {t("sims.clear")}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default FiltersCard;
