import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";
import { updateSubaccount } from "./subaccounts";

const initialState = {
  privileges: [],
};

const privilegesSlice = createSlice({
  name: "privileges",
  initialState,
  reducers: {
    setPrivileges(state, action) {
      state.privileges = action.payload;
    },
    addPrivilege(state, action) {
      const newPrivilege = action.payload;
      return {
        ...state,
        privileges: [...state.privileges, newPrivilege],
      };
    },
    editPrivilege(state, action) {
      const { id, updatedPrivilege } = action.payload;
      const updatedPrivileges = state.privileges.map((privilege) => {
        if (privilege.id === id) {
          return updatedPrivilege;
        }
        return privilege;
      });
      const updatedState = {
        ...state,
        privileges: updatedPrivileges,
      };
      return updatedState;
    },
    removePrivilege(state, action) {
      const idToRemove = action.payload;
      const newPrivileges = state.privileges.filter(
        (privilege) => privilege.id !== idToRemove
      );
      return {
        ...state,
        privileges: newPrivileges,
      };
    },
  },
});

export function fetchAllPrivileges() {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/privileges/all`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(privilegesSlice.actions.setPrivileges(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function createPrivilege({ data }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/privileges`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(privilegesSlice.actions.addPrivilege(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function updatePrivilege({ id, data }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/privileges/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(
        privilegesSlice.actions.editPrivilege({
          id: id,
          updatedPrivilege: response.data.data,
        })
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function deletePrivilege(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/privileges/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(privilegesSlice.actions.removePrivilege(id));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function updateSubaccountPrivileges({ id, data }) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/privileges/subaccount/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(updateSubaccount({ id, subaccountDetails: response.data.data }));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export default privilegesSlice.reducer;
