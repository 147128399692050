import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sms: [],
  outboundSms: [],
};

const smsSlice = createSlice({
  name: "sms",
  initialState,
  reducers: {
    setSms(state, action) {
      state.sms = action.payload;
    },
    setOutboundSms(state, action) {
      state.outboundSms = action.payload;
    },
  },
});

export function getSimSms({ imsi }) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sms/${imsi}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(smsSlice.actions.setSms(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getSimOutboundSms({ imsi }) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sms/outbound/${imsi}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(smsSlice.actions.setOutboundSms(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function sendSms({
  imsiList,
  fromType,
  from,
  messageType,
  textContent,
  base64Content,
}) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const data = {
        imsiList: imsiList,
        fromType: fromType,
        from: from,
        messageType: messageType,
        textContent: textContent,
        base64Content: base64Content,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sms`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export default smsSlice.reducer;
