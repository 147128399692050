import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offers: [],
  availableOffers: [],
  assignedOffers: [],
};

const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    setOffers(state, action) {
      state.offers = action.payload;
    },
    setAvailableOffers(state, action) {
      state.availableOffers = action.payload;
    },
    setAssignedOffers(state, action) {
      state.assignedOffers = action.payload;
    },
  },
});

export function getOffers(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/offers/${id}/distribution`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(offersSlice.actions.setOffers(response.data));
      return Promise.resolve("Success");
    } catch (error) {
      dispatch(offersSlice.actions.setOffers([]));
      return Promise.reject("Error");
    }
  };
}

export function getAvailableOffers() {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/offers/catalog`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(offersSlice.actions.setAvailableOffers(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      dispatch(offersSlice.actions.setAvailableOffers([]));
      return Promise.reject(error.message);
    }
  };
}

export function getAssignedOffersForUser(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/offers/catalog/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(offersSlice.actions.setAssignedOffers(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      dispatch(offersSlice.actions.setAssignedOffers([]));
      return Promise.reject(error.message);
    }
  };
}

export function updateOffers({ id, data }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/offers/${id}/update`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export const { setOffers, setAvailableOffers } = offersSlice.actions;
export default offersSlice.reducer;
