import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Card, Row } from "react-bootstrap";
import { useState } from "react";
import { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../../../contexts/NotyfContext";
import {
  getDataVolumeComparisonForSim,
  getSessionComparisonForSim,
  getZeroSessionComparisonForSim,
} from "../../../../redux/slices/dataSessions";
import CountUp from "react-countup";
import { getAlertCountForSim } from "../../../../redux/slices/alerts";
import { getSimStatistics } from "../../../../redux/slices/sims";

const SimStatistics = ({ sim, period }) => {
  const { t } = useTranslation();
  const [dataVolume, setDataVolume] = useState([]);
  const [dataSessions, setDataSessions] = useState([]);
  const [zeroSessions, setZeroSessions] = useState([]);
  const [alerts, setAlerts] = useState();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const {
    sim_data_session_count,
    sim_data_session_difference_percentage,
    sim_data_volume,
    sim_data_volume_difference_percentage,
    sim_zero_session_count,
    sim_zero_session_difference_percentage,
    sim_alerts_count,
    sim_alerts_difference_percentage,
  } = useSelector((state) => state.sims);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchSimStatistics = async () => {
      await Promise.all([
        dispatch(getSimStatistics({ id: sim.id, period: period })),
      ]).catch((error) => {
        notyf.open({
          type: "danger",
          message: error,
          duration: 5000,
          dismissible: false,
          ripple: true,
          position: {
            x: "center",
            y: "top",
          },
        });
      });
    };

    if (activeUser.id && sim.id) {
      fetchSimStatistics();
    }
  }, [activeUser, sim, period]);

  return (
    <div className="w-100">
      <Row>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("simProfile.dataVolume")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                <CountUp
                  start={0}
                  end={sim_data_volume / 1024.0 / 1024.0}
                  duration={2.75}
                  decimals={2}
                />
                MB
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("simProfile.dataSessions")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                <CountUp
                  start={0}
                  end={sim_data_session_count}
                  duration={2.75}
                />
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("simProfile.alerts")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                <CountUp start={0} end={sim_alerts_count} duration={2.75} />
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="12" className="d-flex col-xxl-6">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mt-0">
                  <h5 className="card-title">{t("simProfile.zeroSessions")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-3 mb-4">
                <CountUp
                  start={0}
                  end={sim_zero_session_count}
                  duration={2.75}
                />
              </span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SimStatistics;
