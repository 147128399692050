import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Image, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import SignIn from "../../components/auth/SignIn";
import pos2cloud from "../../assets/img/pos2cloud.png";
import { fetchWhitelabel } from "../../redux/slices/whitelabel";

const SignInPage = () => {
  const dispatch = useDispatch();
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const currentUrl = window.location.hostname;
  const [faviconUrl, setFaviconUrl] = useState(pos2cloud);
  const [whitelabelImage, setWhitelabelImage] = useState(pos2cloud);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    dispatch(fetchWhitelabel(currentUrl)).finally(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    });
  }, []);

  useEffect(() => {
    setFaviconUrl(
      whitelabel?.tabImage && whitelabel?.tabImage?.imagePath
        ? `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${whitelabel?.tabImage?.imagePath}`
        : pos2cloud
    );
  }, [whitelabel]);

  useEffect(() => {
    setWhitelabelImage(
      whitelabel?.whitelabelImage && whitelabel?.whitelabelImage?.imagePath
        ? `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${whitelabel?.whitelabelImage?.imagePath}`
        : pos2cloud
    );
  }, [whitelabel]);

  if (isLoading) {
    return (
      <React.Fragment>
        <Helmet title="Sign In">
          <link rel="icon" href={faviconUrl} />
        </Helmet>
        <Card style={{ minHeight: "490px" }}>
          <Card.Body className="d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex justify-content-center align-items-center h-100 w-100">
              <Spinner
                animation="border"
                variant="primary"
                role="status"
                style={{ "--dynamic-spinner-color": "#00acdc" }}
              />
            </div>
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Helmet title="Sign In">
        <link rel="icon" href={faviconUrl} />
      </Helmet>
      <Card style={{ minHeight: "490px" }}>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center">
              <Image
                src={whitelabelImage}
                alt=""
                className="img-fluid"
                width="150"
                height="150"
              />
            </div>
            <SignIn />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SignInPage;
