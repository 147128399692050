import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invoices: [],
};

const invoiceSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setInvoices(state, action) {
      state.invoices = action.payload;
    },
  },
});

export function getInvoices() {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/invoice`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(invoiceSlice.actions.setInvoices(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export async function getInvoice(id, downloadUrl) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/invoice/${id}/getInvoice`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          url: downloadUrl,
        },
        responseType: "arraybuffer",
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Error");
  }
}

export const { setInvoices } = invoiceSlice.actions;
export default invoiceSlice.reducer;
