import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  watchdogs: [],
  simWatchdogs: [],
};

const watchdogSlice = createSlice({
  name: "watchdogSlice",
  initialState,
  reducers: {
    setWatchdogs(state, action) {
      state.watchdogs = action.payload;
    },
    setSimWatchdogs(state, action) {
      state.simWatchdogs = action.payload;
    },
  },
});

export function initiliazeWatchdogs(imsiList) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token found");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/watchdog/init`,
        imsiList,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getSimWatchdogs(id, imsi) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/watchdog/${id}/getSimWatchdogs?imsi=${imsi}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(watchdogSlice.actions.setSimWatchdogs(response.data));
      return Promise.resolve("Success");
    } catch (error) {
      dispatch(watchdogSlice.actions.setSimWatchdogs([]));
      return Promise.reject("Error");
    }
  };
}

export function createWatchdog({
  imsiList,
  actionType,
  amount,
  serviceType,
  service,
  suspendInterval,
  unitType,
  warning,
  warning1,
  warning2,
  watchdogInterval,
}) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token found");
    try {
      const data = {
        imsiList: imsiList,
        actionType: actionType,
        amount: amount,
        serviceType: serviceType,
        suspendInterval: suspendInterval,
        unitType: unitType,
        warning: warning ? warning : 0,
        warning1: warning1 ? warning1 : 0,
        warning2: warning2 ? warning2 : 0,
        watchdogInterval: watchdogInterval,
      };
      if (serviceType === "data") {
        data.service = service.split(",").map(Number);
      } else if (serviceType === "sms") {
        data.service = "sms";
      }
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/watchdog`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function removeAllSimWatchdogs({ imsiList }) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token found");
    try {
      const data = {
        imsiList: imsiList,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/watchdog/remove`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export async function modifySimWatchdog(
  id,
  sim,
  watchdogId,
  actionType,
  amount,
  notificationType,
  service,
  suspendInterval,
  unitType,
  warning,
  warning1,
  warning2,
  watchDogInterval,
  watchDogType,
  distributionList
) {
  const accessToken = window.localStorage.getItem("accessToken");
  const data = {
    imsi: sim,
    id: watchdogId,
    actionType: actionType,
    amount: amount,
    emailList: distributionList,
    notificationType: notificationType,
    service: service,
    suspendInterval: suspendInterval,
    unitType: unitType,
    warning: warning,
    warning1: warning1,
    warning2: warning2,
    watchDogInterval: watchDogInterval,
    watchDogType: watchDogType,
  };
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/watchdog/${id}/modifySimWatchdog`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Error");
  }
}

export async function removeSimWatchdog(id, watchdogId, imsi) {
  const accessToken = window.localStorage.getItem("accessToken");
  const data = {
    watchdogId: watchdogId,
    imsi: imsi,
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/watchdog/${id}/removeSimWatchdog`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Error");
  }
}

export const { setSimWatchdogs } = watchdogSlice.actions;

export default watchdogSlice.reducer;
