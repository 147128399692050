import { Modal, Table, Button, Spinner, Form } from "react-bootstrap";
import React, { useState, useEffect, useContext } from "react";
import {
  getSimCellInfo,
  updateSimLocation,
} from "../../../../redux/slices/sims";
import { useDispatch, useSelector } from "react-redux";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import { Formik } from "formik";
import * as Yup from "yup";
import Loader from "../../../../components/spinners/Loader";
import NotyfContext from "../../../../contexts/NotyfContext";

const UpdateLocationModal = ({
  showUpdateLocationModal,
  setShowUpdateLocationModal,
  sim,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const handleClose = () => {
    setCurrentStep(1);
    setShowUpdateLocationModal(false);
  };
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  const initialValue = {
    simList: [sim],
  };

  const validation = Yup.object().shape({
    simList: Yup.array().required("Required"),
  });

  return (
    <Modal
      show={showUpdateLocationModal}
      onHide={handleClose}
      centered
      backdrop="static"
      size="lg"
      scrollable
    >
      <Modal.Header>
        <Modal.Title>Update SIM Location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValue}
          validationSchema={validation}
          onSubmit={async (values, { setValues, setStatus }) => {
            const imsiList = values.simList.map((sim) => sim.imsi);
            await dispatch(updateSimLocation(imsiList))
              .then(() => {
                notyf.open({
                  type: "success",
                  message: "SIM location updated successfully.",
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
                setStatus("Success");
              })
              .catch((error) => {
                notyf.open({
                  type: "danger",
                  message: error,
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
                setStatus("Error");
              })
              .finally(() => {
                setCurrentStep(3);
              });
          }}
        >
          {({
            values,
            handleSubmit,
            validateForm,
            setFieldTouched,
            status,
          }) => {
            const nextStep = () => {
              validateForm().then((errors) => {
                if (Object.keys(errors).length === 0) {
                  if (currentStep === 1) {
                    setCurrentStep(2);
                    handleSubmit();
                  }
                } else {
                  Object.keys(values).forEach((field) => {
                    setFieldTouched(field);
                  });
                }
              });
            };
            return (
              <Form onSubmit={handleSubmit}>
                {currentStep === 1 && (
                  <Form.Group className="mb-3">
                    <Table responsive hover>
                      <thead>
                        <tr>
                          <th>IMSI</th>
                          <th>ICCID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.simList.map((sim, index) => (
                          <tr key={index}>
                            <td>{sim.imsi}</td>
                            <td>{sim.iccid}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Form.Group>
                )}
                {currentStep === 2 && <Loader />}
                {currentStep === 3 && status === "Success" && (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <FaCheckCircle size={120} color="#4BBF73" />
                    <h3>Success</h3>
                  </div>
                )}
                {currentStep === 3 && status === "Error" && (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <FaExclamationCircle size={120} color="#FF0000" />
                    <h3 className="mt-3">Order Error</h3>
                    <p>Please contact the administrator.</p>
                  </div>
                )}
                <Modal.Footer>
                  {currentStep === 1 && (
                    <React.Fragment>
                      <Button
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                        }}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#ffffff",
                          border: "none",
                        }}
                        onClick={nextStep}
                      >
                        Submit
                      </Button>
                    </React.Fragment>
                  )}
                  {currentStep === 3 && (
                    <Button
                      variant="primary"
                      style={{
                        "--dynamic-bg-color":
                          whitelabel?.backgroundColor ?? "#00acdc",
                        "--dynamic-font-color":
                          whitelabel?.fontColor ?? "#ffffff",
                        border: "none",
                      }}
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  )}
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateLocationModal;
