import React from "react";
import { Helmet } from "react-helmet-async";
import Dashboard from "../../../components/dashboard/dashboard";
import AuthGuard from "../../../components/guards/AuthGuard";

const MainDashboard = () => (
  <React.Fragment>
    <AuthGuard>
      <Helmet title="" />
      <Dashboard />
    </AuthGuard>
  </React.Fragment>
);

export default MainDashboard;
