import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Form, Button, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationPreferences,
  updateNotificationPreferences,
} from "../../../redux/slices/preferences";
import NotyfContext from "../../../contexts/NotyfContext";
import * as Yup from "yup";
import { Formik } from "formik";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";

const Notifications = ({ activeTab }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const notificationPreferences = useSelector(
    (state) => state.preferences.notificationPreferences
  );
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchNotificationPreferences = async () => {
      setIsLoading(true);
      await dispatch(getNotificationPreferences())
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
    };

    if (activeUser.id && activeTab === "#Notifications") {
      fetchNotificationPreferences();
    }
  }, [activeUser, activeTab]);

  const initialValues = {
    email_enabled: notificationPreferences.emailEnabled,
    sms_enabled: notificationPreferences.smsEnabled,
    weekly_analytics: notificationPreferences.weeklyAnalytics,
    new_alerts: notificationPreferences.newAlerts,
  };

  const validation = Yup.object().shape({
    email_enabled: Yup.boolean(),
    sms_enabled: Yup.boolean(),
    weekly_analytics: Yup.boolean(),
    new_alerts: Yup.boolean(),
  });

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">
          {t("settings.notificationSettings.title")}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && <Loader />}
        {!isLoading && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, { setValues, setSubmitting }) => {
              await dispatch(updateNotificationPreferences({ data: values }))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Successfully updated notification preferences",
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2} className="text-sm-right">
                      {t("settings.notificationSettings.receiveNotifications")}:
                    </Form.Label>
                    <Col sm={10}>
                      <div className="custom-controls-stacked">
                        <Form.Check
                          type="checkbox"
                          name="smsEnabled"
                          checked={values.sms_enabled}
                          onChange={(e) =>
                            setFieldValue("sms_enabled", e.target.checked)
                          }
                          onBlur={handleBlur}
                          label="SMS"
                          className="mb-2"
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#fff",
                            border: "none",
                          }}
                        />
                        <Form.Check
                          type="checkbox"
                          name="email_enabled"
                          checked={values.email_enabled}
                          onChange={(e) =>
                            setFieldValue("email_enabled", e.target.checked)
                          }
                          onBlur={handleBlur}
                          label="E-mail"
                          className="mb-2"
                          style={{
                            "--dynamic-bg-color":
                              whitelabel?.backgroundColor ?? "#00acdc",
                            "--dynamic-font-color":
                              whitelabel?.fontColor ?? "#fff",
                            border: "none",
                          }}
                        />
                      </div>
                    </Col>
                  </Form.Group>
                  <Form.Label
                    htmlFor="#weeklyAnalytics"
                    className="font-weight-bold"
                  >
                    {t("settings.notificationSettings.weeklyAnalytics")}
                  </Form.Label>
                  <Form.Group as={Row} className="mb-3 ml-3">
                    <Col sm={10}>
                      <Form.Check
                        type="switch"
                        name="weekly_analytics"
                        checked={values.weekly_analytics}
                        onChange={(e) =>
                          setFieldValue("weekly_analytics", e.target.checked)
                        }
                        onBlur={handleBlur}
                        label={t(
                          "settings.notificationSettings.checkIfYouWantToReceiveWeeklyAnalytics"
                        )}
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#fff",
                          border: "none",
                        }}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Label
                    htmlFor="#notifyAlerts"
                    className="font-weight-bold"
                  >
                    {t("settings.notificationSettings.notifyAboutNewAlerts")}
                  </Form.Label>
                  <Form.Group as={Row} className="mb-3 ml-3">
                    <Col sm={10}>
                      <Form.Check
                        type="switch"
                        name="new_alerts"
                        label={t(
                          "settings.notificationSettings.checkIfYouWantToReceiveNotificationsWhenThereAreNewAlerts"
                        )}
                        checked={values.new_alerts}
                        onChange={(e) =>
                          setFieldValue("new_alerts", e.target.checked)
                        }
                        onBlur={handleBlur}
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#fff",
                          border: "none",
                        }}
                      />
                    </Col>
                  </Form.Group>

                  <Row className="mb-3">
                    <Col md="auto ms-auto">
                      <Button
                        type="submit"
                        variant="primary"
                        style={{
                          "--dynamic-bg-color":
                            whitelabel?.backgroundColor ?? "#00acdc",
                          "--dynamic-font-color":
                            whitelabel?.fontColor ?? "#fff",
                          border: "none",
                        }}
                      >
                        {t("settings.notificationSettings.submit")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        )}
      </Card.Body>
    </Card>
  );
};

export default Notifications;
