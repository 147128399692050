import { Card, Table } from "react-bootstrap";

const IPAllocationTable = () => (
  <Table striped hover responsive>
    <thead>
      <tr>
        <th>Customer</th>
        <th>Allocation Type</th>
        <th>Start IP</th>
        <th>End IP</th>
        <th>IPs Count</th>
        <th>IP Type</th>
        <th>IP Configuration Name</th>
        <th>Operator</th>
        <th>Customer Subnet</th>
        <th>Domain Subnet</th>
      </tr>
    </thead>
    <tbody></tbody>
  </Table>
);

const InvoiceTable = () => (
  <Table striped hover>
    <thead>
      <tr>
        <th>IMSI</th>
        <th>ICCID</th>
        <th>Product</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th>Quantity</th>
        <th>Cost</th>
        <th>Local Breakout</th>
        <th>User</th>
        <th>Customer Project</th>
        <th>Type of offering</th>
        <th>Country</th>
        <th>Test</th>
        <th>Customer</th>
      </tr>
    </thead>
    <tbody></tbody>
  </Table>
);

const SimTable = () => (
  <Table striped hover>
    <thead>
      <tr>
        <th>ICCID</th>
        <th>IMSI</th>
        <th>MSISDN</th>
        <th>IP Address</th>
        <th>Platform</th>
        <th>Manager IP</th>
        <th>User IP</th>
        <th>User Email</th>
        <th>User Name</th>
        <th>Status</th>
        <th>Activation Date</th>
        <th>Offer</th>
      </tr>
    </thead>
    <tbody></tbody>
  </Table>
);

export { InvoiceTable, SimTable, IPAllocationTable };
