import React, { useState, useEffect } from "react";
import { Table, Card } from "react-bootstrap";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import SimActionsDropdown from "./SimActionsDropdown";
import SimPagination from "./SimPagination";
import { useSelector } from "react-redux";

const DataCard = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  prepareRow,
  pageNumber,
  pageRecords,
  numberOfRecords,
  setShownSim,
  getSimCellInfo,
  setCellInfo,
  setOpen,
  setSingleIsShown,
  t,
  pageIndex,
  setPageRecords,
  setPageNumber,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  selectedRows,
  clearSelectedRows,
  filters,
  setIsLoading,
}) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  return (
    <Card>
      <Card.Body>
        <SimActionsDropdown
          selectedRows={selectedRows}
          clearSelectedRows={clearSelectedRows}
          pageIndex={pageIndex}
          pageRecords={pageRecords}
          filters={filters}
          setIsLoading={setIsLoading}
          sims={page}
        />
        <hr />
        <Table id="simsTable" hover responsive {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      <span>
                        {column.sortable ? (
                          column.isSorted ? (
                            column.isSortedDesc ? (
                              <FaSortUp className="ms-2 " />
                            ) : (
                              <FaSortDown className="ms-2 " />
                            )
                          ) : (
                            <FaSort className="ms-2" />
                          )
                        ) : null}
                      </span>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => {
                    setShownSim(row.original);
                    setOpen(true);
                    setTimeout(function () {
                      setSingleIsShown(true);
                    }, 500);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <SimPagination
          pageNumber={pageNumber}
          numberOfRecords={numberOfRecords}
          pageRecords={pageRecords}
          setPageSize={setPageSize}
          setPageRecords={setPageRecords}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          setPageNumber={setPageNumber}
          previousPage={previousPage}
          nextPage={nextPage}
          t={t}
        />
      </Card.Body>
    </Card>
  );
};

export default DataCard;
