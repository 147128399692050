import React, { useMemo } from "react";
import RowSelectingTable from "./RowSelectingTable";
import { useTranslation } from "react-i18next";
import getClientData from "../../inputData/clientData";

const ClientsList = (props) => {
  const { t } = useTranslation();
  const { clientColumns } = useMemo(() => getClientData(t), [t]);
  if (props.clientList) {
    return (
      <RowSelectingTable
        columns={clientColumns}
        data={props.clientList}
        shownClient={props.shownClient}
        setShownClient={props.setShownClient}
        setOpen={props.setOpen}
        setSingleIsShown={props.setSingleIsShown}
        title={props.title}
        showCreateEntityModal={props.showCreateEntityModal}
        setShowCreateEntityModal={props.setShowCreateEntityModal}
      />
    );
  }
};

export default ClientsList;
