import React, { useContext } from "react";
import { Pie } from "react-chartjs-2";

import { Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import countryList from "react-select-country-list";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import chroma from "chroma-js";
import SimsByNetworkModal from "../../pages/pages/dashboard/modals/SimsByNetworkModal";
import NotyfContext from "../../contexts/NotyfContext";
import { getNetworkDistribution } from "../../redux/slices/dashboard";
import Loader from "../spinners/Loader";

const NetworksDistributionPieChart = ({ reload }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [country, setCountry] = useState("Greece");
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [activeUser, setActiveUser] = useState({});
  const [bgColor, setBgColor] = useState("#00acdc");
  const countries = countryList().getData();
  const notyf = useContext(NotyfContext);
  const network_distribution = useSelector(
    (state) => state.dashboard.network_distribution
  );

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user.id, impersonationUser.id]);

  useEffect(() => {
    const fetchNetworkDistribution = async () => {
      setIsLoading(true);
      await dispatch(getNetworkDistribution({ country: country }))
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (activeUser.id) {
      fetchNetworkDistribution();
    }
  }, [activeUser, country, reload]);

  useEffect(() => {
    setBgColor(whitelabel?.backgroundColor ?? "#00acdc");
  }, [activeUser]);

  const [scale, setScale] = useState("");

  useEffect(() => {
    if (bgColor) {
      setScale(() =>
        chroma.scale([
          chroma(bgColor).set("hsl.h", "-30"),
          bgColor,
          chroma(bgColor).set("hsl.h", "+30"),
        ])
      );
    }
  }, [bgColor]);

  const [colors, setColors] = useState([]);
  const [showSimsByNetworkModal, setShowSimsByNetworkModal] = useState(false);
  const [network, setNetwork] = useState("");

  useEffect(() => {
    if (scale) {
      setColors(
        Array.from({ length: network_distribution.length }, (_, i) =>
          scale(i / network_distribution.length).hex()
        )
      );
    }
  }, [network_distribution, activeUser.id, scale]);

  const data = {
    labels: network_distribution.map((item) => item.network),
    datasets: [
      {
        data: network_distribution.map((item) => item.count),
        backgroundColor: colors,
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "right",
    },
    onClick: (e, element) => {
      if (element.length > 0) {
        const newNetwork = element[0]._model.label;
        setNetwork(newNetwork);
        setShowSimsByNetworkModal(true);
      }
    },
  };

  return (
    <Card id="pieChart">
      <Card.Header>
        <Card.Title>{t("dashboard.networksDistribution")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && <Loader />}
        {!isLoading && network_distribution.length === 0 && (
          <React.Fragment>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Select Country</Form.Label>
                <Form.Select
                  id="countrySelect"
                  name="country"
                  className="mb-5"
                  onChange={(e) => setCountry(e.target.value)}
                  value={country}
                >
                  {countries.map((country, index) => (
                    <option key={index} value={country.label}>
                      {country.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <div className="text-center">{t("No data found")}</div>
              </Col>
            </Row>
          </React.Fragment>
        )}
        {!isLoading && network_distribution.length > 0 && (
          <React.Fragment>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>{t("dashboard.selectCountry")}</Form.Label>
                <Form.Select
                  id="countrySelect"
                  name="country"
                  className="mb-5"
                  onChange={(e) => setCountry(e.target.value)}
                  value={country}
                >
                  {countries.map((country, index) => (
                    <option key={index} value={country.label}>
                      {country.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <div className="chart chart-sm">
                <Pie data={data} options={options} />
              </div>
              <SimsByNetworkModal
                showSimsByNetworkModal={showSimsByNetworkModal}
                setShowSimsByNetworkModal={setShowSimsByNetworkModal}
                network={network}
                country={country}
              />
            </Row>
          </React.Fragment>
        )}
      </Card.Body>
    </Card>
  );
};

export default NetworksDistributionPieChart;
