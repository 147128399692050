import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { HorizontalBar } from "react-chartjs-2";

import { Card, Dropdown, Spinner } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getTopMetrics } from "../../redux/slices/dataSessions";
import NotyfContext from "../../contexts/NotyfContext";
import Loader from "../spinners/Loader";

const TopMetricsBarChart = ({ period, reload }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [type, setType] = useState("data_sessions");
  const [isLoading, setIsLoading] = useState(true);
  const [barData, setBarData] = useState([]);
  const notyf = useContext(NotyfContext);
  const topMetrics = useSelector((state) => state.dataSessions.topMetrics);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchTopMetrics = async () => {
      setIsLoading(true);
      await Promise.all([dispatch(getTopMetrics({ type, period }))])
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (activeUser.id) {
      fetchTopMetrics();
    }
  }, [activeUser, type, period, reload]);

  function calculateData(item, type) {
    switch (type) {
      case "data_sessions":
        return item.count;
      case "data_volume":
        return parseFloat(item.count / 1000.0 / 1000.0).toFixed(2);
      case "zero_sessions":
        return item.count;
      case "alerts":
        return item.count;
      default:
        return 0;
    }
  }

  const data = {
    labels: topMetrics.map((item) => item.imsi),
    datasets: [
      {
        label:
          type === "data_volume"
            ? t("dashboard.dataVolume")
            : type === "data_sessions"
            ? t("dashboard.dataSessions")
            : type === "alerts"
            ? t("dashboard.alerts")
            : type === "zero_sessions"
            ? t("dashboard.zeroSessions")
            : // : type === "extra_charge"
              // ? t("dashboard.extraCharge")
              null,
        backgroundColor: whitelabel?.backgroundColor ?? "#00acdc",
        borderColor: whitelabel?.backgroundColor ?? "#00acdc",
        hoverBackgroundColor: whitelabel?.backgroundColor ?? "#00acdc",
        hoverBorderColor: whitelabel?.backgroundColor ?? "#00acdc",
        data: topMetrics.map((item) => calculateData(item, type)),
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cornerRadius: 15,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            stepSize: 20,
          },
          stacked: true,
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "transparent",
          },
          stacked: true,
        },
      ],
    },
    onClick: function (evt, element) {
      const imsi = element[0]._chart.config.data.labels[element[0]._index];
      if (element.length > 0) {
        navigate(`/sims/profile/${imsi}`);
      }
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title className="mb-0">
          {t("dashboard.topMetrics")}:{" "}
          {type === "data_volume"
            ? t("dashboard.dataVolume")
            : type === "data_sessions"
            ? t("dashboard.dataSessions")
            : type === "alerts"
            ? t("dashboard.alerts")
            : type === "zero_sessions"
            ? t("dashboard.zeroSessions")
            : // : type === "extra_charge"
              // ? t("Extra Charge")
              null}
        </Card.Title>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setType("data_volume")}>
                {t("dashboard.dataVolume")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("alerts")}>
                {t("dashboard.alerts")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("data_sessions")}>
                {t("dashboard.dataSessions")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setType("zero_sessions")}>
                {t("dashboard.zeroSessions")}
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={() => setType("extra_charge")}>
                {t("Extra Charge")}
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Header>
      <Card.Body className="d-flex">
        {!isLoading && topMetrics.length === 0 && (
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            {t("No data available")}
          </div>
        )}
        {!isLoading && topMetrics.length > 0 && (
          <div className="align-self-center w-100">
            <div className="chart">
              <HorizontalBar data={data} options={options} />
            </div>
          </div>
        )}
        {isLoading && (
          <div className="text-center d-flex w-100 justify-content-center align-items-center">
            <Loader />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default TopMetricsBarChart;
