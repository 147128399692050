const getWatchdogColumns = (t) => {
  const simWatchdogColumns = [
    {
      Header: "Watchdog ID",
      accessor: "watchDog.id",
    },
    {
      Header: t("simProfile.type"),
      accessor: "watchDog.actionType",
    },
    {
      Header: t("simProfile.amount"),
      accessor: "amount",
      Cell: ({ row: { original } }) => (
        <>
          {original.watchDog.amount}
          {original.watchDog.unitType}/{original.watchDog.service}
        </>
      ),
    },
  ];

  return { simWatchdogColumns };
};

export default getWatchdogColumns;
