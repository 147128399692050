import React, { useState, useEffect, useContext } from "react";

import { SIDEBAR_POSITION, SIDEBAR_BEHAVIOR } from "../constants";
import useSettingsState from "../hooks/useSettingsState";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "./NotyfContext";

const initialState = {
  isOpen: true,
  position: SIDEBAR_POSITION.LEFT,
  behavior: SIDEBAR_BEHAVIOR.STICKY,
};

const SidebarContext = React.createContext(initialState);

function SidebarProvider({ children }) {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const notyf = useContext(NotyfContext);

  const [isOpen, setIsOpen] = useState(true);
  const determineSidebarPosition = (whitelabel) => {
    return whitelabel?.sidebarPosition
      ? whitelabel.sidebarPosition === "left"
        ? SIDEBAR_POSITION.LEFT
        : SIDEBAR_POSITION.RIGHT
      : SIDEBAR_POSITION.LEFT;
  };

  const [position, setPosition] = useSettingsState(
    "sidebarPosition",
    determineSidebarPosition(whitelabel)
  );
  const [behavior, setBehavior] = useSettingsState(
    "sidebarBehavior",
    SIDEBAR_BEHAVIOR.STICKY
  );

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    setPosition(determineSidebarPosition(whitelabel));
  }, [whitelabel]);

  return (
    <SidebarContext.Provider
      value={{
        isOpen,
        setIsOpen,
        position,
        setPosition,
        behavior,
        setBehavior,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export { SidebarProvider, SidebarContext };
