import React from "react";
import RowExpanding from "./RowExpanding";

const SimsList = (props) => {
  return (
    <RowExpanding
      setOpen={props.setOpen}
      setSingleIsShown={props.setSingleIsShown}
      shownSim={props.shownSim}
      setShownSim={props.setShownSim}
      data={props.data}
      page={props.page}
      setPage={props.setPage}
      pageSize={props.pageSize}
      setPageSize={props.setPageSize}
      numberOfRecords={props.numberOfRecords}
      setCellInfo={props.setCellInfo}
      filters={props.filters}
      setFilters={props.setFilters}
      isLoading={props.isLoading}
      setIsLoading={props.setIsLoading}
      selectedRows={props.selectedRows}
      setSelectedRows={props.setSelectedRows}
      customFields={props.customFields}
    />
  );
};

export default SimsList;
