import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataSessions: [],
  zeroSessions: [],
  dataVolumes: [],
  comparison: [],
  zeroSessionComparison: [],
  dataVolumeComparison: [],
  dataSessionsCount: 0,
  zeroSessionsCount: 0,
  dataVolumesCount: 0,
  topMetrics: [],
  networkDistribution: [],
};

const sessionSlice = createSlice({
  name: "sessionSlice",
  initialState,
  reducers: {
    setDataSessions(state, action) {
      state.dataSessions = action.payload;
    },
    setComparison(state, action) {
      state.comparison = action.payload;
    },
    setZeroSessionComparison(state, action) {
      state.zeroSessionComparison = action.payload;
    },
    setDataVolumeComparison(state, action) {
      state.dataVolumeComparison = action.payload;
    },
    setDataVolumes(state, action) {
      state.dataVolumes = action.payload;
    },
    setDataSessionsCount(state, action) {
      state.dataSessionsCount = action.payload;
    },
    setZeroSessionsCount(state, action) {
      state.zeroSessionsCount = action.payload;
    },
    setDataVolumesCount(state, action) {
      state.dataVolumesCount = action.payload;
    },
    setTopMetrics(state, action) {
      state.topMetrics = action.payload;
    },
    setNetworkDistribution(state, action) {
      state.networkDistribution = action.payload;
    },
  },
});

export function getSessionComparison(id, type) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/dataSession/${id}/count?type=${type}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(sessionSlice.actions.setComparison(response.data));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export function getZeroSessionComparison(id, type) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/dataSession/${id}/zeroSession/count?type=${type}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(sessionSlice.actions.setZeroSessionComparison(response.data));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export async function getSessionComparisonForSim(id, imsi, type) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/dataSession/${id}/countForSim?type=${type}&imsi=${imsi}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    }
  }
}

export async function getZeroSessionComparisonForSim(id, imsi, type) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/dataSession/${id}/zeroSession/countForSim?type=${type}&imsi=${imsi}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Error");
  }
}

export function getDataVolumeComparison(id, type) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/dataSession/${id}/dataVolume/sum?type=${type}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(sessionSlice.actions.setDataVolumeComparison(response.data));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export async function getDataVolumeComparisonForSim(id, imsi, type) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/dataSession/${id}/dataVolume/sumForSim?type=${type}&imsi=${imsi}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Error");
  }
}

export async function getDistribution({ id, type, period }) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    if (type !== "alerts") {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/dataSession/${id}/distribution?type=${type}&period=${period}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return Promise.resolve(response.data);
    } else {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/alerts/${id}/distribution?period=${period}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return Promise.resolve(response.data);
    }
  } catch (error) {
    return Promise.reject("Error");
  }
}

export function getTopMetrics({ type, period }) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statistics/topmetrics?type=${type}&period=${period}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(sessionSlice.actions.setTopMetrics(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export async function getCountryDistribution(id, type, period) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/dataSession/${id}/distribution/country?type=${type}&period=${period}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Error");
  }
}

export function getNetworkDistribution(country) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/statistics/network?country=${country}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(sessionSlice.actions.setNetworkDistribution(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export async function getNetworkVolumeDistribution(id, country) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/dataSession/${id}/network/volume?country=${country}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Error");
  }
}

export function getCountsForImsiList(imsiList) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/dataSession/counts?imsiList=${imsiList}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        sessionSlice.actions.setDataSessionsCount(
          response.data.data.dataSessions
        )
      );
      dispatch(
        sessionSlice.actions.setZeroSessionsCount(
          response.data.data.zeroSessions
        )
      );
      dispatch(
        sessionSlice.actions.setDataVolumesCount(response.data.data.dataVolume)
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export async function countDataSessionsForImsiList(id, imsiList) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/dataSession/${id}/countSessionsForList?imsiList=${imsiList}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Error");
  }
}

export async function countZeroSessionsForImsiList(id, imsiList) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/dataSession/${id}/countZeroSessionsForList?imsiList=${imsiList}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Error");
  }
}

export async function sumDataVolumeForImsiList(id, imsiList) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/dataSession/${id}/sumDataVolumeForList?imsiList=${imsiList}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Error");
  }
}

export const {
  setDataSessions,
  setComparison,
  setZeroSessionComparison,
  setDataVolumeComparison,
  setDataVolumes,
} = sessionSlice.actions;

export default sessionSlice.reducer;
