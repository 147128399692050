import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SockJS from "sockjs-client";
import { Client } from "@stomp/stompjs";
import NavbarDropdownItem from "./NavbarDropdownItem";
import {
  addNotification,
  markAllAsRead,
} from "../../redux/slices/notifications"; // Import your Redux actions
import { Bell } from "react-feather";
import { Dropdown, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NotificationDropdown = ({ header, footer, children, icon: Icon }) => {
  const unreadNotifications = useSelector(
    (state) => state.notifications.unreadNotifications
  );
  const showBadge = unreadNotifications > 0;
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const dispatch = useDispatch();
  const [animateBadge, setAnimateBadge] = useState(false);
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (unreadNotifications > 0) {
      setAnimateBadge(true); // Trigger the animation
      const timeoutId = setTimeout(() => setAnimateBadge(false), 1000); // Remove the animation class after 1 second
      return () => clearTimeout(timeoutId); // Cleanup on component unmount or re-render
    }
  }, [unreadNotifications]);

  return (
    <Dropdown
      className="me-2 nav-item"
      align="end"
      show={showDropdown}
      onToggle={async () => {
        if (!showDropdown) {
          await dispatch(markAllAsRead());
          setShowDropdown(true);
        } else {
          setShowDropdown(false);
        }
      }}
    >
      <Dropdown.Toggle as="a" className="nav-link nav-icon dropdown-toggle">
        <div className="position-relative">
          <Icon className="align-middle" size={18} />
          {showBadge ? (
            <span
              className={`indicator ${animateBadge ? "animate-badge" : ""}`}
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              }}
            >
              {unreadNotifications}
            </span>
          ) : null}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu drop="end" className="dropdown-menu-lg py-0">
        <div className="dropdown-menu-header position-relative">
          {unreadNotifications} {header}
        </div>
        <ListGroup>{children}</ListGroup>
        <Dropdown.Header
          className="dropdown-menu-footer"
          onClick={() => {
            setShowDropdown(false);
            navigate("/notifications");
          }}
        >
          <span className="text-muted">{footer}</span>
        </Dropdown.Header>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
