import { Formik } from "formik";
import React, { useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { removeCustomField } from "../../../../redux/slices/customFields";
import NotyfContext from "../../../../contexts/NotyfContext";

const RemoveCustomFieldForm = ({
  errors,
  handleBlur,
  setFieldTouched,
  handleChange,
  handleSubmit,
  isSubmitting,
  touched,
  values,
  setValues,
  validateForm,
  setFieldValue,
  initialValues,
  customFields,
  setOpenRemoveCustomFieldModal,
}) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Custom Field</Form.Label>
        <Form.Select
          name="customFieldId"
          value={values.customFieldId}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.customFieldId && errors.customFieldId)}
        >
          <option key="" value="">
            Select Custom Field
          </option>
          {customFields.map((field) => (
            <option key={field.id} value={field.id}>
              {field.id} - {field.displayName}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors.customFieldId}
        </Form.Control.Feedback>
      </Form.Group>
      <Modal.Footer>
        <Button
          style={{
            "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
            "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
            border: "none",
          }}
          type="submit"
        >
          Submit
        </Button>
        <Button
          style={{
            "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
            "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
            border: "none",
          }}
          onClick={() => {
            setValues(initialValues);
            setOpenRemoveCustomFieldModal(false);
          }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const RemoveCustomFieldModal = ({
  openRemoveCustomFieldModal,
  setOpenRemoveCustomFieldModal,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const { customFields } = useSelector((state) => state.customFields);
  const notyf = useContext(NotyfContext);

  return (
    <Modal
      show={openRemoveCustomFieldModal}
      onHide={() => setOpenRemoveCustomFieldModal(false)}
      centered
      backdrop="static"
    >
      <Modal.Header>Remove Custom Field</Modal.Header>
      <Modal.Body>
        <Formik
          formikProps
          initialValues={{
            customFieldId: "",
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            customFieldId: Yup.number().required("Required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            await dispatch(
              removeCustomField(
                impersonationUser.id ? impersonationUser.id : user.id,
                values.customFieldId
              )
            )
              .then((response) => {
                setOpenRemoveCustomFieldModal(false);
                notyf.open({
                  type: "success",
                  message: "Successfully Removed Custom Field",
                  duration: 5000,
                  ripple: true,
                  dismissible: false,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .catch((error) => {
                notyf.open({
                  type: "danger",
                  message: "Error Removing Custom Field",
                  duration: 5000,
                  ripple: true,
                  dismissible: false,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              });
          }}
        >
          {({
            errors,
            handleBlur,
            setFieldTouched,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setValues,
            validateForm,
            setFieldValue,
            initialValues,
          }) => {
            return (
              <RemoveCustomFieldForm
                errors={errors}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                touched={touched}
                values={values}
                setValues={setValues}
                validateForm={validateForm}
                setFieldValue={setFieldValue}
                initialValues={initialValues}
                customFields={customFields}
                setOpenRemoveCustomFieldModal={setOpenRemoveCustomFieldModal}
              />
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default RemoveCustomFieldModal;
