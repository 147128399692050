import React, { useContext } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Collapse, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { enableWhitelabeling } from "../../../redux/slices/whitelabel";
import NotyfContext from "../../../contexts/NotyfContext";
import { useTranslation } from "react-i18next";

const ToggleWhitelabelingModal = ({
  showToggleWhitelabelingModal,
  setShowToggleWhitelabelingModal,
  entityUser,
}) => {
  const { t } = useTranslation();
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);

  const initialValues = {
    enableWhitelabeling: entityUser?.whitelabelingEnabled,
    price: entityUser.serverDetails?.price,
    url: entityUser.serverDetails?.url ?? "",
  };

  const validationSchema = Yup.object({
    enableWhitelabeling: Yup.boolean(),
    price: Yup.number().required("Price is required"),
    url: Yup.string().required("URL is required"),
  });
  return (
    <Modal
      show={showToggleWhitelabelingModal}
      onHide={() => setShowToggleWhitelabelingModal(false)}
      centered
      backdrop="static"
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("crm.whitelabelModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await dispatch(
              enableWhitelabeling({
                id: entityUser.id,
                price: values.price,
                url: values.url,
                role: entityUser.privilege,
              })
            )
              .then(() => {
                notyf.open({
                  type: "success",
                  message: "Whitelabeling enabled successfully",
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .catch((error) => {
                notyf.open({
                  type: "danger",
                  message: error,
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .finally(() => {
                setShowToggleWhitelabelingModal(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Check
                    type="switch"
                    name="enableWhitelabeling"
                    label={t("crm.whitelabelModal.enableWhitelabel")}
                    checked={values.enableWhitelabeling}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{
                      "--dynamic-bg-color":
                        whitelabel?.backgroundColor ?? "#00acdc",
                      "--dynamic-font-color":
                        whitelabel?.fontColor ?? "#ffffff",
                    }}
                  />
                </Form.Group>
                <Collapse in={!!values.enableWhitelabeling} dimension="height">
                  <div>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        {t("crm.whitelabelModal.pricePerMonth")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="price"
                        value={values.price}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.price && errors.price}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.price}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("crm.whitelabelModal.url")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="url"
                        value={values.url}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.url && errors.url}
                      />
                      {!!touched.url && (
                        <Form.Control.Feedback type="invalid">
                          {errors.url}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </div>
                </Collapse>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    style={{
                      "--dynamic-bg-color":
                        whitelabel?.backgroundColor ?? "#00acdc",
                      "--dynamic-font-color":
                        whitelabel?.fontColor ?? "#ffffff",
                      border: "none",
                    }}
                    onClick={() => setShowToggleWhitelabelingModal(false)}
                  >
                    {t("crm.whitelabelModal.cancel")}
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    style={{
                      "--dynamic-bg-color":
                        whitelabel?.backgroundColor ?? "#00acdc",
                      "--dynamic-font-color":
                        whitelabel?.fontColor ?? "#ffffff",
                      border: "none",
                    }}
                  >
                    {t("crm.whitelabelModal.submit")}
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ToggleWhitelabelingModal;
