import React from "react";
import { Bar } from "react-chartjs-2";

import { Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import SimsByOfferModal from "../../pages/pages/dashboard/modals/SimsByOfferModal";
import { getOffersDistribution } from "../../redux/slices/dashboard";
import NotyfContext from "../../contexts/NotyfContext";
import Loader from "../spinners/Loader";

const OffersDistributionBar = ({ reload }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [offersDistribution, setOffersDistribution] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const { offers } = useSelector((state) => state.offers);
  const [showSimsByOfferModal, setShowSimsByOfferModal] = useState(false);
  const [offer, setOffer] = useState("");
  const [activeUser, setActiveUser] = useState({});
  const offers_distribution = useSelector(
    (state) => state.dashboard.offers_distribution
  );
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchOffersDistribution = async () => {
      setIsLoading(true);
      await dispatch(getOffersDistribution())
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (activeUser.id) {
      fetchOffersDistribution();
    }
  }, [activeUser, reload]);

  const sum = offers_distribution.reduce(
    (accumulator, obj) => accumulator + obj.amount,
    0
  );

  const data = {
    labels: offers_distribution.map((item) => item.name),
    datasets: [
      {
        data: offers_distribution.map((item) =>
          parseFloat((item.amount / sum) * 100).toFixed(2)
        ),
        backgroundColor: whitelabel?.backgroundColor ?? "#00acdc",
        borderColor: whitelabel?.backgroundColor ?? "#00acdc",
        hoverBackgroundColor: whitelabel?.backgroundColor ?? "#00acdc",
        hoverBorderColor: whitelabel?.backgroundColor ?? "#00acdc",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: false,
          ticks: {
            stepSize: 20,
          },
        },
      ],
      xAxes: [
        {
          stacked: false,
          gridLines: {
            color: "transparent",
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let label = data.labels[tooltipItem.index] || "";
          let value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] ||
            "";
          return `${label}: ${value}%`;
        },
      },
    },
    onClick: (e, element) => {
      if (element.length > 0) {
        const newOffer = element[0]._model.label;
        setOffer(newOffer);
        setShowSimsByOfferModal(true);
      }
    },
  };

  return (
    <Card id="pieChart">
      <Card.Header>
        <Card.Title>{t("dashboard.simCardsPerOffer")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && <Loader />}
        {!isLoading && (
          <React.Fragment>
            <div className="chart chart-sm">
              <Bar data={data} options={options} />
            </div>
            <SimsByOfferModal
              showSimsByOfferModal={showSimsByOfferModal}
              setShowSimsByOfferModal={setShowSimsByOfferModal}
              offerName={offer}
            />
          </React.Fragment>
        )}
      </Card.Body>
    </Card>
  );
};

export default OffersDistributionBar;
