import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

const AdditionalStatistics = ({ average_data_volume, active_sims, reload }) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col className="d-flex" lg={12} xl={6}>
        <Card className="flex-fill" style={{ height: "160px" }}>
          <Card.Body>
            <Row>
              <Col className="mt-0">
                <h5 className="card-title">{t("dashboard.averageVolume")}</h5>
              </Col>
            </Row>
            <span className="h1 d-inline-block mt-3 mb-4">
              <CountUp
                start={0}
                end={parseFloat(average_data_volume / 1000.0 / 1000.0)}
                duration={2.75}
                decimals={2}
                redraw
              />
              MB
            </span>
          </Card.Body>
        </Card>
      </Col>
      <Col className="d-flex" lg={12} xl={6}>
        <Card className="flex-fill" style={{ height: "160px" }}>
          <Card.Body>
            <Row>
              <Col className="mt-0">
                <h5 className="card-title">{t("dashboard.activeSims")}</h5>
              </Col>
            </Row>
            <span className="h1 d-inline-block mt-3 mb-4">
              <CountUp start={0} end={active_sims} duration={2.75} redraw />
            </span>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default AdditionalStatistics;
