import axios from "../../utils/axios";
import { impersonateUser } from "./impersonationUser";
import { loginUser } from "./user";

export function updateProfileImage({ data }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const formData = new FormData();
      formData.append("image", data.image);
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/images`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (impersonationToken) {
        dispatch(impersonateUser(response.data.data));
      } else {
        dispatch(loginUser(response.data.data));
      }
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}
