const privilegeColumns = [
  {
    Header: "ID",
    accessor: "id",
    sortable: true,
  },
  {
    Header: "Name",
    accessor: "name",
    sortable: true,
  },
];

export { privilegeColumns };
