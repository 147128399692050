import React from "react";
import { Card, Form, Row } from "react-bootstrap";
import FilterGroup from "./FilterGroup";
import { useTranslation } from "react-i18next";

const FiltersCard = ({ headerGroups }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Header>
        <Card.Title>{t("crm.filters")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form>
          <Row>
            {headerGroups.map((headerGroup, idx) =>
              headerGroup.headers.map((column, index) => (
                <FilterGroup key={index} column={column} />
              ))
            )}
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default FiltersCard;
