import React, { useEffect } from "react";
import { Col, Collapse, Form, Row } from "react-bootstrap";

const SendSmsForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
}) => {
  useEffect(() => {
    const buf = Buffer.from(values.textContent);
    const base64 = buf.toString("base64");
    setFieldValue("base64Content", base64);
  }, [values.textContent, setFieldValue]);

  useEffect(() => {
    if (values.fromType === "Platform") {
      setFieldValue("from", "447937405250");
    } else {
      setFieldValue("from", "");
    }
  }, [values.fromType, setFieldValue]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>From Type</Form.Label>
            <Form.Select
              value={values.fromType}
              name="fromType"
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={Boolean(touched.fromType && errors.fromType)}
            >
              <option value="">Select type</option>
              <option value="System">System</option>
              <option value="Platform">Platform</option>
              <option value="Free">Free</option>
            </Form.Select>
            {!!touched.fromType && (
              <Form.Control.Feedback type="invalid">
                {errors.fromType}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>From</Form.Label>
            <Form.Control
              type="text"
              name="from"
              value={values.from}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={
                values.fromType === "system" || values.fromType === "platform"
              }
              isInvalid={Boolean(touched.from && errors.from)}
            />
            {!!touched.from && (
              <Form.Control.Feedback type="invalid">
                {errors.from}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>SMS Type</Form.Label>
            <Form.Select
              value={values.messageType}
              name="messageType"
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={Boolean(touched.messageType && errors.messageType)}
            >
              <option value="">Select SMS Type</option>
              <option value="text">Text</option>
              <option value="binary">Binary</option>
              <option value="unicode">Unicode</option>
            </Form.Select>
            {!!touched.messageType && (
              <Form.Control.Feedback type="invalid">
                {errors.messageType}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>SMS</Form.Label>
            <Form.Control
              as="textarea"
              name="textContent"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.textContent}
              isInvalid={Boolean(touched.textContent && errors.textContent)}
            />
            <Form.Control.Feedback type="invalid">
              {errors.textContent}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Collapse in={values.messageType === "binary"} dimension="height">
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Base64 Encoded</Form.Label>
              <Form.Control
                as="textarea"
                name="base64Content"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.base64Content}
                isInvalid={Boolean(
                  touched.base64Content && errors.base64Content
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.base64Content}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Collapse>
    </React.Fragment>
  );
};

export default SendSmsForm;
