import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";

import Clients from "./Clients";

import { useDispatch, useSelector } from "react-redux";
import { getManagers } from "../../redux/slices/managers";
import Loader from "../../components/spinners/Loader";
import NotyfContext from "../../contexts/NotyfContext";

const Managers = () => {
  const dispatch = useDispatch();
  const { managers } = useSelector((state) => state.managers);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchManagers = async () => {
      await dispatch(getManagers())
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (activeUser.id) {
      fetchManagers();
    }
  }, [dispatch, activeUser]);

  return (
    <React.Fragment>
      <Helmet title="Managers" />
      <Container fluid className="p-0 w-100 h-100">
        <Clients title="Managers" clientList={managers} isLoading={isLoading} />
      </Container>
    </React.Fragment>
  );
};

export default Managers;
