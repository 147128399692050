import React, { useContext } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createPrivilege,
  updatePrivilege,
} from "../../redux/slices/privileges";
import NotyfContext from "../../contexts/NotyfContext";

const EditPrivilegeModal = ({
  showEditPrivilegeModal,
  setShowEditPrivilegeModal,
  privilege,
}) => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const initialValues = {
    name: privilege?.name ?? "",
  };

  const validation = Yup.object({
    name: Yup.string().required("Name is required"),
  });
  return (
    <Modal
      show={showEditPrivilegeModal}
      onHide={() => setShowEditPrivilegeModal(false)}
      size="md"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Privilege</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={async (values, { setValues }) => {
            await dispatch(updatePrivilege({ id: privilege.id, data: values }))
              .then(() => {
                notyf.open({
                  type: "success",
                  message: "Privilege created successfully",
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .catch((error) => {
                notyf.open({
                  type: "danger",
                  message: error,
                  duration: 5000,
                  dismissible: false,
                  ripple: true,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .finally(() => {
                setValues(initialValues);
                setShowEditPrivilegeModal(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    isInvalid={touched.name && errors.name}
                  />
                  {!!touched.name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    className="me-2"
                    onClick={() => setShowEditPrivilegeModal(false)}
                    style={{
                      "--dynamic-bg-color":
                        whitelabel?.backgroundColor ?? "#00acdc",
                      "--dynamic-font-color":
                        whitelabel?.fontColor ?? "#ffffff",
                      border: "none",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    style={{
                      "--dynamic-bg-color":
                        whitelabel?.backgroundColor ?? "#00acdc",
                      "--dynamic-font-color":
                        whitelabel?.fontColor ?? "#ffffff",
                      border: "none",
                    }}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default EditPrivilegeModal;
