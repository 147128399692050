import React, { useState, useEffect, useContext } from "react";

import { Helmet } from "react-helmet-async";

import { Button, Card, Col, Container, Row } from "react-bootstrap";

import { Briefcase, Home, MapPin, Phone, Mail } from "react-feather";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faMobile,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";
import countryList from "react-select-country-list";
import { formatDistanceToNow } from "date-fns";

import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../contexts/NotyfContext";
import defaultUser from "../../assets/img/avatars/default-user.png";
import { fetchUserActivity } from "../../redux/slices/userActivity";
import Loader from "../../components/spinners/Loader";

const ProfileDetails = (props) => {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">{t("profile.profileDetails")}</Card.Title>
      </Card.Header>
      <Card.Body className="text-center">
        <img
          src={props.image}
          alt={props.fullName}
          className="img-fluid rounded-circle mb-2"
          width="128"
          height="128"
        />
        <Card.Title className="mb-0">{props.fullName}</Card.Title>
        <div className="text-muted mb-2">@{props.username}</div>
      </Card.Body>
      <hr className="my-0" />
      <Card.Body>
        <Card.Title>{t("profile.contactInformation")}</Card.Title>

        <ul className="list-unstyled mb-0">
          {props.role !== "subaccount" && (
            <li className="mb-1">
              <Home width={14} height={14} className="me-1" /> {props.address}
            </li>
          )}
          {props.role !== "subaccount" && (
            <li className="mb-1">
              <MapPin width={14} height={14} className="me-1" />{" "}
              {props.postcode} {props.city}
            </li>
          )}
          {props.role !== "subaccount" && (
            <li className="mb-1">
              <FontAwesomeIcon icon={faGlobe} fixedWidth className="me-1" />{" "}
              {props.country}
            </li>
          )}
          {props.role !== "subaccount" && (
            <li className="mb-1">
              <Phone width={14} height={14} className="me-1" />{" "}
              {props.telephoneNumber}
            </li>
          )}
          {props.role !== "subaccount" && (
            <li className="mb-1">
              <FontAwesomeIcon icon={faMobile} fixedWidth className="me-1" />{" "}
              {props.mobileNumber ? props.mobileNumber : "N/A"}
            </li>
          )}
          <li className="mb-1">
            <Mail width={14} height={14} className="me-1" /> {props.email}
          </li>
        </ul>
      </Card.Body>
      <hr className="my-0" />
      {props.role !== "subaccount" && props.role != "admin" && (
        <Card.Body>
          <Card.Title>{t("profile.professionalInformation")}</Card.Title>
          <ul className="list-unstyled mb-0">
            <li className="mb-1">
              <Briefcase width={14} height={14} className="me-1" />{" "}
              {props.occupation} at {props.company}
            </li>
            <li className="mb-1">
              <FontAwesomeIcon icon={faReceipt} fixedWidth className="me-1" />{" "}
              {props.tin}
            </li>
          </ul>
        </Card.Body>
      )}
    </Card>
  );
};

const Activities = (props) => {
  const { t } = useTranslation();
  const userActivity = useSelector((state) => state.userActivity.userActivity);
  const numOfActivities = userActivity.length;
  const [numDisplayed, setNumDisplayed] = useState(10);
  const showLoadMore = numDisplayed < numOfActivities;
  const handleLoadMore = () => {
    setNumDisplayed(userActivity.length);
  };
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">{t("profile.activities")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <div
          className="activity-container"
          style={{ height: "522px", overflow: "auto" }}
        >
          {userActivity.slice(0, numDisplayed).map((activityElement) => (
            <React.Fragment key={activityElement.id}>
              <div className="d-flex">
                <div
                  className={`ms-3 me-5 circle ${
                    activityElement.event === "SIGN_IN"
                      ? "green"
                      : activityElement.event === "SIGN_OUT"
                      ? "red"
                      : activityElement.event === "SIGN_UP"
                      ? "blue"
                      : "blue"
                  }`}
                ></div>
                <div className="flex-grow-1">
                  <small className="float-end text-navy">
                    {activityElement.createdAt}
                  </small>
                  {activityElement.description}
                </div>
              </div>
              <hr />
            </React.Fragment>
          ))}
        </div>
        {showLoadMore && (
          <div className="d-grid">
            <Button
              style={{
                "--dynamic-bg-color": props.backgroundColor,
                "--dynamic-font-color": props.fontColor,
                border: "none",
              }}
              onClick={handleLoadMore}
            >
              {t("profile.loadMore")}
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

const Profile = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const [fullName, setFullName] = useState();
  const [address, setAddress] = useState();
  const [postcode, setPostcode] = useState();
  const [occupation, setOccupation] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [company, setCompany] = useState();
  const [telephoneNumber, setTelephoneNumber] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [email, setEmail] = useState();
  const [tin, setTin] = useState();
  const [username, setUsername] = useState();
  const [image, setImage] = useState();
  const [activity, setActivity] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState("");
  const countries = countryList().getData();
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchServerDetails = async () => {
      await Promise.all([dispatch(fetchUserActivity())])
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (activeUser.id) {
      fetchServerDetails();
    }
  }, [activeUser]);

  useEffect(() => {
    setFullName(activeUser.fullName);
    setAddress(activeUser.address);
    setPostcode(activeUser.postcode);
    setOccupation(activeUser.occupation);
    setCity(activeUser.city);
    setCountry(activeUser.country);
    setCompany(activeUser.company);
    setTelephoneNumber(activeUser.telephoneNumber);
    setMobileNumber(activeUser.mobileNumber);
    setEmail(activeUser.email);
    setTin(activeUser.tin);
    setUsername(activeUser.username);
    setImage(
      activeUser.photo
        ? `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${activeUser.photo}`
        : defaultUser
    );
    setBackgroundColor(whitelabel?.backgroundColor ?? "00acdc");
  }, [activeUser, fullName, countries]);

  if (isLoading) {
    return (
      <React.Fragment>
        <Helmet title="Profile">
          <link rel="icon" href={`data:image/x-icon;base64,${user.favicon}`} />
        </Helmet>
        <Container fluid className="p-0 w-100 h-100">
          <Card className="w-100 h-100">
            <Card.Body className="d-flex justify-content-center align-items-center w-100 h-100">
              <Loader />
            </Card.Body>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Helmet title="Profile">
        <link rel="icon" href={`data:image/x-icon;base64,${user.favicon}`} />
      </Helmet>
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{t("Profile")}</h1>

        <Row>
          <Col md="4" xl="3">
            <ProfileDetails
              fullName={fullName}
              address={address}
              postcode={postcode}
              occupation={occupation}
              city={city}
              country={country}
              company={company}
              telephoneNumber={telephoneNumber}
              mobileNumber={mobileNumber}
              email={email}
              tin={tin}
              username={username}
              image={image}
              backgroundColor={backgroundColor}
              role={activeUser.role}
            />
          </Col>
          <Col md="8" xl="9">
            <Activities
              fullName={fullName}
              image={image}
              backgroundColor={backgroundColor}
              fontColor={whitelabel?.fontColor ?? "ffffff"}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Profile;
