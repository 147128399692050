import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pusher from "pusher-js";
import {
  addNotification,
  getNotifications,
  incrementUnreadNotifications,
} from "../redux/slices/notifications";
import NotyfContext from "./NotyfContext";
import soundFile from "../assets/sounds/message-notification.wav";

const NotificationContext = React.createContext();

export const useNotifications = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const impersonationToken = window.localStorage.getItem("impersonationToken");
  const accessToken = window.localStorage.getItem("accessToken");
  const [pusherInstance, setPusherInstance] = useState(null); // Keep track of pusher instance
  const [channelInstance, setChannelInstance] = useState(null); // Keep track of current channel
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const audio = new Audio(soundFile);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchNotifications = async () => {
      dispatch(getNotifications()).catch((error) => {
        notyf.open({
          type: "danger",
          message: error,
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "center",
            y: "top",
          },
        });
      });
    };

    if (activeUser.id) {
      fetchNotifications();
    }
  }, [activeUser.id]);

  useEffect(() => {
    const subscribeToPusher = () => {
      if (channelInstance) {
        channelInstance.unbind_all(); // Unbind all events from the channel
        channelInstance.unsubscribe(); // Unsubscribe from the old channel
      }
      if (pusherInstance) {
        pusherInstance.disconnect(); // Disconnect old Pusher connection
      }
      const newPusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        authEndpoint: `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/pusher/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        },
      });

      const newChannel = newPusher.subscribe(`private-user-${activeUser.id}`);

      // Listen for events on the channel
      newChannel.bind("invoice", (data) => {
        dispatch(addNotification(JSON.parse(data)));
        dispatch(incrementUnreadNotifications());
        audio.play();
        notyf.open({
          type: "default",
          message: JSON.parse(data).message,
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "right",
            y: "top",
          },
        });
        // Handle notification data here
      });

      newChannel.bind("watchdog", (data) => {
        dispatch(addNotification(JSON.parse(data)));
        dispatch(incrementUnreadNotifications());
        audio.play();
        notyf.open({
          type: "default",
          message: JSON.parse(data).message,
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "right",
            y: "top",
          },
        });
        // Handle notification
      });

      setPusherInstance(newPusher);
      setChannelInstance(newChannel);
    };

    if (activeUser.id) {
      subscribeToPusher();
    }

    return () => {
      if (channelInstance) {
        channelInstance.unbind_all();
        channelInstance.unsubscribe();
      }
      if (pusherInstance) {
        pusherInstance.disconnect();
      }
    };
  }, [activeUser.id]);

  return (
    <NotificationContext.Provider value={notifications}>
      {children}
    </NotificationContext.Provider>
  );
};
