import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePrivilege,
  fetchAllPrivileges,
} from "../../redux/slices/privileges";
import NotyfContext from "../../contexts/NotyfContext";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import Loader from "../../components/spinners/Loader";
import {
  useTable,
  useRowSelect,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from "react-table";
import { privilegeColumns } from "./privilegeData";
import { FaPlus } from "react-icons/fa";
import NewPrivilegeModal from "./NewPrivilegeModal";
import EditPrivilegeModal from "./EditPrivilegeModal";
import { useTranslation } from "react-i18next";

const Privileges = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const privileges = useSelector((state) => state.privileges.privileges);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const notyf = useContext(NotyfContext);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [showNewPrivilegeModal, setShowNewPrivilegeModal] = useState(false);
  const [showEditPrivilegeModal, setShowEditPrivilegeModal] = useState(false);
  const [privilegeToEdit, setPrivilegeToEdit] = useState({});

  useEffect(() => {
    const fetchPrivileges = async () => {
      await Promise.all([dispatch(fetchAllPrivileges())])
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [500]);
        });
    };

    if (user.id) {
      fetchPrivileges();
    }
  }, [user]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    setSortBy,
    setGlobalFilter,
    preGlobalFilteredRows,
    globalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: privilegeColumns,
      data: privileges,
      initialState: { pageIndex: 0, globalFilter: "" },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: "actions",
          Header: t("privileges.actions"),
          Cell: ({ row }) => (
            <DropdownButton
              data-boundary="viewport"
              as={ButtonGroup}
              title={t("privileges.actions")}
              variant="primary"
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
            >
              <Dropdown.Item
                onClick={() => {
                  setPrivilegeToEdit(row.original);
                  setShowEditPrivilegeModal(true);
                }}
              >
                {t("privileges.edit")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={async () => {
                  await dispatch(deletePrivilege(row.original.id))
                    .then(() => {
                      notyf.open({
                        type: "success",
                        message: "Privilege deleted successfully",
                        duration: 5000,
                        ripple: true,
                        dismissible: false,
                        position: {
                          x: "center",
                          y: "top",
                        },
                      });
                    })
                    .catch((error) => {
                      notyf.open({
                        type: "danger",
                        message: error,
                        duration: 5000,
                        ripple: true,
                        dismissible: false,
                        position: {
                          x: "center",
                          y: "top",
                        },
                      });
                    });
                }}
              >
                {t("privileges.delete")}
              </Dropdown.Item>
            </DropdownButton>
          ),
        },
      ]);
    }
  );

  if (isLoading)
    return (
      <Card className="w-100 h-100">
        <Card.Body>
          <Loader />
        </Card.Body>
      </Card>
    );

  return (
    <Card>
      <Card.Header>
        <Row className="align-items-center mb-4">
          <Col>
            <Card.Title>{t("privileges.title")}</Card.Title>
          </Col>
          <Col md="auto ms-auto">
            <Button
              variant="primary"
              onClick={() => {
                setShowNewPrivilegeModal(true);
              }}
              style={{
                "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
                "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
                border: "none",
              }}
            >
              <FaPlus /> {t("privileges.newPrivilege")}
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Table id="privilegesTable" hover responsive {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Row>
          <Col md="9">
            <span className="mx-2">
              {t("table.page")}{" "}
              <strong>
                {pageIndex + 1} {t("table.of")} {pageOptions.length}
              </strong>
            </span>
            <span className="ms-3 me-2">{t("table.show")}:</span>
            <Form.Select
              className="d-inline-block w-auto"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>

            <span className="ms-3 me-2">{t("table.goToPage")}:</span>
            <Form.Control
              className="d-inline-block"
              type="number"
              defaultValue={pageIndex + 1}
              min={1}
              max={pageCount}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "75px" }}
            />
          </Col>
          <Col md="3">
            <Pagination className="float-end">
              <Pagination.First
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              />
              <Pagination.Prev
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              />
              <Pagination.Next
                onClick={() => nextPage()}
                disabled={!canNextPage}
              />
              <Pagination.Last
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              />
            </Pagination>
          </Col>
        </Row>
        <NewPrivilegeModal
          showNewPrivilegeModal={showNewPrivilegeModal}
          setShowNewPrivilegeModal={setShowNewPrivilegeModal}
        />
        <EditPrivilegeModal
          showEditPrivilegeModal={showEditPrivilegeModal}
          setShowEditPrivilegeModal={setShowEditPrivilegeModal}
          privilege={privilegeToEdit}
        />
      </Card.Body>
    </Card>
  );
};

export default Privileges;
